import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UserService } from "../../_services";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as es6printJS from "print-js";

@Component({
  selector: "app-i-user-set",
  templateUrl: "./i-user-set.component.html",
  styleUrls: ["./i-user-set.component.scss"],
})
export class IUserSetComponent implements OnInit {
  @ViewChild("allDetails") allDetails: any;
  @ViewChild("tracking") trackingInput: any;
  @ViewChild("user_code") userCodeInput: any;
  @ViewChild("weight") weightInput: any;
  @ViewChild("quantity") quantityInput: any;
  @ViewChild("commment2") commment2Input: any;
  @ViewChild("air") airInput: any;
  // @ViewChild('ground') groundInput: any;
  @ViewChild("sea") seaInput: any;
  @ViewChild("save") saveBtn: any;
  @ViewChild("dItem") dItem: any;
  @ViewChild("pdfLabelNew") pdfLabelNew: any;
  @ViewChild("openModal") openModal: ElementRef;
  title = "test_testing_0013";
  public bottom = "bottom";
  public top = "top";
  public left = "left";
  public center = "center";
  public mTop = 20;
  public mBottom = 1;
  public sizetwo = 70;
  public fsize = 12;
  public sizeBarcode = 1.5;
  public scannedStatus: boolean;
  public isWithVolumeWeight = false;
  public loading: boolean;
  public generated = false;
  public disableBtn = false;
  public isCm = true;
  public allData: string;
  public message: string;
  public audioStatus: string;
  public labelName: string;
  public userSetForm: FormGroup;
  public orderCategories = [];
  public arm_clients = [];
  public ga_clients = [];
  public machineValue = null;
  public imageUrl;

  constructor(private _userService: UserService, private fb: FormBuilder) {
    this.loading = false;
    this.userSetForm = this.setControls();
  }

  @HostListener("keydown.tab", ["$event"])
  onTab(event: KeyboardEvent) {
    // console.log(event);
    if (event.target == this.userCodeInput.nativeElement) {
      this.dynamicFocus();
      // this.airInput.nativeElement.focus();
      event.preventDefault();
      return false;
    }
    switch (event.target) {
      case this.airInput.nativeElement:
      // case this.groundInput.nativeElement:
      case this.seaInput.nativeElement:
        if (this.getControl("waight").value) {
          this.commment2Input.nativeElement.focus();
        } else {
          this.weightInput.nativeElement.focus();
        }
        event.preventDefault();
        break;
    }
    if (event.target == this.weightInput.nativeElement) {
      this.commment2Input.nativeElement.focus();
      // this.saveBtn.nativeElement.select();
      event.preventDefault();
      return false;
    }
  }

  @HostListener("keyup.enter", ["$event"])
  onEnter(event: KeyboardEvent) {
    if (event.target == this.trackingInput.nativeElement) {
      this.userCodeInput.nativeElement.focus();
      event.preventDefault();
      return false;
    }
    switch (event.target) {
      case this.airInput.nativeElement:
      // case this.groundInput.nativeElement:
      case this.seaInput.nativeElement:
      case this.weightInput.nativeElement:
      case this.quantityInput.nativeElement:
        this.submitForm();
        break;
    }
  }

  @HostListener("keyup.arrowDown", ["$event"])
  onArrowDown(event: KeyboardEvent) {
    if (event.target == this.userCodeInput.nativeElement) {
      if (this.arm_clients.length > 0 || this.ga_clients.length > 0) {
        this.dItem.nativeElement
          .getElementsByClassName("dropdown-item")[0]
          .focus();
        event.preventDefault();
        return false;
      }
    }
  }

  @HostListener("keyup.arrowUp", ["$event"])
  onArrowUp(event: KeyboardEvent) {
    if (this.arm_clients.length > 0 || this.ga_clients.length > 0) {
      if (
        event.target ==
        this.dItem.nativeElement.getElementsByClassName("dropdown-item")[0]
      ) {
        this.userCodeInput.nativeElement.focus();
        event.preventDefault();
        return false;
      }
    }
  }

  public gettingAllDetailsAndCheck(value): void {
    this.machineValue = value;
    const array = value.split(",,");
    const trackingAndWeight = array[0].split(",");
    const dimensions = array[1].split(",");

    this.getControl("tracking_code").setValue(trackingAndWeight[0]);
    this.getControl("waight").setValue(trackingAndWeight[1]);
    if (this.isWithVolumeWeight) {
      this.isCm = false;
      dimensions[0] = Math.ceil(+dimensions[0] / 10) * 10;
      dimensions[1] = Math.ceil(+dimensions[1] / 10) * 10;
      dimensions[2] = Math.ceil(+dimensions[2] / 10) * 10;
      this.getControl("width").setValue(dimensions[1]);
      this.getControl("length").setValue(dimensions[0]);
      this.getControl("height").setValue(dimensions[2]);
      this.calc(
        this.getControl("width").value,
        this.getControl("length").value,
        this.getControl("height").value,
        "all"
      );
    }
    this.getControl("autoscan").setValue(true);
    this.changeMode();
    this.userCodeInput.nativeElement.focus();
    this.checkTheTrackingCode();
  }

  ngOnInit() {
    this.getControl("autoscan").setValue(this.getAutoscanValue());
    this._userService.getOrderCategories().subscribe(
      (data) => {
        if (data.status == 200) {
          this.orderCategories = data.order_categories;
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  public getControl(control): AbstractControl {
    return this.userSetForm.get(control);
  }

  public calc(w, l, h, type): void {
    if (!this.isCm) {
      switch (type) {
        case "width":
          this.getControl("width").setValue(w / 10);
          break;
        case "length":
          this.getControl("length").setValue(l / 10);
          break;
        case "height":
          this.getControl("height").setValue(h / 10);
          break;
        case "all":
          this.getControl("width").setValue(w / 10);
          this.getControl("length").setValue(l / 10);
          this.getControl("height").setValue(h / 10);
          break;
      }
    }

    if (
      w != undefined &&
      w != "" &&
      l != undefined &&
      l != "" &&
      h != undefined &&
      h != ""
    ) {
      let vWeight;
      if (
        this.userCodeInput.nativeElement.value &&
        (this.userCodeInput.nativeElement.value.includes("ARM") ||
          this.userCodeInput.nativeElement.value.includes("MT") ||
          this.userCodeInput.nativeElement.value.includes("PAA"))
      ) {
        vWeight = 5000;
      } else {
        vWeight = 6000;
      }
      let len =
        (this.getControl("width").value *
          this.getControl("length").value *
          this.getControl("height").value) /
        vWeight;
      if (len.toString().includes(".")) {
        let str = len.toString();
        let splited = str.split(".");
        if (splited[1].length > 4) {
          splited[1] = splited[1].substring(0, 2);
          this.getControl("v_waight").setValue(+splited.join("."));
        } else {
          this.getControl("v_waight").setValue(+str);
        }
      } else {
        this.getControl("v_waight").setValue(+len);
      }
    } else {
      this.getControl("v_waight").setValue("");
    }
  }

  public checkMt(): void {
    if (this.getControl("dispatch").value == "2") {
      if (this.getControl("user_code").value.startsWith("ARM", 0)) {
        this.getControl("user_code").setValue(
          this.getControl("user_code").value.replace("ARM", "MT").toUpperCase()
        );
      }
      if (this.labelName && this.labelName.lastIndexOf("MT") == -1) {
        this.labelName = this.breakingFullName(this.labelName, "ARM", "MT");
      }
    } else {
      if (this.getControl("user_code").value.startsWith("MT", 0)) {
        this.getControl("user_code").setValue(
          this.getControl("user_code").value.replace("MT", "ARM").toUpperCase()
        );
      }
      if (this.labelName && this.labelName.lastIndexOf("ARM") == -1) {
        this.labelName = this.breakingFullName(this.labelName, "MT", "ARM");
      }
    }
  }

  public submitForm(): void {
    if (this.userSetForm.valid) {
      // this.pdfLabel(this.getControl('tracking_code').value, this.labelName);
      // document.querySelector('button[printsectionid]').click()
      if (this.userSetForm.value.dispatch === "2") {
        this.labelName = `${this.labelName.split(" ")[0]} ${
          this.labelName.split(" ")[1]
        } ${this.labelName.split(" ")[2].replace("ARM", "MT")} `;
      }
      // return
      let element: HTMLElement = document.querySelector(
        "button[printsectionid]"
      ) as HTMLElement;
      element.click();
      this.loading = true;
      this.disableBtn = true;
      this.message = null;
      this.audioStatus = null;
      this.arm_clients = [];
      this.ga_clients = [];

      if (+this.userSetForm.value.dispatch == 2) {
        this.userSetForm.value.user_code = this.userSetForm.value.user_code
          .replace("MT", "ARM")
          .toUpperCase();
        this.userSetForm.value.code = this.userSetForm.value.code
          .replace("ARM", "ARM")
          .toUpperCase();
      }

      if (this.machineValue) {
        this.userSetForm.value.image_path = `https://onex-china.s3.eu-north-1.amazonaws.com/${this.imageUrl}.jpg`;
      }

      this._userService.userSet(this.userSetForm.value).subscribe(
        (data) => {
          this.loading = false;
          if (data.status == 200) {
            // this.pdfLabel(this.getControl('tracking_code').value, this.labelName);
            this.checkAudioStatus(1, data.message);
            const category = +this.getControl("category_id").value;
            this.userSetForm.reset({
              user_code: "ARM",
              code: "ARM",
              autoscan: this.getAutoscanValue(),
              dispatch: this.getControl("dispatch").value,
              quantity: 1,
              category_id: category,
              battery_type: 0,
              brand: false,
              dangerous: false,
              image_path: null,
            });
            this.machineValue = null;
            this.scannedStatus = false;
            this.labelName = null;
            this.allData = null;
            this.disableBtn = false;
            this.allDetails.nativeElement.focus();
          } else if (data.status == 400) {
            if (data.client && data.e != undefined && data.e.code == "1001") {
              this.checkAudioStatus(3, data.message);
            } else {
              this.checkAudioStatus(4, data.message);
            }
            this.disableBtn = false;
          }
        },
        (error) => {
          console.log(error);
          this.checkAudioStatus(4, error.message);
          this.disableBtn = false;
        }
      );
    } else {
      this.checkAudioStatus(2, "check all required fields");
    }
  }

  public checkTheTrackingCode(): void {
    const tracking = this.getControl("tracking_code").value;
    if (this.machineValue) {
      this.imageUrl = tracking;
    } else {
      this.imageUrl = null;
    }
    if (tracking) {
      const defaultData = { tracking_code: tracking };
      const withAutoScan = {
        tracking_code: tracking,
        auto_scan: this.getControl("autoscan").value,
      };
      const dataToReq = this.getControl("autoscan").value
        ? withAutoScan
        : defaultData;
      this.loading = true;
      this._userService.checkOrder(dataToReq).subscribe((data) => {
        this.loading = false;
        if (data.status == 200) {
          this.scannedStatus = true;
          if (
            data.onex_global !== null &&
            data.onex_am !== null &&
            data.onex_am.data != ""
          ) {
            console.log("found all data");
          } else {
            switch (true) {
              case data.onex_am !== null && data.onex_am.data != "":
                let fullname =
                  data.onex_am.data[0].recipient.length > 11
                    ? data.onex_am.data[0].recipient
                    : data.onex_am.data[0].firstname +
                      " " +
                      data.onex_am.data[0].lastname +
                      " " +
                      data.onex_am.data[0].user_code;
                if (+data.onex_am.data[0].dispatch == 2) {
                  // this.checkAudioStatus(7, 'Expected');

                  if (data.onex_am.data[0].is_person === "0") {
                    this.message = `Company Marine`;
                    this.companyPopup();
                    this.checkAudioStatus(12, "Company Marine");
                  } else {
                    this.checkAudioStatus(9, "dispatch Marine");
                  }

                  data.onex_am.data[0].user_code = data.onex_am.data[0].user_code
                    .replace("ARM", "MT")
                    .toUpperCase();
                  fullname = this.breakingFullName(fullname, "ARM", "MT");
                } else {
                  if (data.onex_am.data[0].is_person === "0") {
                    // this.checkAudioStatus(7, 'Expected');

                    if (+data.onex_am.data[0].dispatch === 0) {
                      this.checkAudioStatus(11, "Company Air");
                    } else {
                      this.checkAudioStatus(13, "Company Ground");
                    }
                    this.companyPopup();
                  } else {
                    this.checkAudioStatus(14, "dispatch Air");
                  }
                }
                this.getControl("dispatch").setValue(
                  +data.onex_am.data[0].dispatch
                );
                this.labelName = fullname;
                this.getControl("user_code").setValue(
                  data.onex_am.data[0].user_code
                );
                this.getControl("code").setValue("ARM");
                this.weightInput.nativeElement.focus();
                this.weightInput.nativeElement.select();
                break;
              case data.onex_global !== null:
                this.labelName =
                  data.onex_global.recipients[0].full_name +
                  " " +
                  data.onex_global.recipients[0].address2;
                this.getControl("user_code").setValue(
                  data.onex_global.recipients[0].address2
                );
                this.getControl("code").setValue("RU");
                this.checkAudioStatus(7, "Expected");
                this.weightInput.nativeElement.focus();
                this.weightInput.nativeElement.select();
                break;
              case data.georgia_onex !== null:
                this.labelName = data.georgia_onex.client_fullname;
                this.getControl("code").setValue("GA");
                this.getControl("user_code").setValue(
                  data.georgia_onex.user_code
                );
                if (data.partner_provider === "pinduoduo") {
                  this.checkAudioStatus(16, "Pinduoduo");
                  this.weightInput.nativeElement.focus();
                  this.weightInput.nativeElement.select();
                  break;
                }
                this.checkAudioStatus(7, "Expected");
                this.weightInput.nativeElement.focus();
                this.weightInput.nativeElement.select();
                break;
              case data.pindoudou_hub !== null:
                this.labelName = data.pindoudou_hub.client_fullname;
                const user_code = data.pindoudou_hub.user_code.slice(0, 3);

                this.getControl("code").setValue(user_code);
                this.getControl("user_code").setValue(
                  data.pindoudou_hub.user_code
                );
                this.getControl("dispatch").setValue(
                  +data.pindoudou_hub.dispatch
                );
                if (data.dispatch === 2) {
                  this.checkAudioStatus(9, "Pindoudou Marine");
                } else {
                  this.checkAudioStatus(14, "Pindoudou Air");
                }
                this.weightInput.nativeElement.focus();
                this.weightInput.nativeElement.select();
                break;

              case data.moldova !== null:
                //
                this.labelName = data.moldova.client_fullname;
                this.getControl("user_code").setValue(data.moldova.user_code);
                this.getControl("code").setValue("M");
                break;
              case data.moldova !== null:
                this.labelName =
                  data.moldova.recipients[0].full_name +
                  " " +
                  data.moldova.recipients[0].address2;
                this.getControl("user_code").setValue(
                  data.moldova.recipients[0].address2
                );
                this.getControl("code").setValue("M");
                this.checkAudioStatus(7, "Expected");
                this.weightInput.nativeElement.focus();
                this.weightInput.nativeElement.select();
                break;
              default:
                // this.order.code = 'ARM';
                console.log("default: ", data);
            }
          }
        }
        if (data.status == 403) {
          this.checkAudioStatus(5, data.message);
        }
        if (data.status === 402) {
          this.scannedStatus = true;
          this.checkAudioStatus(
            data.message === "Archive"
              ? 15
              : data.message === "Reject"
              ? 17
              : 8,
            data.message
          );
        }
        if (data.status === 404) {
          this.checkAudioStatus(2, data.message);
        }
      });
    }
  }

  public closeCompanyPopup() {
    this.message = null;
  }

  public checkUserCode(us_code, code): void {
    this.arm_clients = [];
    this.ga_clients = [];
    // let ln = code == 'ARM' ? 7 : 6;
    let ln = 4;
    if (us_code.startsWith("MT", 0)) {
      us_code = us_code.replace("MT", "ARM").toUpperCase();
    }
    if (this.scannedStatus) {
      if (us_code.length >= ln) {
        this.loading = true;
        this._userService
          .checkUserCode({ user_code: us_code, code: code })
          .subscribe(
            (d) => {
              if (d.status == 200) {
                if (d.arm_data != undefined && d.arm_data.length > 0) {
                  if (this.getControl("dispatch").value == 2) {
                    d.arm_data = d.arm_data.map((item) => {
                      const splittedName = item.fullname.split(" ");
                      splittedName[splittedName.length - 1] = splittedName[
                        splittedName.length - 1
                      ]
                        .replace("ARM", "MT")
                        .toUpperCase();
                      item.fullname = splittedName.join(" ");
                      return item;
                    });
                  }
                  this.arm_clients = d.arm_data;
                }
                if (d.onex_georgia != undefined && d.onex_georgia.length > 0) {
                  if (this.getControl("dispatch").value == 2) {
                    d.onex_georgia = d.onex_georgia.map((item) => {
                      const name =
                        item.first_name + item.last_name + item.user_code;
                      const splittedName = name.split(" ");
                      splittedName[splittedName.length - 1] = splittedName[
                        splittedName.length - 1
                      ]
                        .replace("GA")
                        .toUpperCase();
                      item.fullname = splittedName.join(" ");
                      return item;
                    });
                  }
                  this.ga_clients = d.onex_georgia;
                }
              }
              this.loading = false;
            },
            (e) => {
              console.log(e);
            }
          );
      } else {
        this.arm_clients = [];
        this.ga_clients = [];
      }
    } else {
      this.checkAudioStatus(8, "first check the tracking code!");
    }
  }

  public changeMode(): void {
    let auto = this.getControl("autoscan").value ? "on" : "off";
    localStorage.setItem("autoScan", auto);
  }

  generateTracking() {
    this._userService.getTracking().subscribe(
      (data) => {
        if (data.status == 200) {
          this.getControl("autoscan").setValue(true);
          this.getControl("tracking_code").setValue(data.tracking_code);
          this.changeMode();
          this.checkTheTrackingCode();
          this.userCodeInput.nativeElement.focus();
          this.generated = true;
          this._userService.createScanOrder(data.tracking_code).subscribe();
        }
      },
      (error) => console.log(error)
    );
    // }
  }

  public pdfLabel(t_code, labelName): void {
    // es6printJS(`http://127.0.0.1:8000/api/printLabelChina?code=chizag1234565656&name=Harout Abrahamyan ARM333333`, 'pdf');
    // return;
    if (t_code != undefined && labelName != undefined && labelName.length > 7) {
      es6printJS(
        `http://warehouseback.onex.am/api/printLabelChina?code=${t_code}&name=${labelName}`,
        "pdf"
      );
      // es6printJS('https://onex.am/api/pdflabel?tcode=' + t_code + '&name=' + labelName, 'pdf');
    } else {
      alert("tracking and User Code fields are required");
    }
  }

  public selectedClient(
    id,
    recipient,
    fullName,
    is_person,
    us_code,
    dest
  ): void {
    this.dynamicFocus();
    this.message = null;
    this.audioStatus = null;
    if (is_person !== "1" && is_person !== 1 && is_person !== "") {
      this.checkAudioStatus(6, "Company");
    }
    this.labelName = fullName;
    let armPos;
    let user_code;
    if (id === "17") {
      user_code = "GA112233";
    } else {
      if (
        id !== "12244" &&
        id !== "4903" &&
        id !== "17" &&
        id !== "1004" &&
        id !== "1004" &&
        id !== "0" &&
        id !== "1"
      ) {
        if (this.getControl("dispatch").value == 2) {
          armPos = fullName.lastIndexOf("MT");
          fullName = this.breakingFullName(fullName, "MT", "ARM");
          user_code = fullName.slice(armPos);
        } else {
          armPos = fullName.lastIndexOf(us_code);
          user_code = fullName.slice(armPos);
        }
      } else {
        armPos = fullName.lastIndexOf(us_code);
        user_code = fullName.slice(armPos);
      }
    }

    if (this.getControl("tracking_code").value) {
      this.getControl("code").setValue(us_code);
      let dataToSend = {
        dest: id === "17" ? "GA" : dest,
        dispatch: `${this.getControl("dispatch").value}`,
        user_id: id,
        user_code: user_code,
        is_person: is_person,
        recipient: fullName,
        idrecipient: recipient,
        trackingcode: this.getControl("tracking_code").value,
      };
      this._userService.setClientInfo(dataToSend).subscribe(
        (r) => {
          this.arm_clients = [];
          this.ga_clients = [];
          if (r.is_person === "0" && !r.expected) {
            this.message = `Company ${r.client_fullname}`;
            if (r.dispatch === "2") {
              this.checkAudioStatus(12, "Company Marine");
            } else if (r.dispatch === "0") {
              this.checkAudioStatus(11, "Company Air");
            }
            this.companyPopup();
          }
          if (this.getControl("dispatch").value == 2) {
            fullName = this.breakingFullName(fullName, "ARM", "MT");
            user_code = fullName.slice(armPos);
          }
          this.getControl("user_code").setValue(user_code);
        },
        (e) => {
          console.log(e);
        }
      );
    } else {
      this.checkAudioStatus(8, "first check the tracking code!");
    }
    // let user_code = armPos.slice(armPos, fullName.length);
  }

  private dynamicFocus(): void {
    if (
      this.getControl("dispatch").value === 0 ||
      this.getControl("dispatch").value === "0"
    ) {
      this.airInput.nativeElement.focus();
    } else if (
      this.getControl("dispatch").value === 2 ||
      this.getControl("dispatch").value === "2"
    ) {
      this.seaInput.nativeElement.focus();
    } /*else if (this.getControl('dispatch').value === 1 || this.getControl('dispatch').value === '1') {
            this.groundInput.nativeElement.focus();
        } */
  }

  private setControls(): FormGroup {
    return this.fb.group({
      tracking_code: [null, Validators.required],
      user_code: ["ARM", Validators.required],
      code: ["ARM"],
      waight: [null, Validators.required],
      dispatch: [0, Validators.required],
      autoscan: [false, Validators.required],
      width: [null],
      length: [null],
      height: [null],
      v_waight: [null],
      additional_cost: [null],
      add_charge_cost: [null],
      dangerous: [false],
      comment: [null],
      comment2: [null],
      battery_type: [0],
      category_id: [1],
      brand: [false],
      quantity: [1],
    });
  }

  private getAutoscanValue(): boolean {
    let mode = localStorage.getItem("autoScan");
    return mode === "on";
  }

  private breakingFullName(name, defaultStr, replaceWith): string {
    const splittedName = name.split(" ");
    splittedName[splittedName.length - 1] = splittedName[
      splittedName.length - 1
    ]
      .replace(defaultStr, replaceWith)
      .toUpperCase();
    return splittedName.join(" ");
  }

  private companyPopup(): void {
    this.openModal.nativeElement.click();
  }

  private checkAudioStatus(audioStatus, message): void {
    switch (audioStatus) {
      case 1:
        this.audioStatus = "../assets/audio/ok.mp3";
        this.message = message;
        break;
      case 2:
        this.audioStatus = "../assets/audio/error.mp3";
        this.message = message;
        break;
      case 3:
        this.audioStatus = "../assets/audio/service_is_not_done.mp3";
        this.message = message;
        break;
      case 4:
        this.audioStatus = "../assets/audio/unknown_command.mp3";
        this.message = message;
        break;
      case 5:
        this.audioStatus = "../assets/audio/service.mp3";
        this.message = message;
        break;
      case 6:
        this.audioStatus = "../assets/audio/company.mp3";
        this.message = message;
        break;
      case 7:
        this.audioStatus = "../assets/audio/expected.mp3";
        this.message = message;
        break;
      case 8:
        this.audioStatus = "../assets/audio/oldItem.mp3";
        this.message = message;
        break;
      case 9:
        this.audioStatus = "../assets/audio/marine.mp3";
        this.message = message;
        break;
      case 10:
        this.audioStatus = "../assets/audio/generate_label.mp3";
        this.message = message;
        break;
      case 11:
        this.audioStatus = "../assets/audio/company-air.mp3";
        this.message = message;
        break;
      case 12:
        this.audioStatus = "../assets/audio/company-marine.mp3";
        this.message = message;
        break;
      case 13:
        this.audioStatus = "../assets/audio/company-ground.mp3";
        this.message = message;
        break;
      case 14:
        this.audioStatus = "../assets/audio/air.mp3";
        this.message = message;
        break;
      case 15:
        this.audioStatus = "../assets/audio/archive.mp3";
        this.message = message;
        break;
      case 16:
        this.audioStatus = "../assets/audio/pinduoduo.mp3";
        this.message = message;
        break;
      case 17:
        this.audioStatus = "../assets/audio/reject.mp3";
        this.message = message;
        break;
    }
    setTimeout(() => {
      // this.ok = null;
      this.audioStatus = null;
      if (this.message === "Company Marine" || this.message === "Company Air") {
        return;
      } else {
        this.message = null;
      }
    }, 3500);
  }
}
