import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

const host = environment.API_ENDPOINT_ROOT;

@Injectable()
export class DeclarationService {
  constructor(private httpClient: HttpClient) {
  }

  getDeclarationsByTrackingGermany() {
    return this.httpClient.get<any>(host + '/getDeclaredOrdersGermany');
  }

  getDeclarationByTrackingGermany(tracking_code) {
    return this.httpClient.post<any>(host + '/getDeclaredOrderGermany', tracking_code);
  }

  getOrderById(id: number) {
    return this.httpClient.get<any>(`${host}/declarationService/${id}`);
  }
  deleteDeclaration(id: number) {
    return this.httpClient.delete<any>(`${host}/deleteDeclaration/${id}`);
  }
  declare(form) {
    return this.httpClient.post<any>(`${host}/declare`, form);
  }

}

