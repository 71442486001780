import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Order, User } from "../_models";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs-compat";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const host = environment.API_ENDPOINT_ROOT;
const local = "http://localhost:8000/api";

@Injectable()
export class UserService {
  userName;
  userRole;
  boxTrackingPrefix;
  countordergroups;

  public $scanData: BehaviorSubject<any> = new BehaviorSubject<any>(0);

  constructor(private httpClient: HttpClient) {}

  // users Routing

  getAll() {
    return this.httpClient.get<any>(host + "/users", httpOptions).pipe();
  }

  getById(id: number) {
    return this.httpClient
      .get<any>(host + "/getById/" + id, httpOptions)
      .pipe();
  }

  create(user: User) {
    return this.httpClient.post(host + "/signup", user, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  edituser(user: User) {
    return this.httpClient
      .post<any>(host + "/editUser", user, httpOptions)
      .pipe();
  }

  // Werehouse Routing

  getPahest() {
    return this.httpClient.get<any>(host + "/getPahest", httpOptions).pipe();
  }

  createWerehouse(user) {
    return this.httpClient.post(host + "/createpahest", user, httpOptions);
  }

  editwherehouse(pahestId, fd) {
    return this.httpClient.post(
      host + "/editPahest/" + pahestId,
      fd,
      httpOptions
    );
  }

  // Tracking Code Routing

  getTracking() {
    return this.httpClient
      .get<any>(host + "/getNewTrackingcode", httpOptions)
      .pipe();
  }

  getBoxTracking() {
    return this.httpClient
      .get<any>(host + "/generateBoxTrackingcode", httpOptions)
      .pipe();
  }

  // Order Routing

  getAllOrders() {
    return this.httpClient.get<any>(host + "/orders", httpOptions).pipe();
  }

  getOrderCategories() {
    return this.httpClient
      .get<any>(host + "/orderCategories", httpOptions)
      .pipe();
  }

  getorderById(id: number) {
    return this.httpClient
      .get<any>(host + "/getOrderById/" + id, httpOptions)
      .pipe();
  }

  userSet(order) {
    return this.httpClient
      .post<any>(host + "/userSet", order, httpOptions)
      .pipe();
  }

  sendOrder(order) {
    return this.httpClient
      .post<any>(host + "/sendOrder", order, httpOptions)
      .pipe();
  }

  createOrder(user: Order) {
    return this.httpClient.post(host + "/createorder", user, httpOptions);
  }

  getOrderForEdit(tracking_code) {
    return this.httpClient.post<any>(host + "/getOrderForEdit", tracking_code);
  }

  getOrdersForReturn() {
    return this.httpClient.get<any>(host + "/getOrdersForReturn");
  }

  getOrdersGermanyPayConf() {
    return this.httpClient.get<any>(host + "/getOrdersForGermanyPayReq");
  }

  getManifestGermany(parcel) {
    return this.httpClient.post<any>(host + "/getManifestGermany", parcel);
  }

  getOrderByTracking(tracking_code) {
    return this.httpClient.post<any>(
      host + "/getOrderByTracking",
      tracking_code
    );
  }

  getOrderByTrackingGermany(tracking_code) {
    return this.httpClient.post<any>(
      host + "/getOrderByTrackingGermany",
      tracking_code
    );
  }

  uploadFileToReturn(uploadData) {
    let formData: any = new FormData();
    formData.append("id", uploadData.id);
    formData.append("file", uploadData.docFile);
    return this.httpClient.post<any>(`${host}/uploadFileToReturn`, formData, {
      reportProgress: true,
    });
  }

  uploadFileMAWB(uploadData) {
    let formData: any = new FormData();
    formData.append("file", uploadData.file);
    return this.httpClient.post<any>(
      `${host}/attachMAWB/${uploadData.id}`,
      formData,
      {
        reportProgress: true,
      }
    );
  }

  setOrderReturned(id) {
    return this.httpClient.post<any>(host + "/setOrderReturned", id);
  }

  setOrderConfirmed(id) {
    return this.httpClient.post<any>(host + "/setOrderConfirmed", id);
  }

  setOrderRejected(id) {
    return this.httpClient.post<any>(host + "/setOrderRejected", id);
  }

  needConfirmToggle(id) {
    return this.httpClient.post<any>(host + "/needConfirmToggle", id);
  }

  editorder(user: Order) {
    return this.httpClient
      .post<any>(host + "/editOrder", user, httpOptions)
      .pipe();
  }

  createScanOrder(user: Order) {
    return this.httpClient.post(host + "/createScanOrder", user, httpOptions);
  }

  createRepack(user: Order) {
    return this.httpClient.post(host + "/createRepack", user, httpOptions);
  }

  createexpectedtoorder(order) {
    return this.httpClient
      .post<any>(host + "/createexpected", order, httpOptions)
      .pipe();
  }

  searchOrder(order) {
    return this.httpClient
      .post<any>(host + "/searchorder", order, httpOptions)
      .pipe();
  }

  getOrderGroup() {
    return this.httpClient
      .get<any>(host + "/getOrderGroup", httpOptions)
      .pipe();
  }

  getRuOrder() {
    return this.httpClient.get<any>(host + "/ru-orders", httpOptions).pipe();
  }

  get_orders_for_manifest(orders_ids) {
    return this.httpClient.post(
      host + "/get_orders_for_manifest",
      orders_ids,
      httpOptions
    );
  }

  getorders(ordergroupid) {
    return this.httpClient
      .get<any>(host + "/ordersFromGroup/" + ordergroupid, httpOptions)
      .pipe();
  }

  getRuOrders(ordergroupid) {
    return this.httpClient.get<any>(host + `/getRuOrders/${ordergroupid}`);
  }

  getUaOrders(ordergroupid) {
    return this.httpClient.get<any>(host + `/getUaOrders/${ordergroupid}`);
  }

  getRuOrderGroups() {
    return this.httpClient.get<any>(host + "/getRuOrderGroups");
  }

  getUaOrderGroups() {
    return this.httpClient.get<any>(host + "/getUaOrderGroups");
  }

  order_request(groups) {
    return this.httpClient
      .post<any>(host + "/groupsIds", groups, httpOptions)
      .pipe();
  }

  change_pony_status(groups) {
    return this.httpClient
      .post<any>(host + "/ponyStatus", groups, httpOptions)
      .pipe();
  }

  change_pony_status_tosent(group) {
    return this.httpClient
      .post<any>(host + "/ponyStatusTosent", group, httpOptions)
      .pipe();
  }

  manifest() {
    return this.httpClient.get<any>(host + "/manifest", httpOptions).pipe();
  }

  setPreBox(data) {
    return this.httpClient.post<any>(host + "/orderPreBoxForRu", data);
  }

  setPreBoxForUk(data) {
    return this.httpClient.post<any>(host + "/orderPreBoxForUk", data);
  }

  // Box Routing

  getBox() {
    return this.httpClient.get<any>(host + "/boxes", httpOptions).pipe();
  }

  createnewbox(box) {
    return this.httpClient.post(host + "/createbox", box, httpOptions);
  }

  getByIdBox(id: number) {
    return this.httpClient
      .get<any>(host + "/getBoxById/" + id, httpOptions)
      .pipe();
  }

  editBox(box) {
    return this.httpClient
      .post<any>(host + "/editbox", box, httpOptions)
      .pipe();
  }

  editParcelWithBoxes(boxes) {
    return this.httpClient
      .post<any>(host + "/editParcelWithBoxes", boxes, httpOptions)
      .pipe();
  }

  parcelSend(id) {
    return this.httpClient
      .post<any>(host + "/parcelSend", id, httpOptions)
      .pipe();
  }

  getBoxingInfo(tracking) {
    return this.httpClient
      .get<any>(host + "/getBoxingInfo/" + tracking, httpOptions)
      .pipe();
  }

  addOrderToBox(tracking) {
    return this.httpClient
      .post<any>(host + "/addOrderToBox", tracking, httpOptions)
      .pipe();
  }

  forceOrderToBox(tracking) {
    return this.httpClient
      .post<any>(host + "/forceOrderToBox", tracking, httpOptions)
      .pipe();
  }

  getBoxWithOrder(trackingcode) {
    return this.httpClient
      .get<any>(host + "/getBoxWithOrder/" + trackingcode, httpOptions)
      .pipe();
  }

  unbox(tracking) {
    return this.httpClient
      .post<any>(host + "/unbox", tracking, httpOptions)
      .pipe();
  }

  rmBox(id) {
    return this.httpClient
      .post<any>(host + "/removeBox", id, httpOptions)
      .pipe();
  }

  // Parcel Routing

  getParcel() {
    return this.httpClient.get<any>(host + "/parcels", httpOptions).pipe();
  }

  getByIdParcel(id: number) {
    return this.httpClient
      .get<any>(host + "/getParcelById/" + id, httpOptions)
      .pipe();
  }

  getParcelDetails(id: number) {
    return this.httpClient
      .get<any>(host + "/getParcelDetails/" + id, httpOptions)
      .pipe();
  }

  createParcel(parcel) {
    return this.httpClient.post(host + "/createparcel", parcel, httpOptions);
  }

  editParcel(parcel) {
    return this.httpClient
      .post<any>(host + "/editparcel", parcel, httpOptions)
      .pipe();
  }

  editParcelOnSend(parcel, id) {
    return this.httpClient
      .post<any>(`${host}/editParcelOnSend/${id}`, parcel, httpOptions)
      .pipe();
  }

  sentUkraineManifest(id) {
    return this.httpClient
      .post<any>(`${host}/sentUkraineManifest/${id}`, httpOptions)
      .pipe();
  }

  rmParcel(id) {
    return this.httpClient
      .post<any>(host + "/removeParcel", id, httpOptions)
      .pipe();
  }

  // Set User Routing

  setUser(id: number) {
    return this.httpClient
      .get<any>(host + "/setuser/" + id, httpOptions)
      .pipe();
  }

  checkOrder(tracking) {
    return this.httpClient
      .post<any>(host + "/checkOrder", tracking, httpOptions)
      .pipe();
  }

  checkUserCode(user_code) {
    return this.httpClient
      .post<any>(host + "/checkUserCode", user_code, httpOptions)
      .pipe();
  }

  setClientInfo(user_info) {
    return this.httpClient
      .post<any>(host + "/setClientInfo", user_info, httpOptions)
      .pipe();
  }

  getManifest(parsel_id) {
    return this.httpClient.get(
      `https://192.168.0.60:8080/getOrdersForManifestExcel/${parsel_id}`
    );
  }

  checkOrderPreBoxForRu(trackingCode) {
    return this.httpClient.get(host + `/checkOrderPreBoxForRu/${trackingCode}`);
  }

  getSwitchValue() {
    return this.httpClient.get(host + `/sorting-machine/switcher`);
  }

  changeSwitchValue(data) {
    return this.httpClient.post(host + `/sorting-machine/switcher`, data);
  }

  extraUserSet(status) {
    return this.httpClient.get(host + `/dws/get-current/${status}`);
  }

  changeStatus(data) {
    return this.httpClient.post(host + `/dws/change-status`, data);
  }

  getRuCategories() {
    return this.httpClient.get(host + "/ru-categories");
  }
}
