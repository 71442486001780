import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SliderActionModal} from 'src/app/service/slider-action/slider-action.component';
import {OrderServices, RessponsPostDone, ServiceStatus,} from 'src/app/_models/serviceStatus.model';
import {Iservice} from 'src/app/_services/iservice.service';
import {SendReturnService} from 'src/app/_services/send-return.service';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs-compat';
import {DoneModalComponent} from './done-modal/done-modal.component';
import {MatDialog} from '@angular/material';


@Component({
    selector: 'app-send-return',
    templateUrl: './send-return.component.html',
    styleUrls: ['./send-return.component.scss'],
})
export class SendReturnComponent implements OnInit, OnDestroy {
    //---->Public varialble start <-----//
    //  onex TableTitle "ID User","Comments",
    public onexTableTitle = [
        '#',
        // 'ID Order',
        'Full Name',
        'Recipient',
        'Dispatch',
        'Tracking Code',
        'Weight',
        'Service',
        'Images',
        'Label',
        'Action',
    ];
    public allOrder;
    public lenAll: number;
    public lenSend: number = 0;
    public lenStop: number = 0;
    public lenReturn: number = 0;
    public indexBtn = null;
    public orders: OrderServices[];
    public sendAndReturnColumn = false;
    public message: any;
    public isChange: boolean = false;
    //---->Public varialble end <-----//

    //---->Private varialble start <-----//
    private _unsubscribe$ = new Subject<void>();

    //---->Private varialble end <-----//
    constructor(
        public _sendReturnService: SendReturnService,
        private modalServiceMaterial: MatDialog,
        private modalService: NgbModal,
        public iService: Iservice,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.allStatusOrder(null);
        this.message = this._sendReturnService.message;
        console.log(this.message);
        // this._getOrders().subscribe()
    }

    // -----> Get All Orders <---- //

    public allStatusOrder(ss, bb?) {
        this.onexTableTitle = [
            '#',
            // 'ID Order',
            'Full Name',
            'Recipient',
            'Dispatch',
            'Tracking Code',
            'Weight',
            'Service',
            'Images',
            'Label',
            'Action',
        ];
        this.sendAndReturnColumn = false;
        if (ss === 1) {
            this.sendAndReturnColumn = true;
            this.onexTableTitle = [
                '#',
                // 'ID Order',
                'Full Name',
                'Recipient',
                'Dispatch',
                'Tracking Code',
                'Weight',
                'Service',
                'Images',
                'Label',
                'Send Date',
                'Action',
            ];
        } else if (ss === 3) {
            this.sendAndReturnColumn = true;
            this.onexTableTitle = [
                '#',
                // 'ID Order',
                'Full Name',
                'Recipient',
                'Dispatch',
                'Tracking Code',
                'Weight',
                'Service',
                'Images',
                'Label',
                'Return Date',
                'Action',
            ];
        }
        switch (ss) {
            case 1: {
                // this._getOrders(1).subscribe();
                if (this.allOrder) {
                    this.orders = this.allOrder;
                    this.orders = this.orders.filter((order) => {
                        return order.stop_status === '1';
                    });
                    this.indexBtn = 1;
                }
                break;
            }
            case 2: {
                // this._getOrders(2).subscribe();
                if (this.allOrder) {
                    this.orders = this.allOrder;
                    this.orders = this.orders.filter((order) => {
                        return order.stop_status === '2';
                    });
                    this.indexBtn = 2;
                }
                break;
            }
            case 3: {
                if (this.allOrder) {
                    this.orders = this.allOrder;
                    this.orders = this.orders.filter((order) => {
                        return order.stop_status === '3';
                    });
                    this.indexBtn = 3;
                }
                break;
            }
            default: {
                if (bb) {
                    return;
                } else {
                    this.indexBtn = null;
                }
                this._getOrders(null).subscribe();
            }
        }
    }

    // -----> Filter Orders in Status ID <----- ///

    public openModal(order_id) {
        const modalRef = this.modalServiceMaterial.open(SliderActionModal, {
            width: '1000px',
            panelClass: 'custom-dialog-container',
            maxHeight: '95vh',
            data: {
                dataKey: order_id
            }
        });
    }

    //------> Open Slider Modal <------

    public doneService(order_id, status_code): void {
        const obj: RessponsPostDone = {
            order_id: order_id,
        };
        this._sendReturnService
            .doneService(obj)
            .pipe(
                takeUntil(this._unsubscribe$),
                switchMap((data) => {
                    return this._getOrders(status_code);
                })
            )
            .subscribe();
    }

    // -----> Done Service function send Order id in back-end <------- ///

    public openConfirm(order_id, status_code, type) {
        let confirmObject = {
            type: type,
            order_id: order_id,
            status_code: status_code,
        };
        const modalRef = this.modalService.open(DoneModalComponent, {
            windowClass: 'doneConfirm',
        });
        modalRef.componentInstance.confirmObject = confirmObject;
        modalRef.componentInstance.passEntry
            .pipe(
                takeUntil(this._unsubscribe$),
                switchMap((data) => {
                    console.log(data);
                    return this._getOrders(status_code);
                })
            )
            .subscribe((receivedEntry) => {
            });
    }

    ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }


    // ----> called before the Angular framework removes the component. <-----//

    private _getOrders(status_code): Observable<void> {
        return this._sendReturnService.sendAndReturn().pipe(
            takeUntil(this._unsubscribe$),
            map((data: ServiceStatus) => {
                this.orders = data.data;
                this.allOrder = data.data;
                this.orders = this.allOrder;
                this.lenAll = this.orders.length;
                this.lenSend = this.orders.filter((x) => {
                    return x.stop_status === '1';
                }).length;
                this.lenStop = this.orders.filter((x) => {
                    return x.stop_status === '2';
                }).length;
                this.lenReturn = this.orders.filter((x) => {
                    return x.stop_status === '3';
                }).length;

                if (status_code === null) {
                    this.lenAll = this.orders.length;
                    this.orders = data.data;
                    this.indexBtn = null;
                } else if (status_code === 1) {
                    this.orders = data.data;
                    this.orders = this.orders.filter((order) => {
                        return order.stop_status === '1';
                    });
                    this.lenStop = this.orders.filter((x) => {
                        return x.stop_status === '1';
                    }).length;
                } else if (status_code === 2) {
                    this.orders = data.data;
                    this.orders = this.orders.filter((order) => {
                        if (order.stop_status === '2') {
                            console.log(order);
                        }
                        return order.stop_status === '2';
                    });
                    this.lenStop = this.orders.filter((x) => {
                        return x.stop_status === '2';
                    }).length;
                } else if (status_code === 3) {
                    this.orders = data.data;
                    this.orders = this.orders.filter((order) => {
                        return order.stop_status === '3';
                    });
                    this.lenStop = this.orders.filter((x) => {
                        return x.stop_status === '3';
                    }).length;
                }
            })
        );
    }
}

