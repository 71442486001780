import {Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges} from '@angular/core';

@Component({
  selector: 'app-add-order-shelf',
  templateUrl: './add-order-shelf.component.html',
  styleUrls: ['./add-order-shelf.component.scss']
})
export class AddOrderShelfComponent implements OnChanges, OnInit {

  @Input() selectedShelfBox;
  newOrdersInBox = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    const changedValue: SimpleChange = changes.selectedShelfBox;
    // console.log('prev value: ', changedValue.previousValue);
    // console.log('got changedValue: ', changedValue.currentValue);
    this.newOrdersInBox = changedValue.currentValue.reverse();
  }

  ngOnInit() {
    if (this.selectedShelfBox) {
      this.newOrdersInBox = this.selectedShelfBox.reverse();
    }
  }
}
