import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {UsersComponent} from './users/users.component';
import {RepackComponent} from './order-actions/repack/repack.component';
import {ScanOrderComponent} from './order-actions/scan-order/scan-order.component';
import {WarehouseComponent} from './warehouse/warehouse.component';
import {AdminAuthGuard, AuthGuard, LoginGuard} from './_guards';
import {ParcelsComponent} from './boxes/parcels/parcels.component';
import {ParcelsCreateComponent} from './boxes/parcels-create/parcels-create.component';
import {BoxCreateComponent} from './boxes/box-create/box-create.component';
import {BoxesComponent} from './boxes/boxes/boxes.component';
import {BoxingComponent} from './boxes/boxing/boxing.component';
import {UserSetComponent} from './order-actions/user-set/user-set.component';
import {UserSetExtraComponent} from './order-actions/user-set-extra/user-set-extra.component';
import {SendReturnComponent} from './order-actions/send-return/send-return.component';
import {ServicesComponent} from './services/services.component';
import {OrdersGroupComponent} from './orders/order-group/ordergroup.component';
import {PackedComponent} from './orders/order-group/packed/packed.component';
import {SentComponent} from './orders/order-group/sent/sent.component';
import {ShelvesComponent} from './order-actions/shelves/shelves.component';
import {CreateShelfComponent} from './order-actions/create-shelf/create-shelf.component';
import {MainComponent} from './partials/main/main.component';
import {ParcelDetailsComponent} from './boxes/parcel-details/parcel-details.component';
import {ShelfingComponent} from './order-actions/shelfing/shelfing.component';
import {OrderStatsComponent} from './orders/order-stats/order-stats.component';
import {TrackingReportComponent} from './tracking-report/tracking-report.component';
import {EditOrderComponent} from './order-actions/edit-order/edit-order.component';
import {ReturnComponent} from './order-actions/return/return.component';
import {GermanyPayConfirmComponent} from './orders/germany-pay-confirm/germany-pay-confirm.component';
import {GermanyDeclarationsComponent} from './orders/germany-declarations/germany-declarations.component';
import {DeclareComponent} from './orders/germany-declarations/declare/declare.component';
import {UkraineComponent} from './orders/ukraine/ukraine.component';
import {IUserSetComponent} from './order-actions/i-user-set/i-user-set.component';
import {ReportComponent} from './report/report.component';
import {InventoriesComponent} from './inventories/inventories.component';
import {EditOrdersComponent} from './edit-orders/edit-orders.component';
import {TeamReportComponent} from './team-report/team-report.component';
import {IappService} from './service/iservice.component';
import {ReturnServiceComponent} from './return-service/return-service.component';
import {ExtraServiceComponent} from './order-actions/extra-service/extra-service.component';
import {RussianConsolidationComponent} from './orders/russian-consolidation/russian-consolidation.component';
import {UkraineConsolidationComponent} from './orders/ukraine-consolidation/ukraine-consolidation.component';
import {RussianOrdersComponent} from './orders/russian-orders/russian-orders.component';
import {AppRussianParcelsComponent} from './boxes/russian-parcels/russian-parcels.component';
import {AppShowRussianParcelsComponent} from './boxes/russian-parcels/show-russian-parcels/show-russian-parcels.component';
import {
    AppRussianWarehouseConsolidationComponent
} from './orders/russian-warehouse-consolidation/russian-warehouse-consolidation.component';
import {AppReadyToSendComponent} from './orders/ready-to-send/ready-to-send.component';
import {AppBoxEditComponent} from './boxes/box-edit/box-edit.component';
import {ExtraOrdersComponent} from './orders/extra-orders/extra-orders.component';
import {AppRussianParcelsScanComponent} from './boxes/russian-parcels/russian-parcels-scan/russian-parcels-scan.component';
import {ExpectedComponent} from './orders/expected/expected.component';


// canActivate: [AuthGuard]
const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'users', component: UsersComponent, canActivate: [AdminAuthGuard]},
            {path: 'repack', component: RepackComponent},
            // { path: 'orders', component: OrdersComponent },
            {path: 'scan', component: ScanOrderComponent},
            // { path: 'create/order', component: CreateOrderComponent },
            {path: 'warehouse', component: WarehouseComponent, canActivate: [AdminAuthGuard]},
            {path: 'parcels', component: ParcelsComponent},
            {path: 'russian-parcels', component: AppRussianParcelsComponent},
            {path: 'russian-parcels-scan', component: AppRussianParcelsScanComponent},
            {path: 'russian-parcels/show/:id', component: AppShowRussianParcelsComponent},
            {path: 'parcel/:id', component: ParcelDetailsComponent},
            {path: 'create/parcel', component: ParcelsCreateComponent},
            {path: 'boxes', component: BoxesComponent},
            {path: 'create/box', component: BoxCreateComponent},
            {path: 'edit-boxes', component: AppBoxEditComponent},
            {path: 'boxing', component: BoxingComponent},
            {path: 'user/set', component: UserSetComponent},
            {path: 'extra/user/set', component: UserSetExtraComponent},
            {path: 'extra-orders', component: ExtraOrdersComponent},
            {path: 'userSet', component: IUserSetComponent},
            {path: 'shelf', component: ShelvesComponent},
            {path: 'shelfing', component: ShelfingComponent},
            {path: 'createShelf', component: CreateShelfComponent, canActivate: [AdminAuthGuard]},
            {path: 'ireport', component: TrackingReportComponent},
            {path: 'report', component: ReportComponent},
            {path: 'inventories', component: InventoriesComponent},
            {path: 'edit-orders', component: EditOrdersComponent},
            {path: 'team-report', component: TeamReportComponent},
            {path: 'send-return', component: SendReturnComponent},
            {path: 'pay-confirm', component: GermanyPayConfirmComponent},
            {path: 'declarations', component: GermanyDeclarationsComponent},
            {path: 'declare/:id', component: DeclareComponent},
            {path: 'iservice', component: IappService},
            {path: 'returns-service', component: ReturnServiceComponent},
            {path: 'extra-service', component: ExtraServiceComponent},
            // { path: 'order/services', component: ServiceComponent },
            {path: 'services', component: ServicesComponent},
            {path: 'ordergroup', component: OrdersGroupComponent},
            {path: 'russianConsolidation', component: RussianConsolidationComponent},
            {path: 'readyToSend', component: AppReadyToSendComponent},
            {path: 'russianOrders', component: RussianOrdersComponent},
            {path: 'ukraineConsolidation', component: UkraineConsolidationComponent},
            {path: 'russianWarehouseConsolidation', component: AppRussianWarehouseConsolidationComponent},
            {path: 'edit-order', component: EditOrderComponent},
            {path: 'expected', component: ExpectedComponent},
            {path: 'return', component: ReturnComponent},
            {path: 'orderStats', component: OrderStatsComponent},
            {path: 'ordersUkraine', component: UkraineComponent},
            {path: 'packed', component: PackedComponent},
            {path: 'sent', component: SentComponent},
            {
                path: 'unknown', loadChildren: './unknown/unknown.module#UnknownModule'
            },
            {path: '', redirectTo: '/', pathMatch: 'full'},

        ]
    },
    // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    {path: 'login', component: AuthComponent, canActivate: [LoginGuard]},
    {path: '', redirectTo: '/', pathMatch: 'full'},
    // { path: '**', component: PageNotFoundComponent }
];


@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        // { enableTracing: true }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
