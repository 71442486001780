import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterShelfBoxes',
  pure: false
})
export class FilterShelfBoxesPipe implements PipeTransform {

  transform(value: any, args?: string): any {
    if (!Array.isArray(value)) {
      return;
    }
    switch (args) {
      case 'empty':
        value = value.filter(item => item.quantity == 0);
        break;
      case 'hasItem':
        value = value.filter(item => item.quantity !== 0);
        break;
      case 'special':
        value = value.filter(item => item.activated == '2');
        break;
      case 'scaned':
        value = value.filter(item => item.is_scan_ru_warehouse == 1);
        break;
      case 'notScaned':
        value = value.filter(item => item.is_scan_ru_warehouse == 0);
        break;
      case 'found':
        value = value.filter(item => item.color == 'yellow');
        break;
      case 'notFound':
        value = value.filter(item => item.color == 'white');
        break;
      case 'russainWarehouseSpecial':
        value = value.filter(item => item.color == 'blue');
        break;
      case 'readyToSend':
        value = value.filter(item => item.color == 'green');
        break;
      case 'normal':
        value = value.filter(item => item.activated == '1');
        break;
      case 'return':
        value = value.filter(item => item.return == 1);
        break;
      case 'returned':
        value = value.filter(item => item.return == 2);
        break;
      case 'found':
        value = value.filter(item => item.color === 'yellow');
        break;
      case 'partial':
        value = value.filter(item => item.color === 'blue');
        break;
      case 'notFound':
        value = value.filter(item => item.color === 'white');
        break;
      default:
    }
    return value;
  }

}
