import {Component, HostListener, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {RussianParcelsService, UserService} from '../../_services';
import {ScriptLoaderService} from '../../_services/script-loader.service';
import {Router} from '@angular/router';
import {FilterShelfBoxesPipe} from '../../_pipes/filter-shelf-boxes.pipe';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material';
import {ReadTosendSuggetionModal} from './readTosendSuggetion';

@Component({
    selector: 'app-ready-to-send',
    templateUrl: 'ready-to-send.component.html',
    styleUrls: ['ready-to-send.component.scss']
})

export class AppReadyToSendComponent implements OnInit {
    @ViewChild('tracking_code') tracking_code;
    @ViewChild('orderCheckbox') orderCheckbox;
    @ViewChildren('trackCheckbox') public trackCheckbox: QueryList<any>;
    orders = [];
    model = [];
    public couriers: any[] = [];
    public default = 0;
    public courier = [];
    public exportCouriers: string | number;
    courierError = [];
    orderfromOrdersgroup = [];
    public selected = [];
    public oneOrder = [];
    public allSelected = false;
    ogtracking;
    checked = [];
    ponyTracking;
    showLoading = true;
    chack_messege;
    alert_messege;
    chack_ok = 0;
    alert_ok = 0;
    ordersid = [];
    modal = false;
    p;
    print_lable_og;
    print_list_og;
    public finalOrders = [];
    public top = 'top';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public printLabelId: string;
    public activeArr = [];
    public searchOrders;
    public searchConsolidationTrack;
    public activeFilter = 'all';
    public couriersType: any[] = [];
    public all = '0';
    public totalWeight = 0;
    public ordersForCalc = [];
    public totalWeightCalc;
    public totalConsolidation: number;
    public windowScrolled: boolean;
    public audioStatus: string;

    public searchTrackingCode;
    public dd = false;
    public selectedArrLength = 0;
    public message;
    public onlyTrackingCode = [];

    constructor(
        private _userService: UserService,
        private _script: ScriptLoaderService,
        private _router: Router,
        public dialog: MatDialog,
        private filter: FilterShelfBoxesPipe,
        private russianParcelsService: RussianParcelsService,
        @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit() {
        this.showLoading = true;
        this.getOrders();
        this.getCourier();
    }

    get_orders(consolidationTracking) {
        this.activeArr = [];
        this.showLoading = true;
        this.russianParcelsService.getRuOrder(consolidationTracking).subscribe(
            (data: any) => {
                this.orderfromOrdersgroup = data.data;
                this.ogtracking = consolidationTracking;
                // this.orderfromOrdersgroup = data.orders.orders;
                this.showLoading = false;


            },
            error => console.log(error)
        );
    }

    setPreBox(event, order) {
        // order.yellowRow = order.is_activated_from_ru;
        order.yellowRow = event.checked;
        this.russianParcelsService.searchRuOrderGroup({
            tracking_code: order.tracking_code,
            is_activated_from_ru: event.checked ? 1 : 0
        }).subscribe(
            (data: any) => {
                order.is_activated_from_ru = data.is_activated_from_ru;
            }, e => {
                console.log(e);
            }
        );
    }

    checkOrdersActivate() {
        this.totalWeight = 0;
        this.ordersForCalc = [];
        this.totalWeightCalc = 0;
        this.totalConsolidation = 0;
        if (this.couriersType.length) {
            for (let weight of this.couriersType) {
                this.ordersForCalc.push(weight);
                this.totalWeight += Number(weight.weight);
                this.totalConsolidation += +weight.count;
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            }
        } else if (this.exportCouriers === '0' || !this.exportCouriers) {
            for (let weight of this.orders) {
                this.ordersForCalc.push(weight);
                this.totalWeight += Number(weight.weight);
                this.totalConsolidation += +weight.count;
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            }
        } else {
            return;
        }
    }

    search(tracking) {
        this.searchTrackingCode = tracking;
        this.trackCheckbox.map(item => {

            if (item.nativeElement.id === tracking) {
                item.nativeElement.click();
            }
        });
        setTimeout(() => {

            this.tracking_code.nativeElement.value = '';

        }, 1000);
    }

    //
    // searchOrderFromOrdersGroup(tracking) {
    //     this.searchOrders = tracking;
    //     if (this.searchOrders) {
    //         this.orderfromOrdersgroup.filter(i => {
    //             if (i.tracking_code === this.searchOrders) {
    //                 if (i.activated !== '2') {
    //                     this._userService.setPreBox({id: i.id, checked: true}).subscribe(
    //                         d => {
    //                             this._userService.$scanData.next({id: i.id, checked: true});
    //                             if (d.is_send) {
    //                                 if (+d.order.activated === 2) {
    //                                     this.audioStatus = '../assets/audio/send_parcel.mp3';
    //                                     this.message = 'Send Parcel';
    //                                     i.activated = this._userService.$scanData.value.checked ? '2' : '1';
    //                                 }
    //                             }
    //                         }, e => {
    //                             console.log(e);
    //                         }
    //                     );
    //                 } else {
    //                     this.audioStatus = '../assets/audio/ok.mp3';
    //                     this.message = 'OK';
    //                 }
    //             } else {
    //                 this.audioStatus = '../assets/audio/dont_send.mp3';
    //                 this.message = 'Don\'t Send';
    //             }
    //         });
    //         setTimeout(() => {
    //             this.audioStatus = null;
    //             this.message = null;
    //         }, 1500);
    //     }
    // }
    //
    // searchConsolidationOrders(tracking) {
    //     this.searchConsolidationTrack = tracking;
    // }

    public closePopup() {
        // this.getOrders();
    }

    @HostListener('window:scroll')
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    topFunction() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

    public sendBtn() {

        this.selected.map((el) => {
            this.onlyTrackingCode.push(el.tracking_code);
        });

        console.log(this.onlyTrackingCode);
        // console.log(this.selected);
        // return
        if (this.selected.length) {
            const dialogRef = this.dialog.open(ReadTosendSuggetionModal, {
                width: '500px',
                panelClass: 'custom-dialog-container',
                data: this.onlyTrackingCode

            });
            dialogRef.afterClosed()
                .subscribe((data) => {
                    this.dd = true;
                    if (data.doneORno === 'Yes') {
                        this.getOrders();
                        this.dd = false;
                    } else {
                        this.orders.map((el) => {
                            el.selected = false;
                        });
                        this.dd = false;
                    }
                    this.selected = [];
                    this.onlyTrackingCode = [];
                });
        } else {
            alert('Pleas select any track and courier');
        }
    }

    public sendUniqBtn(track: string, order) {
        const dialogRef = this.dialog.open(ReadTosendSuggetionModal, {
            width: '500px',
            panelClass: 'custom-dialog-container',
            data: [{
                tracking_code: track,
                delivery_company_id: order.delivery_company_id
            }]
        });
        dialogRef.afterClosed()
            .subscribe((data) => {
                this.dd = true;
                if (data.doneORno === 'Yes') {
                    this.getOrders();
                    this.dd = false;

                } else {
                    this.dd = false;

                }
                this.selected = [];
                this.courier = [];
            });


    }

    public export() {
        this.russianParcelsService.export().subscribe();
    }

    public isAllSelected(e, index, order) {
        if (e.target.checked) {
            this.selectedArrLength++;
            if (!this.orders[index].delivery_company_id && !this.couriersType.length) {
                this.orders[index].error = true;

                this.courierError.map((el, ind) => {
                    if (ind === index) {
                        this.orders[index].error = false;
                    } else if (order.delivery_company_id) {
                        this.orders[index].error = false;
                    } else {
                        this.orders[index].error = true;
                    }
                });
            } else {
                this.selected = this.selected.filter(item => item.tracking_code !== order.order_group_tracking_code);
                this.couriersType = this.couriersType.filter(item => item.tracking_code !== order.order_group_tracking_code);
                if (this.couriersType.length) {
                    this.couriersType.map((el, idx) => {
                        if (idx === index) {
                            this.selected.push({
                                tracking_code: el.order_group_tracking_code,
                                delivery_company_id: this.orders[index].delivery_company_id
                            });
                        }
                    });
                } else {
                    this.selected.push({
                        tracking_code: order.order_group_tracking_code,
                        delivery_company_id: this.orders[index].delivery_company_id
                    });
                }
                console.log(this.selected);
            }


        } else {
            this.selectedArrLength--;
            this.orders[index].error = false;
            this.selected = this.selected.filter(item => item.tracking_code !== order.order_group_tracking_code);
        }
    }

    public selectAll(index, order) {
        if (this.allSelected) {
            if (this.couriersType.length) {
                this.couriersType.map((el) => {
                    el.selected = true;
                    if (el.delivery_company_id === Number(this.exportCouriers)) {

                        this.selected.push({
                            tracking_code: el.order_group_tracking_code,
                            delivery_company_id: el.delivery_company_id
                        });
                    }
                });
            } else {
                this.orders.map(item => {
                    item.selected = true;
                    if (item.delivery_company_id) {
                        // if (this.courierError.includes(item.order_group_tracking_code)) {
                        item.error = false;

                        this.selected.push({
                            tracking_code: item.order_group_tracking_code,
                            delivery_company_id: item.delivery_company_id
                        });


                    } else {
                        item.error = true;
                    }
                    // }
                    // else {
                    //     if (this.selected.some(data => data != item.order_group_tracking_code) || !this.selected.length) {
                    //         this.selected.push({
                    //             tracking_code: item.order_group_tracking_code,
                    //             delivery_company_id: item.delivery_company_id
                    //         });
                    //     }
                    // }
                });
            }

        } else {
            for (let i = 0; i < this.orders.length; i++) {
                this.orders[i].error = false;
                this.orders[i].selected = this.allSelected;
                this.selected = [];
            }
        }
    }

    public getCourier() {
        this.russianParcelsService.getCourier().subscribe((data: any) => {
            this.couriers = data.deliveryCompanies;
        });
    }

    public changeCourier(e, index, order) {

        this.showLoading = true;
        this.russianParcelsService.changeCourier(+e.value === 0 ? null : +e.value, order.order_group_tracking_code).subscribe((data: any) => {
            this.showLoading = false;
        });
        this.courier.push({
            value: +e.value,
            order_group_tracking_code: order.order_group_tracking_code
        });
        if (!this.courierError.includes(order.order_group_tracking_code)) {
            this.courierError.push(order.order_group_tracking_code);
        }

        this.orders.map((el, ind) => {
            if (index === ind) {
                order.error = false;
                el.delivery_company_id = this.courier[this.courier.length - 1];
            }

        });

        if (!!this.selected.length) {
            this.selected = this.selected.filter(item => item.tracking_code !== order.order_group_tracking_code);
            this.selected.push({
                tracking_code: order.order_group_tracking_code,
                delivery_company_id: +e.value
            });
        } else {
            this.selected.push({
                tracking_code: order.order_group_tracking_code,
                delivery_company_id: +e.value
            });
        }
        order.delivery_company_id = +e.value;
    }

    public getCourierValue(e) {
        this.exportCouriers = e.value;
        this.selected = [];
        this.couriersType = this.orders.filter((el) => el.delivery_company_id === Number(this.exportCouriers));
        this.checkOrdersActivate();
        this.orders.map((el) => {
            el.selected = false;
        });
        console.log(this.couriersType);
    }

    public exportCourier() {
        window.open(`https://www.onex.ru/api/delivery-manifest/${this.exportCouriers}`, '_blank');
    }

    private getOrders() {
        this.russianParcelsService.readyToSend().subscribe((data: any) => {
            this.orders = data.data;
            this.couriersType.map((it) => {
                this.couriersType = this.orders.filter((el) => el.tracking_code === it.tracking_code);
            });
            this.orders.map(item => {
                item.error = false;
            });
            this.checkOrdersActivate();
            this.showLoading = false;
        });

    }


}
