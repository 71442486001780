import {Component, HostListener, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {RussianParcelsService, UserService} from '../../_services';
import {ScriptLoaderService} from '../../_services/script-loader.service';
import {Router} from '@angular/router';
import {FilterShelfBoxesPipe} from '../../_pipes/filter-shelf-boxes.pipe';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material';
import {
    AppRussianWarehouseConsolidationShowDialogComponent
} from './russian-warehouse-consolidation-show/russian-warehouse-consolidation-show-dialog.component';


@Component({
    selector: 'app-russian-warehouse-consolidation',
    templateUrl: 'russian-warehouse-consolidation.component.html',
    styleUrls: ['russian-warehouse-consolidation.component.scss']
})

export class AppRussianWarehouseConsolidationComponent implements OnInit {
    @ViewChild('tracking_code') tracking_code;
    @ViewChild('orderCheckbox') orderCheckbox;
    @ViewChildren('trackCheckbox') public trackCheckbox: QueryList<any>;
    orders = [];
    foundOrder = [];
    foundOrderFail: string;
    model = [];
    orderfromOrdersgroup = [];
    ogtracking;
    checked = [];
    ponyTracking;
    showLoading = true;
    chack_messege;
    alert_messege;
    chack_ok = 0;
    alert_ok = 0;
    ordersid = [];
    modal = false;
    p;
    print_lable_og;
    print_list_og;
    public finalOrders = [];
    public top = 'bottom';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 10;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public printLabelId: string;
    public activeArr = [];
    public searchOrders;
    public searchConsolidationTrack;
    public activeFilter = 'all';
    public totalWeight = 0;
    public ordersForCalc = [];
    public totalWeightCalc;
    public windowScrolled: boolean;
    public audioStatus: string;
    public message: string;
    public searchTrackingCode;
    public checkedOrderArr = [];
    public scanedOrderArr = [];
    public isDoneBtn = false;
    public isSearch: boolean;
    public isYellowOrder = false;

    constructor(
        private _userService: UserService,
        private _script: ScriptLoaderService,
        private _router: Router,
        private filter: FilterShelfBoxesPipe,
        private russianParcelsService: RussianParcelsService,
        public dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.showLoading = true;
        this.getOrders();
    }

    private getOrders() {
        this.russianParcelsService.getRuOrdersGroup().subscribe((data: any) => {
            this.orders = data.data;
            console.log(this.orders);
            this.finalOrders = this.orders;
            this.totalWeight = 0;
            this.ordersForCalc = this.finalOrders;
            this.finalOrders.map(item => {
                console.log(item.order_group_tracking_code === '2205116255');
                this.totalWeight += Number(item.weight);
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            });
            this.showLoading = false;
        });

    }

    get_orders(order) {
        const dialogRef = this.dialog.open(AppRussianWarehouseConsolidationShowDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '90%',
            maxWidth: '100%',
            height: '800px',
            data: {
                data: order,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getOrders();
        });
    }

    setPreBox(event, order) {
        this.checkedOrderArr = [];

        // if (order.color === '')
        this.russianParcelsService.searchRuOrderGroup({
            tracking_code: order.tracking_code,
            is_activated_from_ru: event.target.checked ? 1 : 0
        }).subscribe(
            (data: any) => {
                order.is_activated_from_ru = data.data.is_activated_from_ru;
                this.orderfromOrdersgroup.map(({is_activated_from_ru}) => {
                    if (is_activated_from_ru) {
                        this.checkedOrderArr.push(is_activated_from_ru);
                    }
                });
                if (this.checkedOrderArr.length === this.orderfromOrdersgroup.length) {
                    this.isDoneBtn = true;
                } else {
                    this.isDoneBtn = false;
                }
            }, e => {
                console.log(e);
            }
        );
    }

    checkOrdersActivate() {
        if (this.activeFilter === 'readyToSend') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'green') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += +weight.weight;
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else if (this.activeFilter === 'found') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'yellow') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += +weight.weight;
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else if (this.activeFilter === 'russainWarehouseSpecial') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'blue') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += +weight.weight;
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else if (this.activeFilter === 'notFound') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'white') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += +weight.weight;
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                this.ordersForCalc.push(weight);
                this.totalWeight += +weight.weight;
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            }
        }
    }

    searchConsolidationOrders(tracking) {
        this.searchConsolidationTrack = tracking;
    }

    searchWithTracking(track) {
        this.foundOrder = [];
        this.foundOrderFail = null;
        this.russianParcelsService.getRuParcelWithTracking(track).subscribe((data: any) => {
            if (data.status === 200) {
                this.orders.map(item => {
                    if (item.order_group_tracking_code === data.data) {
                        this.foundOrder.push(item);
                        return;
                    }
                });
            } else {
                this.foundOrderFail = data.message;
            }
        });
    }

    search(tracking) {
        this.searchTrackingCode = tracking;
        this.isSearch = true;
        this.trackCheckbox.map(item => {
            if (item.nativeElement.id.toUpperCase() === tracking.toUpperCase()) {
                if (!item.nativeElement.checked) {
                    this.isSearch = false;
                    item.nativeElement.click();
                    this.message = 'OK';
                    this.audioStatus = '../assets/audio/ok.mp3';
                } else {
                    this.isSearch = false;
                    this.audioStatus = '../assets/audio/error-stab-notification.mp3';
                    this.message = 'Repeat';

                }
            } else {
                if (this.isSearch) {
                    this.audioStatus = '../assets/audio/error.mp3';
                    this.message = 'Error';
                }

            }
        });
        setTimeout(() => {

            this.tracking_code.nativeElement.value = '';

        }, 1000);
        setTimeout(() => {
            this.message = null;
            this.audioStatus = null;
        }, 1500);
    }

    public doneOrderGroup(orderGroupTracking): void {
        this.russianParcelsService.doneOrderGroupTracking(orderGroupTracking).subscribe((data: any) => {
            this.getOrders();
        });
    }

    public closePopup(e) {
        // e.stopPropagation();
        this.getOrders();
    }


}
