import {Component, OnInit} from '@angular/core';
import {UserService} from '../_services';
import {Event, NavigationStart, NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
    orders = [];
    pahests = [];
    uporder: any = {};
    superadmin;
    auth_user;
    getorder: any = {};
    showLoading = true;
    pages = [];
    pageprev;
    pagenext;
    p;

    constructor(private _userService: UserService, private _router: Router) {
    }

    ngOnInit() {
        this._userService.getAllOrders().subscribe(
            data => {
                this.orders = data.orders;
                this.pahests = data.pahests;
                for (let i = 1; i <= data.orders.lenght; i++) {
                    this.orders.push(`${i}`);
                }
                this.showLoading = false;
            },
            error => {
                console.log(error);
                this.showLoading = false;
            }
        );
        this._userService.getPahest().subscribe(
            data => {
                this.pahests = data.pahests;
            },
            error => console.log(error)
        );

    }

    getid(getorderid) {
        this.uporder.id = getorderid;
        this._userService.getorderById(this.uporder.id).subscribe(
            data => {
                this.uporder = data.order;
                this.auth_user = data.auth_user;
                if (this.auth_user == 1) {
                    this.superadmin = true;
                }
            },
            error => console.log(error)
        );
    }

    update() {
        this._userService.editorder(this.uporder).subscribe(
            data => {
                // this.uporder = data.order;
            },
            error => console.log(error)
        );
    }

    search() {
        this._userService.searchOrder(this.getorder).subscribe(
            data => {
                this.orders = data.orders;
            },
            error => console.log(error)
        );
    }
}
