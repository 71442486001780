import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services';
import { ScriptLoaderService } from '../_services/script-loader.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users  = [];
  pahests  = [];
  usersRole = [];
  userId;
  createModel :any = {};
  closeResult: string;
  userPremission:boolean;

  constructor(private _userService : UserService, private _script: ScriptLoaderService) { }

  ngOnInit() {
    this._script.loadScripts('app-users',
    ['/assets/js/vendor.bundle.base.js','/assets/js/vendor.bundle.addons.js','/assets/js/data-table.js' ]);

    this._userService.getAll().subscribe(
      data => {
        this.users = data.users;
        this.usersRole = data.users[0].pahest;
        this.pahests = data.pahests;
      },
      error => console.log(error)
  )
  this.userPremission = (localStorage.getItem('role') == 'super_admin')? true : false;

  }
  
  openedit(userid) {
    this.createModel.id = userid;

    this._userService.getById(this.createModel.id).subscribe(
      data => {
        this.createModel = data.user;
      },
      error => console.log(error)
  )

  }
  update(){
    this._userService.edituser(this.createModel).subscribe(
      data => {
        console.log(data);
          // this.showAlert('alertSignin');
          // this._alertService.success(
          //     'Thank you. To complete your registration please check your email.',
          //     true);
          // this.loading = false;
          // LoginCustom.displaySignInForm();
          // this.model = {};
      },
      error => console.log(error));
  }
  
  signup() {
    this._userService.create(this.createModel).subscribe(
        data => {
          console.log(data);
            // this.showAlert('alertSignin');
            // this._alertService.success(
            //     'Thank you. To complete your registration please check your email.',
            //     true);
            // this.loading = false;
            // LoginCustom.displaySignInForm();
            // this.model = {};
        },
        error => console.log(error));
}


}
