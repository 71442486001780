import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ShelfService, UserService} from '../../_services';
import {FilterShelfBoxesPipe} from '../../_pipes/filter-shelf-boxes.pipe';
import {SearchDataInterface} from './interface/search-data.interface';

@Component({
    selector: 'app-order-stats',
    templateUrl: './order-stats.component.html',
    styleUrls: ['./order-stats.component.scss']
})
export class OrderStatsComponent implements OnInit {
    @ViewChild('tracking_code') public tracking_code: ElementRef;
    public ordersTracks = [];
    showLoading = true;
    orders = [];
    orderTrackingCodes = [];
    finalOrders = [];
    p;
    searchTrackingCode;
    activeFilter = 'all';
    public ok = false;
    public top = 'top';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public totalWeight = 0;
    public ordersForCalc = [];
    public totalWeightCalc;
    public audioStatus: string;
    public message: string;

    constructor(private _userService: UserService,
                private shelfService: ShelfService,
                private filter: FilterShelfBoxesPipe) {
    }

    ngOnInit() {
        this.getData();
    }

    checkOrdersActivate() {
        if (this.activeFilter === 'special') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.activated === '2') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.waight);
                    this.totalWeightCalc = this.totalWeight.toPrecision(3);
                }
            }
        } else if (this.activeFilter === 'normal') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.activated === '1') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.waight);
                    this.totalWeightCalc = this.totalWeight.toPrecision(4);
                }
            }
        } else {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                this.ordersForCalc.push(weight);
                this.totalWeight += Number(weight.waight);
                this.totalWeightCalc = this.totalWeight.toPrecision(4);
            }
        }
    }

    getData() {
        this.orders = [];
        this.orderTrackingCodes = [];
        this._userService.getOrderGroup().subscribe(
            data => {
                if (data.status == 200) {
                    data.orders.orderGroups.map(key => {
                        if (key.ordergroup_status == 3) {
                            let user = key.profile.full_name + ' ' + key.profile.address2;
                            let recipient = key.recipmy.full_name + ' ' + key.recipmy.address2;
                            key.order.map(k => {
                                this.orders.push({
                                    group_tracking: key.tracking_code,
                                    tracking_code: k.tracking_code,
                                    first_name_ru: key.recipmy.first_name_ru,
                                    last_name_ru: key.recipmy.last_name_ru,
                                    address1: key.recipmy.address1,
                                    phone: key.recipmy.phone,
                                    weight: key.weight,
                                    user: user,
                                    recipient: recipient,
                                    in_my_way_date_ru: key.in_my_way_date_ru,
                                    in_usa_date: key.in_usa_date,
                                });
                                this.orderTrackingCodes.push(k.tracking_code);
                            });
                        }
                    });
                    if (this.orders.length > 0) {
                        this.get_orders(this.orders, this.orderTrackingCodes);
                    } else {
                        this.showLoading = false;
                    }
                }
            },
            error => console.log(error)
        );
    }

    get_orders(groups, tracking_code) {
        this.shelfService.gettingShelfBoxesForConsolidation({tracking_code: tracking_code}).subscribe(
            d => {
                if (d.status == 200) {
                    d.orders.map(k => {
                        if (groups.filter(item => item.tracking_code.toUpperCase() == k.tracking_code.toUpperCase()).length > 0) {
                            k.client_fullname = groups.filter(item => item.tracking_code.toUpperCase() == k.tracking_code.toUpperCase())[0].recipient;
                            k.group_tracking = groups.filter(item => item.tracking_code.toUpperCase() == k.tracking_code.toUpperCase())[0].group_tracking;
                            k.user = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].user;
                            k.in_my_way_date_ru = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].in_my_way_date_ru.split(' ')[0];
                            k.in_usa_date = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].in_usa_date.split(' ')[0];
                            k.address1 = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].address1;
                            k.phone = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].phone;
                            k.print_waight = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].weight;
                            k.last_name_ru = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].last_name_ru;
                            k.first_name_ru = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].first_name_ru;
                        }
                    });
                    this.finalOrders = d.orders;
                    this.finalOrders = this.finalOrders.sort((a, b) => (a.shelf_box > b.shelf_box) ? 1 : -1);
                    this.finalOrders = this.finalOrders.sort((a, b) => (a.group_tracking > b.group_tracking) ? 1 : -1);
                    this.ordersForCalc = this.finalOrders;
                    for (let weight of this.finalOrders) {
                        this.totalWeight += Number(weight.waight);
                        this.totalWeightCalc = this.totalWeight.toPrecision(4);
                    }
                    this.showLoading = false;
                }
            }, e => {
                console.log(e);
            }
        );
    }

    printTable(finalOrders) {
        // window.print();
        finalOrders = this.filter.transform(finalOrders, this.activeFilter);
        let myWindow = window.open('', 'PRINT');
        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>table, th, td { border: 1px solid black; border-collapse: collapse;}</style>');
        myWindow.document.write('</head><body >');
        myWindow.document.write('<table style="width: 100%; text-align: center">');
        myWindow.document.write('<thead>\n' +
            '                        <tr>\n' +
            '                            <th>X</th>\n' +
            '                            <th>Shelf Box</th>\n' +
            '                            <th>Recipient</th>\n' +
            '                            <th>User</th>\n' +
            '                            <th>Order Tracking</th>\n' +
            '                            <th>Weight</th>\n' +
            '                            <th>Order Group Tracking</th>\n' +
            '                        </tr>\n' +
            '                        </thead>');
        myWindow.document.write('<tbody>');
        for (let el of finalOrders) {
            let special = el.activated == '2' ? '*' : ' ';
            myWindow.document.write('<tr><td>' + special + '</td>' +
                '<td>' + el.shelf_box + '</td>' +
                '<td>' + el.client_fullname + '</td><td>' + el.user + '</td><td>' + el.tracking_code + '</td>' +
                '<td>' + el.waight + '</td><td>' + el.group_tracking + '</td></tr>');
        }
        myWindow.document.write('</tbody>');
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        // myWindow.close();

        return true;
    }

    search(tracking) {
        this.searchTrackingCode = tracking;
        if (this.searchTrackingCode) {
            this._userService.checkOrderPreBoxForRu(this.searchTrackingCode).subscribe((data: SearchDataInterface) => {
                if (data.status === 404) {
                    this.message = data.message;
                    setTimeout(() => {
                        this.message = '';
                    }, 1000);
                }
                else if (data.state === 'dont sand') {
                    console.log(this.audioStatus);
                    if (data.order.user_code.split('RU')[1].charAt(0) === '0') {
                        this.audioStatus = '../assets/audio/dont_send0.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '1') {
                        this.audioStatus = '../assets/audio/dont_send1.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '2') {
                        this.audioStatus = '../assets/audio/dont_send2.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '3') {
                        this.audioStatus = '../assets/audio/dont_send3.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '4') {
                        this.audioStatus = '../assets/audio/dont_send4.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '5') {
                        this.audioStatus = '../assets/audio/dont_send5.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '6') {
                        this.audioStatus = '../assets/audio/dont_send6.mp3';
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '7') {
                        this.audioStatus = '../assets/audio/dont_send7.mp3';
                        console.log(this.audioStatus);
                    } else if (data.order.user_code.split('RU')[1].charAt(0) === '8') {
                        this.audioStatus = '../assets/audio/dont_send8.mp3';
                    } else {
                        this.audioStatus = '../assets/audio/dont_send9.mp3';
                    }
                    // this.audioStatus = '../assets/audio/dont_send.mp3';
                    this.message = `Don't Send ${data.order.client_fullname ? data.order.client_fullname : data.order.orderer_info} ${data.order.tracking_code}`;
                    setTimeout(() => {
                        this.audioStatus = '';
                    }, 2500);
                }
                else if (data.state === 'send') {
                    this.audioStatus = '../assets/audio/send_parcel.mp3';
                    this.message = 'Send';
                } else if (data.state === 'old item') {
                    this.audioStatus = '../assets/audio/oldItem.mp3';
                    this.message = 'Old Item';
                } else {
                    if (data.order.activated !== '2') {
                        this._userService.setPreBox({id: data.order.id, checked: true}).subscribe(
                            d => {
                                this._userService.$scanData.next({id: data.order.id, checked: true});
                                if (d.is_send) {
                                    if (+d.order.activated === 2) {
                                        this.audioStatus = '../assets/audio/send_parcel.mp3';
                                        this.message = 'Send Parcel';
                                        data.order.activated = this._userService.$scanData.value.checked ? '2' : '1';

                                    }
                                }
                            }, e => {
                                console.log(e);
                            }
                        );
                    } else {
                        this.audioStatus = '../assets/audio/ok.mp3';
                        this.message = 'OK';
                    }
                    setTimeout(() => {
                        this.audioStatus = null;
                        this.message = null;
                    }, 1500);
                }
            });

            setTimeout(() => {
                this.tracking_code.nativeElement.value = '';

            }, 1000);
        }

    }


    setPreBox(order, event) {
        this._userService.setPreBox({id: order.id, checked: event.checked}).subscribe(
            d => {
                if (+d.order.activated === 2) {
                    if (d.is_send) {
                        this.audioStatus = '../assets/audio/send_parcel.mp3';
                        this.message = 'Send Parcel';
                    } else {
                        this.audioStatus = '../assets/audio/dont_send.mp3';
                        this.message = 'Don\'t Send';
                    }
                    setTimeout(() => {
                        this.audioStatus = null;
                        this.message = null;
                    }, 1000);
                } else {
                    this.audioStatus = null;
                    this.message = null;
                }
                order.activated = d.activated;
            }, e => {
                console.log(e);
            }
        );
    }


}
