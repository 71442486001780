import { Component, OnInit } from '@angular/core';
import {DeclarationService} from './declaration.service';
import {UserService} from '../../_services';

@Component({
  selector: 'app-germany-declarations',
  templateUrl: './germany-declarations.component.html',
  styleUrls: ['./germany-declarations.component.scss']
})
export class GermanyDeclarationsComponent implements OnInit {

  orders = [];
  tracking: string;
  activeFilter = 'all';
  constructor(private declarationService: DeclarationService, private userService: UserService) { }

  ngOnInit() {
    this.getOrders();
  }
  getOrders() {
    this.declarationService.getDeclarationsByTrackingGermany().subscribe(
      d => {
        if (d.status === 200) {
          this.orders = d.orders;
        }
        console.log(d);
      },
      e => {
        console.log(e);
      }
    );
  }
  searchTracking() {
    if (this.tracking != undefined && this.tracking != '') {
      this.declarationService.getDeclarationByTrackingGermany({tracking_code: this.tracking}).subscribe(
        d => {
          if (d.status == 200) {
            this.orders = [d.order];
          }
          console.log(d);
        }, e => {
          console.log(e);
        }
      );
    }  else {
      this.getOrders();
    }
  }
  setRejected(order, event) {
    console.log(order, event, event.checked);
    if (event.checked) {
      this.userService.setOrderRejected({id: order.id, type: 'invoice'}).subscribe(
        d => {
          console.log(d);
          order.invoice = d.order.invoice;
          order.invoice_confirm = d.order.invoice_confirm;
        }, e => {
          console.log(e);
        }
      );
    }
  }
}
