import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RussianParcelsService} from '../../../_services';
import {Log} from '@angular/core/testing/src/logger';

@Component({
    selector: 'app-russian-warehouse-consolidation-show-dialog',
    templateUrl: 'russian-warehouse-consolidation-show-dialog.component.html',
    styleUrls: ['russian-warehouse-consolidation-show-dialog.component.scss']
})

export class AppRussianWarehouseConsolidationShowDialogComponent implements OnInit {

    @ViewChild('tracking_code') tracking_code;
    @ViewChild('orderCheckbox') orderCheckbox;
    @ViewChildren('trackCheckbox') public trackCheckbox: QueryList<any>;
    public orderfromOrdersgroup;
    public order;
    public checkedOrderArr = [];
    public isDoneBtn = false;
    public isYellow: boolean;
    public isGreen: boolean;
    public isSearch: boolean;
    public ogtracking;
    public searchTrackingCode;
    public activeArr = [];
    public scanedOrderArr = [];
    showLoading = true;
    public message;
    public audioStatus;
    p_width = '';
    p_length = '';
    p_height = '';
    public errorMessage = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_DIALOG_DATA) public activeFilter: any,
        private russianParcelsService: RussianParcelsService,
        public dialogRef: MatDialogRef<AppRussianWarehouseConsolidationShowDialogComponent>) {
    }

    ngOnInit() {
        this.order = this.data.data;
        this.getData(this.order);
        if (this.order.order_group_dimensions) {
            this.order.v_waight = this.order.order_group_dimensions.v_weight;
        } else {
            return;
        }
    }

    public closePopup() {
        this.dialogRef.close();
    }

    search(tracking) {
        this.searchTrackingCode = tracking;
        this.isSearch = true;
        this.trackCheckbox.map(item => {
            if (item.nativeElement.id.toUpperCase() === tracking.toUpperCase()) {
                if (!item.nativeElement.checked) {
                    this.isSearch = false;
                    item.nativeElement.click();
                    this.message = 'OK';
                    this.audioStatus = '../assets/audio/ok.mp3';
                } else {
                    this.isSearch = false;
                    this.audioStatus = '../assets/audio/error-stab-notification.mp3';
                    this.message = 'Repeat';

                }
            } else {
                if (this.isSearch) {
                    this.audioStatus = '../assets/audio/error.mp3';
                    this.message = 'Error';
                }

            }
        });
        setTimeout(() => {

            this.tracking_code.nativeElement.value = '';

        }, 1000);
        setTimeout(() => {
            this.message = null;
            this.audioStatus = null;
        }, 1500);
    }

    public getData(order) {
        this.activeArr = [];
        this.orderfromOrdersgroup = [];
        this.checkedOrderArr = [];
        // this.ordersid.push(order);
        this.showLoading = true;
        // this.checked = ordergroup;
        // this.ponyTracking = ponytracking;
        // this.print_lable_og = order.tracking_code;
        // this.print_list_og = order;
        if (order.color === 'yellow') {
            this.isYellow = true;
        } else {
            this.isYellow = false;
        }
        this.russianParcelsService.getRuOrder(order.order_group_tracking_code).subscribe(
            (data: any) => {
                this.orderfromOrdersgroup = data.data;
                this.ogtracking = order.order_group_tracking_code;
                // this.orderfromOrdersgroup = data.orders.orders;
                this.orderfromOrdersgroup.map(({is_activated_from_ru, is_scan_ru_warehouse}) => {
                    if (is_activated_from_ru) {
                        this.checkedOrderArr.push(is_activated_from_ru);
                    }
                    if (is_scan_ru_warehouse) {
                        this.scanedOrderArr.push(is_scan_ru_warehouse);
                    }
                });
                if (this.checkedOrderArr.length === this.orderfromOrdersgroup.length) {
                    this.isDoneBtn = true;

                } else {
                    this.isDoneBtn = false;
                }
                this.showLoading = false;


            },
            error => console.log(error)
        );
    }

    setPreBox(event, order) {
        this.checkedOrderArr = [];

        this.russianParcelsService.searchRuOrderGroup({
            tracking_code: order.tracking_code,
            is_activated_from_ru: event.target.checked ? 1 : 0
        }).subscribe(
            (data: any) => {
                order.is_activated_from_ru = data.data.is_activated_from_ru;
                if (order.is_activated_from_ru) {
                    this.isGreen = true;
                } else {
                    this.isGreen = false;
                }
                this.orderfromOrdersgroup.map(({is_activated_from_ru}) => {
                    if (is_activated_from_ru) {
                        this.checkedOrderArr.push(is_activated_from_ru);
                    }
                });
                if (this.checkedOrderArr.length === this.orderfromOrdersgroup.length) {
                    this.isDoneBtn = true;
                } else {
                    this.isDoneBtn = false;
                }
            }, e => {
                console.log(e);
            }
        );
    }

    public doneOrderGroup(orderGroupTracking): void {
        this.russianParcelsService.doneOrderGroupTracking(orderGroupTracking).subscribe((data: any) => {
            this.dialogRef.close();
        });
    }


    calc(name, value) {
        if (this.order.order_group_dimensions) {

            if (name === 'width') {
                this.order.order_group_dimensions.width = value;
                this.p_length = this.order.order_group_dimensions.length;
                this.p_height = this.order.order_group_dimensions.height;
            } else if (name === 'length') {
                this.order.order_group_dimensions.length = value;
                this.p_width = this.order.order_group_dimensions.width;
                this.p_height = this.order.order_group_dimensions.height;
            } else {
                this.order.order_group_dimensions.height = value;
                this.p_width = this.order.order_group_dimensions.width;
                this.p_length = this.order.order_group_dimensions.length;
            }
            // switch (name) {
            //     case 'width':
            //         this.order.order_group_dimensions.width = value;
            //         break;
            //     case 'length':
            //         this.order.order_group_dimensions.length = value;
            //         break;
            //     case 'height':
            //         this.order.order_group_dimensions.height = value;
            //         break;
            // }
        }

        switch (name) {
            case 'width':
                this.p_width = value;
                break;
            case 'length':
                this.p_length = value;
                break;
            case 'height':
                this.p_height = value;
                break;
        }

        console.log('w: ', this.p_width, 'L: ', this.p_length, 'H: ', this.p_height);
        this.checkCalc(this.p_width, this.p_length, this.p_height);
    }

    checkCalc(w, l, h) {

        if (
            w != undefined &&
            w != '' &&
            l != undefined &&
            l != '' &&
            h != undefined &&
            h != ''
        ) {
            this.errorMessage = false;
            let vWeight = 5000;
            let len = (w * l * h) / vWeight;
            // console.log(len);
            if (len.toString().includes('.')) {
                let str = len.toString();
                let splited = str.split('.');
                if (splited[1].length > 4) {
                    splited[1] = splited[1].substring(0, 2);
                    this.order.v_waight = +splited.join('.');
                } else {
                    this.order.v_waight = +str;
                }
            } else {
                this.order.v_waight = len;
            }
        } else {
            this.errorMessage = true;
            this.order.v_waight = '';
        }
    }

    updateVWeight() {
        if (this.p_height === '' && this.p_length === '' && this.p_width === '') {
            this.errorMessage = true;
            return;
        } else {
            this.errorMessage = false;
        }
        const v_weight = this.order.v_waight.toString();

        if (this.p_height !== '' && this.p_length !== '' && this.p_width !== '' && this.order.v_waight) {
            const newData = {
                height: this.p_height,
                length: this.p_length,
                width: this.p_width,
                order_group_tracking_code: this.order.order_group_tracking_code,
                v_weight: v_weight

            };
            this.russianParcelsService.updateTrackingGroupVWeight(newData).subscribe((data: any) => {
                console.log(data);
                this.closePopup();
            });
        } else {
            this.errorMessage = true;
        }
    }
}
