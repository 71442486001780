import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'app-services-badge',
    templateUrl: 'services-badge.component.html',
    styleUrls: ['services-badge.component.scss']
})

export class ServicesBadgeComponent implements OnInit, OnDestroy {
    @Input() service_id: number | undefined;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }
}
