import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const host = environment.API_ENDPOINT_ROOT;

@Injectable()

export class Iservice {
    public array: string [];
    public srcThisImg: any;
    public userData;

    constructor(private _httpClient: HttpClient) {
    }
    public getServiceOrder(tracking_code) {
        return this._httpClient.post(host + '/checkService', tracking_code);
    }

    public addPhoto(obj) {
        return this._httpClient.post(host + '/doneService', obj);
    }

    public getReturnService(track) {
        return this._httpClient.get(host + `/doneReturnsAndSends/${track}`);
    }

    public generateTrackingForServiceById(id) {
        return this._httpClient.get(host + `/order-split/${id}`);
    }

    public getImages(order_id) {
        return this._httpClient.get(`${host}/getImages/${order_id}`);
    }
}
