import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TrackingCode} from '../../../tracking-report/tracking-report.component';
import {TrackingReportService} from '../../../_services/tracking-report.service';

@Component({
    selector: 'app-tracking-print',
    templateUrl: 'tracking-print.component.html',
    styleUrls: ['tracking-print.component.css']
})


export class TrackingPrintComponent implements OnInit, OnDestroy {
    @ViewChild('tackInput') public tackInput: any;
    @ViewChild('printButton') public printButton: any;
    public trackingCode: string | undefined;
    public userFullName: string;
    public top = 'top';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public sendTackInput: TrackingCode;
    public sendTackMessage = null;
    public disabledBtn = true;

    constructor(
        private trackingReport: TrackingReportService,
    ) {
    }

    ngOnInit() {
    }

    onEnter() {
        this.sendTackMessage = null;
        if (this.tackInput.nativeElement.value !== '') {
            this.sendTackInput = {
                tracking_code: ''
            };

            this.sendTackInput.tracking_code = this.tackInput.nativeElement.value;
            this.trackingCode = this.tackInput.nativeElement.value;
            this.trackingReport.chekInputTrack(this.sendTackInput).subscribe(data => {
                console.log(data);
                if (data.status === 200) {
                    if (data.order) {
                        if (+data.order.dispatch === 2) {
                            this.userFullName = `${data.order.client_fullname.split(' ')[0]} ${data.order.client_fullname.split(' ')[1]} ${data.order.client_fullname.split(' ')[2].replace('ARM', 'MT')} `;
                        } else {
                            this.userFullName = data.order.client_fullname ? data.order.client_fullname : data.order.orderer_info;
                        }
                        console.log(this.userFullName);
                        this.sendTackMessage = data.message;
                        this.disabledBtn = false;
                        setTimeout(() => {
                            this.printButton.nativeElement.click();
                        }, 0);
                    }
                    if (data.message) {
                        this.disabledBtn = true;
                        this.sendTackMessage = data.message;
                    }
                }

            });
        }
        setTimeout(() => {
            this.sendTackMessage = null;
        }, 2000);
    }

    public focusOut(e) {
        console.log(e.target.value);
        if (e.target.value !== '') {
            this.disabledBtn = false;
            setTimeout(() => {
                this.printButton.nativeElement.focus();
            }, 0);
        } else {
            this.disabledBtn = true;
        }
    }

    ngOnDestroy() {
    }
}
