import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderServices, RessponsPostDone, ServiceStatus} from '../../_models/serviceStatus.model';
import {Subject} from 'rxjs-compat';
import {SendReturnService} from '../../_services/send-return.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Iservice} from '../../_services/iservice.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {ImagesDate} from '../../_models';
import {SliderActionModal} from '../../service/slider-action/slider-action.component';
import {DoneModalComponent} from '../../order-actions/send-return/done-modal/done-modal.component';
import {RussianParcelsService} from '../../_services';

@Component({
    selector: 'app-russian-parcels',
    templateUrl: 'russian-parcels.component.html',
    styleUrls: ['russian-parcels.component.scss']
})

export class AppRussianParcelsComponent implements OnInit, OnDestroy {

    //---->Public varialble start <-----//
    //  onex TableTitle "ID User","Comments",
    public onexTableTitle = [
        '#',
        // 'ID Order',
        'Full Name',
        'Recipient',
        'Dispatch',
        'Tracking Code',
        'Weight',
        'Service',
        'Images',
        'Label',
        'Action',
    ];

    public ruParcels: any;
    public message: any;
    //---->Public varialble end <-----//

    //---->Private varialble start <-----//
    private _unsubscribe$ = new Subject<void>();

    //---->Private varialble end <-----//
    constructor(
        public _sendReturnService: SendReturnService,
        private modalService: NgbModal,
        public iService: Iservice,
        private russianParcelsService: RussianParcelsService
    ) {
    }

    ngOnInit() {
        this.allStatusOrder(null);
        this.message = this._sendReturnService.message;
        this.getRussianParcels();
    }

    // -----> Get All Orders <---- //

    public getRussianParcels() {
        this.russianParcelsService.getRuParcels().subscribe(data => {
            this.ruParcels = data.data;
        });
    }

    // -----> Filter Orders in Status ID <----- ///

    public allStatusOrder(ss, bb?) {
        this.onexTableTitle = [
            'ID',
            'Name',
            'Air Way Bill',
            'In My Way Date',
            'Total Weight',
            'V Weight',
            'Action',
        ];
    }

    //------> Open Slider Modal <------

    public openModal(order_id) {
        this.iService.getImages(order_id).subscribe((data: ImagesDate) => {
            let tmp = JSON.parse(data.data.images);
            console.log(tmp);
            this.iService.array = tmp;
            tmp = [];
            console.log(tmp);
        });
        const modalRef = this.modalService.open(SliderActionModal, {
            windowClass: 'makePhotoModal',
        });
    }

    ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }
}
