import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../_services";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";

@Component({
  selector: "app-ngbd-modal-content",
  template: `
    <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p [innerHTML]="modalShow"></p>
      <button class="btn btn-success" (click)="copy(defCopy)">Copy</button>
      &nbsp; <span *ngIf="copied">copied</span>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
      >
        Close
      </button>
    </div>
  `,
})
export class NgbdModalContentComponent {
  @Input() modalShow;
  @Input() defCopy;
  copied = false;

  constructor(public activeModal: NgbActiveModal) {}

  copy(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "1";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.copied = true;
  }
}

@Component({
  selector: "app-parcel-details",
  templateUrl: "./parcel-details.component.html",
  styleUrls: ["./parcel-details.component.scss"],
})
export class ParcelDetailsComponent implements OnInit {
  @ViewChild("airBillNum") billNum;
  parcel;
  MAWB_URL = null;
  manifestUAHref: string | undefined;
  fileMAWB;
  @ViewChild("content") modal;
  boxes = [];
  orders = [];
  resultWeight;
  totalOrdersQuantity;
  totalOrdersWeight;
  totalOrdersCount;
  message;
  ok;
  sent = false;
  sentError = false;
  sentMessage = false;
  showLoading = false;
  isGermany: boolean;
  public measurementUnit = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.isGermany = localStorage.getItem("detectUser") === "6";
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
    ) {
      return; // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (this.billNum && e.target === this.billNum.nativeElement) {
      return;
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 191)
    ) {
      e.preventDefault();
    }
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get("id");
    console.log(localStorage.getItem("name"));
    if (localStorage.getItem("detectUser") === "2") {
      this.measurementUnit = "inch";
    } else {
      this.measurementUnit = "cm";
    }

    this.userService.getParcelDetails(+id).subscribe(
      (d) => {
        if (d.status == 200) {
          this.parcel = d.parcel;
          this.MAWB_URL = d.MAWB_URL;
          this.manifestUAHref = `http://warehouseback.onex.am/getOrdersForManifestExcel/${d.parcel.id}`;
          if (d.parcel.in_mmy_way_date != null) {
            let date = new Date(d.parcel.in_mmy_way_date);
            let ngDate: NgbDateStruct = {
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              day: date.getDate(),
            };
            this.parcel.in_mmy_way_date = ngDate;
          }
          if (d.parcel.freght_foward != null) {
            let date = new Date(d.parcel.freght_foward);
            let ngDate: NgbDateStruct = {
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              day: date.getDate(),
            };
            this.parcel.freght_foward = ngDate;
          }
          this.boxes = d.parcel.box;
          this.totalOrdersQuantity = 0;
          this.totalOrdersWeight = 0;
          this.boxes.map((key) => {
            if (key) {
              this.totalOrdersQuantity += key.order
                ? +key.order.totalOrdersCount
                : 0;
              this.totalOrdersCount += +key.totalOrdersCount;
            } else {
              this.totalOrdersQuantity = 0;
              this.totalOrdersCount = 0;
            }
            this.totalOrdersWeight += key.order
              ? key.order.totalOrdersWeight
              : 0;
          });
          this.resultWeight = this.totalOrdersWeight.toFixed(2) + " " + "kg";
        } else {
          alert(d.message);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  gotoParcels() {
    this.router.navigate(["/parcels"]);
  }

  boxDetails(orders) {
    this.orders = orders;
  }

  calcGrossWeight(value, index) {
    this.boxes[index].waight = value;
    this.parcel.totalGrossWeight = 0;
    this.boxes.map((key) => {
      this.parcel.totalGrossWeight += +key.waight;
    });
  }

  calcVolumeWeight(w_val, l_val, h_val, index) {
    // console.log( w_val,  l_val,  h_val, index);
    this.checkCalc(+w_val, +l_val, +h_val, index);
  }

  checkCalc(w, l, h, index) {
    if (
      w != undefined &&
      w != "" &&
      w != 0 &&
      l != undefined &&
      l != "" &&
      l != 0 &&
      h != undefined &&
      h != "" &&
      h != 0
    ) {
      let len;
      if (localStorage.getItem("detectUser") === "2") {
        len = (w * 2.54 * (l * 2.54) * (h * 2.54)) / 6000;
      } else {
        len = (w * l * h) / 6000;
      }
      len = Math.round(len);
      this.boxes[index].v_waight = len;
      this.parcel.totalVolumeWeight = 0;
      this.boxes.map((key) => {
        this.parcel.totalVolumeWeight += key.v_waight;
      });
    } else {
      this.parcel.totalVolumeWeight = 0;
      this.boxes[index].v_waight = 0;
      this.boxes.map((key) => {
        this.parcel.totalVolumeWeight += key.v_waight;
      });
    }
  }

  saveChanges(parcel) {
    this.showLoading = true;
    if (parcel.in_mmy_way_date != null) {
      parcel.in_mmy_way_date.day =
        parcel.in_mmy_way_date.day.toString().length < 2
          ? "0" + parcel.in_mmy_way_date.day
          : parcel.in_mmy_way_date.day;
      parcel.in_mmy_way_date.month =
        parcel.in_mmy_way_date.month.toString().length < 2
          ? "0" + parcel.in_mmy_way_date.month
          : parcel.in_mmy_way_date.month;
      parcel.in_mmy_way_date =
        parcel.in_mmy_way_date.year +
        "-" +
        parcel.in_mmy_way_date.month +
        "-" +
        parcel.in_mmy_way_date.day;
    }
    this.userService.editParcelWithBoxes(parcel).subscribe(
      (d) => {
        if (!this.isGermany) {
          this.getTextToCopy(parcel.box, parcel.destination);
        }
        this.showLoading = false;
      },
      (e) => {
        console.log(e);
        this.showLoading = false;
      }
    );
  }

  sendParcel(id, date, dest) {
    this.showLoading = true;
    if (date != null) {
      date.day = date.day.toString().length < 2 ? "0" + date.day : date.day;
      date.month =
        date.month.toString().length < 2 ? "0" + date.month : date.month;
      date = date.year + "-" + date.month + "-" + date.day;
    }

    let checkValues = this.boxes.some((key) => {
      return key.v_waight == 0 || key.waight == 0 || key.waight == "";
    });
    if (!checkValues) {
      this.userService
        .parcelSend({ id: id, in_my_way: date, destination: dest })
        .subscribe(
          (d) => {
            if (d.status == 200) {
              this.parcel.status = 2;
              this.sent = true;
              this.sentMessage = d.message;
              setTimeout(() => {
                this.sent = false;
                this.sentMessage = null;
              }, 5000);
            }
            if (d.status == 400) {
              this.sentError = true;
              this.sentMessage = d.message;
              setTimeout(() => {
                this.sentError = false;
                this.sentMessage = null;
              }, 5000);
            }
            this.showLoading = false;
          },
          (e) => {
            console.log(e);
          }
        );
    } else {
      alert("volume weight and gross weight fields are required!");
      this.showLoading = false;
    }
  }

  unBox(tracking_code, order) {
    this.userService
      .unbox({ tracking: tracking_code, id: order.box_id })
      .subscribe(
        (data) => {
          if (data.status == 200) {
            this.message = data.message;
            this.ok = 1;
            setTimeout(() => {
              this.message = null;
              this.ok = null;
            }, 2000);
            this.totalOrdersQuantity--;
            this.totalOrdersWeight -= order.waight;
            this.totalOrdersWeight = Math.round(this.totalOrdersWeight);
            this.orders = this.orders.filter(
              (item) => item.tracking_code !== tracking_code
            );
            this.boxes.map((key, i) => {
              if (key.id == order.box_id) {
                let total = key.totalOrdersWeight - order.waight;
                key.totalOrdersWeight = Math.round(total);
                this.boxes[i].order = this.orders;
              }
            });
          } else if (data.status == 400) {
            this.message = data.message;
            this.ok = 2;
          }
        },
        (error) => console.log(error)
      );
  }

  removeParcel(id) {
    this.userService.rmParcel({ parcel_id: id }).subscribe(
      (d) => {
        console.log(d);
        if (d.status == 200) {
          alert(d.message);
          this.gotoParcels();
        }
        if (d.status == 400) {
          alert(d.message);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  getManifest(id) {
    this.userService.getManifestGermany({ parcel_id: id }).subscribe(
      (d) => {
        console.log(d);
        let link = document.createElement("a");
        link.href = d;
        link.target = "_blank";
        link.download = "Je kar.pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
      },
      (e) => {
        console.log(e);
      }
    );
  }

  sentUkraineManifest(id) {
    this.userService.sentUkraineManifest(id).subscribe(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          this.parcel.status = 2;
          this.sent = true;
          this.sentMessage = data.message;
          setTimeout(() => {
            this.sent = false;
            this.sentMessage = null;
          }, 5000);
        }
        if (data.status == 400) {
          this.sentError = true;
          this.sentMessage = data.message;
          setTimeout(() => {
            this.sentError = false;
            this.sentMessage = null;
          }, 5000);
        }
        this.showLoading = false;
      },
      (e) => {
        console.log(e);
      }
    );
  }

  saveParcelDetails(parcel) {
    let in_mmy_way_date, freght_foward;
    if (parcel.in_mmy_way_date != null) {
      const day =
        parcel.in_mmy_way_date.day.toString().length < 2
          ? "0" + parcel.in_mmy_way_date.day
          : parcel.in_mmy_way_date.day;

      const month =
        parcel.in_mmy_way_date.month.toString().length < 2
          ? "0" + parcel.in_mmy_way_date.month
          : parcel.in_mmy_way_date.month;
      in_mmy_way_date = parcel.in_mmy_way_date.year + "-" + month + "-" + day;
    }
    if (parcel.freght_foward != null) {
      const day =
        parcel.freght_foward.day.toString().length < 2
          ? "0" + parcel.freght_foward.day
          : parcel.freght_foward.day;
      const month =
        parcel.freght_foward.month.toString().length < 2
          ? "0" + parcel.freght_foward.month
          : parcel.freght_foward.month;
      freght_foward = parcel.freght_foward.year + "-" + month + "-" + day;
    }
    const dataToSend = {
      in_mmy_way_date: in_mmy_way_date,
      freght_foward: freght_foward,
      air_way_bill: parcel.air_way_bill,
    };
    this.userService.editParcelOnSend(dataToSend, parcel.id).subscribe(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.log(e);
      }
    );
  }

  getTextToCopy(box, destination) {
    let g_weight = 0,
      t_w_weight = 0,
      allIn,
      dest = destination == 1 ? "Armenia" : destination == 2 ? "Russia" : "",
      body =
        "Dear Svetlana, \nhere is our shipment information to " +
        dest +
        ".\n\n";
    allIn =
      "Dear Svetlana, <br>here is our shipment information to " +
      dest +
      ".<br><br>";

    const sumMap = new Map();

    // Iterate through the data array
    box.forEach((item) => {
      const { width, height, length, waight } = item;
      const key = `${width}-${height}-${length}`;

      // If the key exists in the map, add the weight to the existing sum; otherwise, initialize the sum
      if (sumMap.has(key)) {
        sumMap.set(key, sumMap.get(key) + parseFloat(waight));
      } else {
        sumMap.set(key, parseFloat(waight));
      }
    });

    // Update the original data with the aggregated weight for matching width, height, and length
    const resultData = box.map((item) => {
      const { width, height, length, waight } = item;
      const key = `${width}-${height}-${length}`;
      const matchingEntry = sumMap.get(key);

      if (matchingEntry !== undefined) {
        return {
          ...item,
          waight: matchingEntry.toString(), // Update the waight property with the aggregated weight
          // Include additional properties from the original data if needed
        };
      } else {
        // Include original data for entries that do not have a matching combination
        return {
          ...item,
          // Include additional properties from the original data if needed
        };
      }
    });

    function countItemsWithSameDimensions(data) {
      const dimensionCounts = {};

      data.forEach((item) => {
        const key = `${item.width}_${item.height}_${item.length}`;
        dimensionCounts[key] = (dimensionCounts[key] || 0) + 1;
      });

      // Filter items with count greater than 1
      const resultArray = Object.entries(dimensionCounts)
        .filter(([dimensions, count]) => count > 1)
        .map(([dimensions, count]) => {
          const [width, height, length] = dimensions.split("_");
          return { width, height, length, count, ...data };
        });

      return resultArray;
    }

    // Call the function and log the result
    const resultCount = countItemsWithSameDimensions(resultData);

    function filterUniqueDimensions(data) {
      const uniqueDimensions = new Set();
      const result = [];

      for (const item of data) {
        const { width, height, length } = item;
        const dimensionsKey = `${width}-${height}-${length}`;

        if (!uniqueDimensions.has(dimensionsKey)) {
          uniqueDimensions.add(dimensionsKey);
          result.push(item);
        }
      }

      return result;
    }

    const filteredData = filterUniqueDimensions(resultData);

    filteredData.map((key, i) => {
      g_weight += +key.waight;
      t_w_weight = box.reduce((accumulator, object) => {
        return accumulator + object.v_waight;
      }, 0);
      body +=
        "BOX " +
        (i + 1) +
        " : " +
        key.waight +
        " KG - W " +
        key.width +
        " X L " +
        key.length +
        " X H " +
        key.height +
        "\n";
      allIn += (
        "BOX " +
        (i + 1) +
        " : " +
        key.waight +
        " KG - W " +
        key.width +
        " X L " +
        key.length +
        " X H " +
        key.height +
        (
          resultCount.map((el, index) =>
            el &&
            el.count > 1 &&
            key.width === el.width &&
            key.length === el.length &&
            key.height === el.height
              ? " " + "Total count" + " " + "-" + " " + el.count
              : ""
          ) + "<br>"
        ).replace(",", "")
      ).replace(",", "");
    });
    allIn +=
      "<br>Gross Weight : " +
      g_weight.toFixed(2) +
      " KG<br>Volume Weight : " +
      t_w_weight.toFixed(2) +
      " KG";
    body +=
      "\nGross Weight : " +
      g_weight.toFixed(2) +
      " KG\nVolume Weight : " +
      t_w_weight.toFixed(2) +
      " KG\n";
    this.open(allIn, body);
  }

  open(modal, def) {
    const modalRef = this.modalService.open(NgbdModalContentComponent);
    modalRef.componentInstance.modalShow = modal;
    modalRef.componentInstance.defCopy = def;
  }

  handleFileInput(event) {
    this.fileMAWB = (event.target as HTMLInputElement).files[0];
  }

  /* modal actions*/
  openModalOnSamePage(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "sm" })
      .result.then(
        (result) => {
          // console.log(result);
        },
        (reason) => {
          // console.log(reason);
        }
      );
  }

  public uploadMAWB(): void {
    if (this.fileMAWB != null) {
      this.userService
        .uploadFileMAWB({ file: this.fileMAWB, id: this.parcel.id })
        .subscribe(
          (d) => {
            console.log(d);
            this.MAWB_URL = d.url;
            if (d.status == 200) {
              this.modalService.dismissAll();
            }
          },
          (e) => {
            console.log(e);
          }
        );
    }
  }
}
