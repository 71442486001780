import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const host = environment.API_ENDPOINT_ROOT;

@Injectable({
  providedIn: 'root'
})
export class UkraineService {

  constructor(private httpClient: HttpClient) { }

  getUkraineOrders() {
    return this.httpClient.get<any>(`${host}/ukraineOrders`, httpOptions).pipe();
  }
  setPreBox(data, id) {
    return this.httpClient.post<any>(host + `/ukraineOrder/${id}`, data).pipe();
  }
  labelGenerate(id: number) {
    return this.httpClient.get<any>(`${host}/ukraineLabel/${id}`).pipe();
  }
}
