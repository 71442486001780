import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RussianParcelsService} from '../../../_services';

@Component({
    selector: 'app-read-to-send-suggestion',
    templateUrl: 'readTosendSuggetion.modal.html',
    styleUrls: ['readTosendSuggetion.modal.scss']
})

export class ReadTosendSuggetionModal implements OnInit, OnDestroy {
    public showLoading = false;
    public isSend: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ReadTosendSuggetionModal>,
        private russianParcelsService: RussianParcelsService
    ) {
    }

    ngOnInit() {
        console.log(this.data);
    }

    public send(): void {

        if (this.data.length
        ) {
            this.showLoading = true;
            this.russianParcelsService.readyForHome(this.data).subscribe((data: any) => {
                this.dialogRef.close({doneORno: 'Yes'});
                this.showLoading = false;

            });
        }

        // this.data.map((el) => {
        //     if (el.delivery_company_id) {
        //         this.isSend = true;
        //     } else {
        //         this.isSend = false;
        //         return;
        //     }
        // });
        // if (this.isSend) {
        //     this.russianParcelsService.readyForHome(this.data).subscribe((data: any) => {
        //         this.dialogRef.close({doneORno: 'Yes'});
        //     });
        // } else {
        //     this.close();
        // }
        // if (this.data.length > 1) {
        //     this.showLoading = true;
        //     this.russianParcelsService.readyForHome(this.data).subscribe((data: any) => {
        //         this.dialogRef.close({doneORno: 'Yes'});
        //         this.showLoading = false;
        //
        //     });
        // } else {
        //     if (this.data[0].delivery_company_id) {
        //         this.showLoading = true;
        //         this.russianParcelsService.readyForHome(this.data).subscribe((data: any) => {
        //             this.dialogRef.close({doneORno: 'Yes'});
        //             this.showLoading = false;
        //
        //
        //         });
        //     } else {
        //         this.close();
        //     }
    }


    public close(): void {
        this.dialogRef.close({doneORno: 'no'});

    }

    ngOnDestroy() {
    }
}
