import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../_services';
import {Event, NavigationStart, NavigationEnd, Router} from '@angular/router';
import {FormGroup, FormBuilder, FormArray, FormControl} from '@angular/forms';
import {ScriptLoaderService} from '../../_services/script-loader.service';

@Component({
    selector: 'app-ordergroup',
    templateUrl: './ordergroup.component.html',
    styleUrls: ['./ordergroup.component.css']
})
export class OrdersGroupComponent implements OnInit {
    @ViewChild('trackingcode') inputText;
    orders = [];
    model = [];
    orderfromOrdersgroup = [];
    ogtracking;
    checked = [];
    ponyTracking;
    showLoading = true;
    chack_messege;
    alert_messege;
    chack_ok = 0;
    alert_ok = 0;
    ordersid = [];
    modal: boolean = false;
    p;
    print_lable_og;
    print_list_og;

    constructor(private _userService: UserService, private _script: ScriptLoaderService, private _router: Router) {
    }

    ngOnInit() {
        this.showLoading = true;
        this._userService.getOrderGroup().subscribe(
            data => {
                for (var i = 0; i < data.orders.orderGroups.length; i++) {
                    if (data.orders.orderGroups[i].pony_status == 1) {
                        this.orders.push(data.orders.orderGroups[i]);
                    }
                }
                this.showLoading = false;
            },
            error => console.log(error)
        );
    }

    get_print_code(poni_tracking = false) {

        if (!poni_tracking) {
            poni_tracking = this.ponyTracking;
        }

        window.open('http://warehouseback.onex.am/api/lable_print_code/' + poni_tracking);

    }

    packed() {
        this.showLoading = true;

        this._userService.change_pony_status(this.checked).subscribe(
            data => {
                for (let i = 0; i < this.orders.length; i++) {
                    for (let j = 0; j < this.ordersid.length; j++) {
                        if (this.orders[i] == this.ordersid[j]) {
                            this.orders.splice(i, 1);
                        }
                    }
                }
                this.showLoading = false;
            },
            error => console.log(error)
        );
    }

    get_pony_tracking(modal = false) {
        this.modal = modal;
        this.showLoading = true;
        this._userService.order_request(this.checked).subscribe(
            data => {
                if (data.orders.status === 1) {
                    if (this.modal) {
                        this.alert_ok = 1;
                        this.alert_messege = data.orders.notification;
                        this.ponyTracking = data.orders.pony_number;
                    } else {
                        this.chack_messege = data.orders.notification;
                        this.chack_ok = 1;
                        this.ponyTracking = data.orders.pony_number;
                    }
                    this._script.loadScripts('app-ordergroup', ['/assets/js/henrik.js']);
                } else if (data.orders.status === 0) {
                    if (this.modal) {
                        this.alert_ok = 2;
                        this.alert_messege = data.orders.notification;
                    } else {

                        this.chack_ok = 2;
                        this.chack_messege = data.orders.notification;
                    }
                    this._script.loadScripts('app-ordergroup', ['/assets/js/henrik.js']);
                }
                this.model = [];
                this.showLoading = false;
            },
            error => console.log(error)
        );
    }

    get_orders(ordergroup, ogtracking, ponytracking, order) {
        this.ordersid.push(order);
        this.showLoading = true;
        this.checked = ordergroup;
        this.ponyTracking = ponytracking;
        this.print_lable_og = order.tracking_code;
        this.print_list_og = order;

        this._userService.getorders(ordergroup).subscribe(
            data => {
                this.ogtracking = ogtracking;
                this.orderfromOrdersgroup = data.orders.orders;
                this.showLoading = false;
                data.orders.orders.map(k => {
                    console.log(k.in_arm);
                });
            },
            error => console.log(error)
        );
    }

    updateCheckedOptions(event, order) {

        this.ordersid.push(order);

        if (event.target.checked) {
            this.checked.push(event.target.value);
        } else {
            for (let i = 0; i < this.checked.length; i++) {
                if (event.target.value == this.checked[i]) {
                    this.checked.splice(i, 1);
                }
            }

            return this.checked;
        }

    }

    manifast() {
        // this._userService.get_orders_for_manifest(this.checked).subscribe(
        //     data => {
        //         console.log(data);
        //     },
        //     error => console.log(error)
        // );
        // this._userService.manifest().subscribe(
        //     data => {
        //         console.log(data);
        //     },
        //     error => console.log(error)
        // );
        window.open('https://www.onex.ru/api/exel/');
    }

    manifastUsa(): void {
        window.open('https://www.onex.ru/api/exelUsa/');
    }

    getPochtaRussiaManifast(): void {
        window.open('https://onex.ru/poshta/manifest');
    }

    manifestGbs(): void {
        window.open('https://onex.ru/GBS/manifest');
    }

    print_label() {
        window.open('http://warehouseback.onex.am/api/print_lable/' + this.print_lable_og);
    }

}
