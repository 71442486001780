import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SliderActionModal} from '../service/slider-action/slider-action.component';
import {ImagesDate} from '../_models';
import {OrderServices, ServiceStatus} from '../_models/serviceStatus.model';
import {Iservice} from '../_services/iservice.service';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'return-service-view',
    templateUrl: 'return-service.component.html',
    styleUrls: ['return-service.component.scss'],
})
export class ReturnServiceComponent implements OnInit {
    public onexTableTitle = [
        // 'ID User',
        // 'ID Order',
        'Full Name',
        'Recipient',
        'Dispatch',
        'Tracking Code',
        'Weight',
        'Service',
        'Images',
        'Label',
        'Comments',
        'Customer Comment',
        'Date',
    ];
    public orders: OrderServices[];
    public searchData;
    public filterDataTable: FormGroup;

    constructor(
        public iService: Iservice,
        private modalServiceMaterial: MatDialog,
        private _fb: FormBuilder
    ) {
    }

    ngOnInit() {
        // this._getOrdersReturnAndSend();
        this._formBuilder();
    }

    private _formBuilder(): void {
        this.filterDataTable = this._fb.group({
            trackingCode_filter: [null],
        });
    }

    // private _getOrdersReturnAndSend() {

    // }

    public openModal(i) {
        // this.iService.getImages(i).subscribe((data: ImagesDate) => {
        //     this.iService.array = JSON.parse(data.data.images);
        // });
        // const modalRef = this.modalService.open(SliderActionModal, {
        //     windowClass: 'makePhotoModal',
        // });

        const modalRef = this.modalServiceMaterial.open(SliderActionModal, {
            width: '1000px',
            panelClass: 'custom-dialog-container',
            data: {
                dataKey: i
            }
        });
    }

    public onSearchChange(searchValue: string) {
        this.iService.getReturnService(searchValue).subscribe((data: any) => {
            // this.orders = data.data;
            this.searchData = data.data;
        });
        // if (searchValue !== '') {
        //     this.orders = this.orders.filter((order) =>
        //         order.order.tracking_code.includes(searchValue)
        //     );
        // } else {
        //     this._getOrdersReturnAndSend();
        // }
    }
}
