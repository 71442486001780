import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {RussianParcelsService, UserService} from '../../../_services';
import {FilterShelfBoxesPipe} from '../../../_pipes/filter-shelf-boxes.pipe';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-show-russian-parcels',
    templateUrl: 'show-russian-parcels.component.html',
    styleUrls: ['show-russian-parcels.component.scss']
})

export class AppShowRussianParcelsComponent implements OnInit {
    @ViewChild('tracking_code') public tracking_code: ElementRef;
    @ViewChildren('trackCheckbox') public trackCheckbox: QueryList<any>;
    @ViewChildren('printBtn') public printBtn: QueryList<any>;
    public ordersTracks = [];
    showLoading = true;
    public orders = [];
    orderTrackingCodes = [];
    finalOrders = [];
    p;
    searchTrackingCode;
    activeFilter = 'all';
    public ok = false;
    public top = 'top';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public totalWeight = 0;
    public ordersForCalc = [];
    public totalWeightCalc;
    public audioStatus: string;
    public message: string;
    public scanedOrder = [];
    public alertSuccess = true;
    public yellowRow: boolean;
    public isSearch: boolean;
    public id: number | string;
    public searchRecipientData;
    public searchData = {
        tracking_code: null,
        is_scan_ru_warehouse: null
    };

    constructor(
        private _userService: UserService,
        private filter: FilterShelfBoxesPipe,
        private russianParcelService: RussianParcelsService,
        private route: ActivatedRoute
    ) {
    }


    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getRuOrders(this.id);
    }

    // public getRuOrders(id) {
    //     this.russianParcelService.getRuOrders(id).subscribe(data => {
    //         this.orders = data.data;
    //         console.log(data);
    //     });
    // }

    public getRuOrders(id) {
        this.orders = [];
        this.orderTrackingCodes = [];
        this.russianParcelService.getRuOrders(id).subscribe(
            data => {
                this.finalOrders = data.data;
                this.finalOrders.map(item => {
                    item.yellowRow = item.activated === '2';
                });
                this.finalOrders = this.finalOrders.sort((a, b) => {
                    if (a.orderer_info && b.orderer_info) {
                        if ((a.orderer_info.split(' ')[2] > b.orderer_info.split(' ')[2])) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                });
                this.showLoading = false;
                this.checkOrdersActivate();
            },
            error => console.log(error)
        );
    }

    //
    checkOrdersActivate() {
        if (this.activeFilter === 'scaned') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.is_scan_ru_warehouse === 1) {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += +weight.weight;
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else if (this.activeFilter === 'notScaned') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.is_scan_ru_warehouse === 0) {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += +weight.weight;
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                this.ordersForCalc.push(weight);
                this.totalWeight += +weight.weight;
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            }
        }
    }

    //
    search(tracking) {
        this.searchTrackingCode = tracking;
        this.isSearch = true;
        this.finalOrders.map(item => {
            if (item.tracking_code.toUpperCase() === tracking.toUpperCase()) {
                console.log(item);
                this.searchRecipientData = item;
                return;
            }
        });
        this.trackCheckbox.map(item => {
            if (item.nativeElement.id.toUpperCase() === tracking.toUpperCase()) {
                if (!item.nativeElement.checked) {
                    this.isSearch = false;
                    item.nativeElement.click();
                    this.message = 'OK';
                    this.audioStatus = '../assets/audio/ok.mp3';
                } else {
                    this.isSearch = false;
                    this.audioStatus = '../assets/audio/error-stab-notification.mp3';
                    this.message = 'Repeat';

                }
            } else {
                if (this.isSearch) {
                    this.audioStatus = '../assets/audio/error.mp3';
                    this.message = 'Error';
                }

            }
        });
        console.log(this.searchRecipientData);
        setTimeout(() => {

            this.tracking_code.nativeElement.value = '';

        }, 1000);
        setTimeout(() => {
            this.message = null;
            this.audioStatus = null;
        }, 1500);
    }

    //
    //
    // @HostListener('document:keydown', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent): void {
    //     if (event.ctrlKey && event.keyCode === 80 && this.scanedOrder.length) {
    //         event.preventDefault();
    //         // @ts-ignore
    //         document.querySelector(`#print-scaned-section${this.searchTrackingCode}`).click();
    //     }
    // }
    //
    //
    setPreBox(event, order) {
        order.yellowRow = event.checked;
        this.russianParcelService.searchRuOrders({
            tracking_code: order.tracking_code,
            is_scan_ru_warehouse: event.checked ? 1 : 0
        }).subscribe(
            (data: any) => {
                order.is_scan_ru_warehouse = data.data.is_scan_ru_warehouse;
                this.yellowRow = data.data.is_scan_ru_warehouse;
            }, e => {
                console.log(e);
            }
        );

    }

}
