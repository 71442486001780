import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const host = environment.API_ENDPOINT_ROOT;

@Injectable({
  providedIn: "root",
})
export class TrackingReportService {
  public detectUserID = +localStorage.getItem("detectUser");

  constructor(private httpClient: HttpClient) {}

  /* old api*/
  index(role) {
    return this.httpClient
      .post<any>(host + "/report", role, httpOptions)
      .pipe();
  }

  search(tracking) {
    return this.httpClient
      .post<any>(host + "/tracking-report", tracking, httpOptions)
      .pipe();
  }

  showDetails(id) {
    return this.httpClient
      .post<any>(host + "/report/showDetails", id, httpOptions)
      .pipe();
  }

  /* new api */

  getWarehousesForReport() {
    return this.httpClient
      .get<any>(host + "/newReport/getWarehouses", httpOptions)
      .pipe();
  }

  searching(data) {
    if (this.detectUserID == 8) {
      return this.httpClient
        .get<any>(
          host +
            `/filter-russian-orders?tracking_code=${
              data.order.tracking_code ? data.order.tracking_code : ""
            }&user_full_name=${
              data.order.client_fullname ? data.order.client_fullname : ""
            }&from=${data.order.created_at ? data.order.created_at : ""}&to=${
              data.order.created_at_end ? data.order.created_at_end : ""
            }&status=${data.order.status}&parcel_names=${
              data.parcel ? data.parcel : ""
            }&export=`,
          httpOptions
        )
        .pipe();
    } else {
      return this.httpClient
        .get<any>(
          host +
            `/filter-usa-orders?tracking_code=${
              data.order.tracking_code ? data.order.tracking_code : ""
            }&user_full_name=${
              data.order.client_fullname ? data.order.client_fullname : ""
            }&destination=${data.order.belongs}&dispatch=${
              data.order.dispatch
            }&from=${data.order.created_at ? data.order.created_at : ""}&to=${
              data.order.created_at_end ? data.order.created_at_end : ""
            }&status=${data.order.status}&parcel_names=${
              data.parcel ? data.parcel : ""
            }&export=`,
          httpOptions
        )
        .pipe();
    }
  }

  showDataReportUsa(data, showDesc) {
    if (this.detectUserID == 8) {
      return this.httpClient
        .get<any>(
          host +
            `/filter-russian-orders?tracking_code=${
              data.order.tracking_code ? data.order.tracking_code : ""
            }&user_full_name=${
              data.order.client_fullname ? data.order.client_fullname : ""
            }&destination=${data.order.belongs}&dispatch=${
              data.order.dispatch
            }&from=${data.order.created_at ? data.order.created_at : ""}&to=${
              data.order.created_at_end ? data.order.created_at_end : ""
            }&status=${data.order.status}&parcel_names=${
              data.parcel ? data.parcel : ""
            }&export=&show_more=${showDesc ? showDesc : ""}`,
          httpOptions
        )
        .pipe();
    } else {
      return this.httpClient
        .get<any>(
          host +
            `/filter-usa-orders?tracking_code=${
              data.order.tracking_code ? data.order.tracking_code : ""
            }&user_full_name=${
              data.order.client_fullname ? data.order.client_fullname : ""
            }&destination=${data.order.belongs}&dispatch=${
              data.order.dispatch
            }&from=${data.order.created_at ? data.order.created_at : ""}&to=${
              data.order.created_at_end ? data.order.created_at_end : ""
            }&status=${data.order.status}&parcel_names=${
              data.parcel ? data.parcel : ""
            }&export=&show_more=${showDesc ? showDesc : ""}`,
          httpOptions
        )
        .pipe();
    }
  }

  editWeight(data) {
    return this.httpClient.post<any>(host + "/newReport/update", data);
  }

  getParcels() {
    return this.httpClient.get<any>(host + "/parcels");
  }

  getTrackings() {
    return this.httpClient.get<any>(host + "/inventories");
  }

  public searchTracking(trackingCode) {
    return this.httpClient.post<any>(
      host + `/inventories/${trackingCode}`,
      trackingCode
    );
  }

  public refreshTrackingPage() {
    return this.httpClient.get<any>(host + `/reset-all/inventories`);
  }

  chekInputTrack(track) {
    return this.httpClient.post<any>(host + "/report/printLabel", track);
  }

  getWorkData(dateTime) {
    if (dateTime) {
      return this.httpClient.post<any>(host + "/admin/report", dateTime);
    }
    return;
  }

  getExtraOrders(params) {
    return this.httpClient.get<any>(host + `/dws/get`, {
      params: { ...params },
    });
  }

  getUsers() {
    return this.httpClient.get<any>(host + "/dws/get/users");
  }

  fetchChangeComment(data) {
    return this.httpClient.post<any>(host + "/order/change-comment", data);
  }

  fetchExpectedOrders(params) {
    return this.httpClient.get<any>(host + "/expected-orders/get", {
      params,
    });
  }
}
