import {Component, OnInit} from '@angular/core';
import {UserService} from '../../_services';
import {OpenCloseMenuService} from '../partial-services/open-close-menu.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    user;
    role;
    whId;
    orderAction;
    appsDropdownOpen;
    boxeslist;
    smartservice: any;
    unknown: any;
    consolidation: boolean;
    groupsCount;
    checked: boolean;

    constructor(private _userService: UserService,
                private _openCloseService: OpenCloseMenuService) {
    }

    ngOnInit() {
        this.user = localStorage.getItem('name');
        this.role = localStorage.getItem('role');
        this.groupsCount = localStorage.getItem('countgroups');
        this.whId = +localStorage.getItem('detectUser');
    }

    toggleIconOnlySidebar() {
        if (window.innerWidth <= 768) {
            this._openCloseService.sidebarOpened = false;
            this._openCloseService.iconOnlyToggled = !this._openCloseService.iconOnlyToggled;
            document.querySelector('.sidebar-offcanvas').classList.remove('active');
        }
        return;
    }

    handleChangeSwitch($event: boolean) {
        this.checked = $event;
    }
}
