import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/_services";
import { ScriptLoaderService } from "../../_services/script-loader.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-boxing",
  templateUrl: "./boxing.component.html",
  styleUrls: ["./boxing.component.css"],
})
export class BoxingComponent implements OnInit {
  box: any = {};
  info: any = {};
  selected = false;
  canRm = false;
  boxtable = [];
  preBoxOrders = [];
  boxid;
  parcelId: number;
  addtobox;
  rmFromBox;
  unboxing: any = {};
  messege;
  ok;
  audiodata;
  unboxingMode = false;
  public isBattery: boolean = false;
  public boxID: any;
  whId;
  isEqualBoxId;
  isEqualBoxIdDefault;

  constructor(
    private _userService: UserService,
    private router: Router,
    private _script: ScriptLoaderService
  ) {}

  ngOnInit() {
    this.whId = +localStorage.getItem("detectUser");
    if (typeof this._userService.boxTrackingPrefix !== "undefined") {
      this.enter(this._userService.boxTrackingPrefix);
    }

    this.boxID = JSON.parse(localStorage.getItem("data"));
    this._userService.getBoxingInfo(this.boxID).subscribe(
      (data) => {
        this.selected = true;
        this.preBoxOrders = data.preBoxOrders.reverse();
        this.box.id = data.box_id;
        this.isEqualBoxIdDefault = data.box_id;
        this.box.status = data.box_status;
        this.box.tracking = this.boxID;
        this.info.id = data.box_id;
      },

      (error) => console.log(error)
    );

    this._userService.getBoxWithOrder(this.boxID).subscribe(
      (data) => {
        this.box.parcel = data.parcel_name;
        this.boxtable = data.orders.reverse();
        this.boxid = data.box_id;
        this.parcelId = data.parcel_destination;
        if (this.boxtable.length === 0) {
          this.canRm = true;
        }
      },
      (error) => console.log(error)
    );
  }

  enter(trackingcode) {
    this._userService.getBoxingInfo(trackingcode).subscribe(
      (data) => {
        this.selected = true;
        this.preBoxOrders = data.preBoxOrders.reverse();
        this.box.id = data.box_id;
        this.box.status = data.box_status;
        this.box.tracking = trackingcode;
        this.info.id = data.box_id;
      },
      (error) => console.log(error)
    );

    this._userService.getBoxWithOrder(trackingcode).subscribe(
      (data) => {
        this.box.parcel = data.parcel_name;
        this.boxtable = data.orders.reverse();
        this.boxid = data.box_id;
        this.parcelId = data.parcel_destination;
        if (this.boxtable.length === 0) {
          this.canRm = true;
        }
      },
      (error) => console.log(error)
    );
  }

  entertoaddBox(getOrdertracking) {
    this.messege = null;
    this.ok = null;
    this.info.getOrdertracking = getOrdertracking;
    if (this.preBoxOrders.length > 0) {
      this.preBoxOrders = this.preBoxOrders.filter(
        (item) => item.tracking_code != getOrdertracking
      );
    }

    this._userService.addOrderToBox(this.info).subscribe(
      (data) => {
        if (data.status == 200) {
          if (data.order[0].battery_type) {
            this.isEqualBoxId = data.order[0].box_id;
            this.isBattery = true;
            this.ok = 1;
            this.messege = data.message;
            this.boxtable.push(data.order[0]);
            this.addtobox = null;
            this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
          } else {
            this.addtobox = null;
            let array3 = [...this.boxtable, ...data.order];
            for (let i = 0; i < array3.length; ++i) {
              for (let j = i + 1; j < array3.length; ++j) {
                if (array3[i].id === array3[j].id) {
                  array3.splice(j--, 1);
                }
              }
            }

            this.boxtable = array3;
            if (data.order.is_person !== "1" && data.order.is_person) {
              this.messege = "company";
              this.ok = 3;
            } else {
              this.messege = data.message;
              this.ok = 1;
            }
            this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
          }
        } else if (data.status == 400) {
          if (data.message.includes("Error in battery type")) {
            this.isBattery = true;
          } else {
            this.isBattery = false;
          }
          this.addtobox = null;
          this.messege = data.message;
          this.ok = 2;
          if (data.message.includes("Service isn't done")) {
            this.messege = "service";
          }
          this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
        } else if (data.status == 404) {
          this.addtobox = null;
          this.messege = data.message;
          this.isEqualBoxId = data.box_id;
          this.ok = 4;
          this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
        }
      },
      (error) => console.log(error)
    );
  }

  forcetoaddBox(getOrdertracking) {
    this.messege = null;
    this.ok = null;
    this.info.getOrdertracking = getOrdertracking;

    this._userService.forceOrderToBox(this.info).subscribe(
      (data) => {
        if (data.status == 200) {
          this.addtobox = null;
          this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
          // this.boxtable = this.boxtable.filter(item => item.tracking_code !== data.tracking_code);
          let checkOrder = this.boxtable.some((key) => {
            return key.tracking_code == data.order.tracking_code;
          });
          if (data.order.is_person !== "1" && data.order.is_person !== null) {
            this.messege = "company";
            this.ok = 3;
          } else {
            this.messege = data.message;
            this.ok = 1;
          }
          if (!checkOrder) {
            this.boxtable.push(data.order);
          }
        } else if (data.status == 400) {
          this.addtobox = null;
          this.messege = data.message;
          this.ok = 2;
          this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
        }
      },
      (error) => console.log(error)
    );
  }

  unboxfunc(tracking, order) {
    this.messege = null;
    this.ok = null;
    this.unboxing.tracking = tracking;
    this.unboxing.id = this.boxid;
    this._userService.unbox(this.unboxing).subscribe(
      (data) => {
        if (data.status == 200) {
          this.messege = data.message;
          this.ok = 1;
          this.boxtable = this.boxtable.filter(
            (item) => item.tracking_code !== tracking
          );
          this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
          this.rmFromBox = null;
        } else if (data.status == 400) {
          this.rmFromBox = null;
          this.messege = data.message;
          this.ok = 2;
          this._script.loadScripts("app-boxing", ["/assets/js/henrik.js"]);
        }
      },
      (error) => console.log(error)
    );
  }

  back() {
    this.router.navigate(["/boxes"]);
    localStorage.removeItem("data");
  }

  removeBox(id) {
    this._userService.rmBox({ box_id: id }).subscribe(
      (d) => {
        // console.log(d);
        if (d.status == 200) {
          alert(d.message);
          this.back();
        }
        if (d.status == 400) {
          alert(d.message);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  addPreBoxOrders(order) {
    this.preBoxOrders = this.preBoxOrders.filter((item) => item.id != order.id);
    this.entertoaddBox(order.tracking_code);
  }
}
