import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TrackingReportService} from '../../_services/tracking-report.service';

@Component({
    selector: 'app-modal-inventories',
    templateUrl: 'modal-inventories.component.html',
    styleUrls: ['modal-inventories.component.scss']
})

export class ModalInventoriesComponent implements OnInit, OnDestroy {
    public showLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public orders: any,
        public dialogRef: MatDialogRef<ModalInventoriesComponent>,
        private _refresh: TrackingReportService,
    ) {
    }


    public send(): void {
        if (this.orders.length) {
            this.showLoading = true;
            this._refresh.refreshTrackingPage().subscribe((data: any) => {
                this.orders.splice(0, this.orders.length);
                this.dialogRef.close({doneORno: 'Yes'});
                this.showLoading = false;
            });
        }
    }

    public close(): void {
        this.dialogRef.close({doneORno: 'No'});

    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
