import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BarcodeScannerLivestreamComponent} from 'ngx-barcode-scanner';
import {SearchService, ServiceOrder, SplitOrders} from '../_models';
import {Iservice} from '../_services/iservice.service';
import {AttachPhotoComponent} from './attachPhoto/attachPhoto.component';
import {SliderActionModal} from './slider-action/slider-action.component';

@Component({
    selector: 'app-service',
    templateUrl: 'iservice.component.html',
    styleUrls: ['iservice.component.scss']
})


export class IappService implements OnInit, OnDestroy {
    @ViewChild(BarcodeScannerLivestreamComponent)
    @ViewChild('checkServiceTrackInput') public checkServiceTrackInput;
    public barcodeScanner: BarcodeScannerLivestreamComponent | undefined;
    public generateTracking = new FormControl();
    public barcodeValue: any;
    public openSanner: boolean = false;
    public searchTrackingForm: FormGroup;
    public commentForm: FormGroup;
    public approvedImages: string[];
    public userInfo: ServiceOrder;
    public status: Number;
    public done: boolean = false;
    public messageErorr: string;
    public ok = false;
    public top = 'top';
    public left = 'left';
    public mLeft = 1;
    public mRight = 1;
    public mTop = 20;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public isPhoto: boolean;
    public recipients: SplitOrders [];
    closeResult = '';

    constructor(private _fb: FormBuilder, private modalService: NgbModal, public iService: Iservice) {
    }

    ngOnInit() {
        this.checkServiceTrackInput.nativeElement.focus();
        this._formBuilder();
    }

    private _formBuilder(): void {
        this.searchTrackingForm = this._fb.group({
            trackingCode: [null, Validators.required],
        });
        this.commentForm = this._fb.group({
            commentService: [null]
        });
    }

    private _photoOut(): void {
        console.log(this.iService.array);

        if (this.iService.array.length > 0) {
            const serviceBox = {
                images: JSON.stringify(this.iService.array),
                comment: this.commentForm.get('commentService').value,
                order_id: this.userInfo.id
            };
            this.iService.addPhoto(serviceBox).subscribe((res: any) => {
                this.commentForm.reset();
            });
        }
    }

    private _actionService() {
        const actionService = {
            order_id: this.userInfo.id,
            comment: this.commentForm.get('commentService').value,
        };
        this.iService.addPhoto(actionService).subscribe((res: any) => {
            this.status = res.status;
            this.messageErorr = res.message;
            this.ok = res.status ? res.message : false;
            this.commentForm.reset();
        });
    }

    public searchService(searchText) {
        const search_tracking_code = {
            tracking_code: searchText
        };
        this.iService.getServiceOrder(search_tracking_code).subscribe((data: SearchService) => {
            this.userInfo = data.data;
            this.status = data.status;
            this.messageErorr = data.message;
            this.iService.userData = data.data;
            this.done = false;
            if (data.data) {
                this.recipients = data.data.split_orders;
            }
        });
        setTimeout(() => {
            this.messageErorr = null;
        }, 2500);
    }

    public open(content) {
        const modalRef = this.modalService.open(AttachPhotoComponent, {windowClass: 'makePhotoModal', size: 'sm'}).result.then((result) => {
            this.iService.array = result;
        }, (reason) => {
            this.closeResult = `Dismissed `;
        });
    }


    public openSliderModal(src) {
        const modalRef = this.modalService.open(SliderActionModal, {windowClass: 'makePhotoModal'});
        this.iService.srcThisImg = src;
    }

    public doneService() {
        if (this.userInfo.service.id === 7 || this.userInfo.service.id === 6 || this.userInfo.service.id === 8) {
            if (this.iService.array !== undefined) {
                this._photoOut();
                this.done = true;
                this.ok = true;
                setTimeout(() => {
                    this.ok = false;
                    this.searchTrackingForm.reset();
                }, 2500);
                this.iService.array = [];
                this.iService.userData = null;
            } else {
                this.isPhoto = true;
                setTimeout(() => {
                    this.isPhoto = false;
                    this.ok = false;
                    this.done = true;
                    this.searchTrackingForm.reset();
                    this.iService.userData = null;
                }, 2000);
            }
        } else {
            this._actionService();
            this.done = true;
            this.ok = true;
            this.iService.array = [];
            this.iService.userData = null;
            setTimeout(() => {
                this.ok = false;
                this.searchTrackingForm.reset();
            }, 2500);
        }
    }

    // ngAfterViewInit() {
    //     this.barcodeScanner.start();
    // }

    // public openSannerBarcod():void {
    //     this.openSanner =!this.openSanner
    //     console.log(111)
    // }

    // public onValueChanges(result:any) {
    //     this.barcodeValue = result.codeResult.code;
    //     this.searchTrackingForm.patchValue({
    //         trackingCode: this.barcodeValue
    //     })
    // }

    // public  onStarted(started:any) {
    //     console.log(started);
    // }
    public generateTrackingForSplit(id: number, userInput): void {
        console.log(userInput);
        this.iService.generateTrackingForServiceById(id).subscribe((res: any) => {
            userInput.value = res.data;
        });
    }

    copyMessage(inputElement): void {
        inputElement.removeAttribute('disabled');
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        inputElement.setAttribute('disabled', true);
    }


    ngOnDestroy() {
    }
}
