import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SendReturnService} from 'src/app/_services/send-return.service';

@Component({
    selector: 'done-modal',
    templateUrl: 'done-modal.component.html',
    styleUrls: ['done-modal.component.scss'],
})
export class DoneModalComponent implements OnInit, OnDestroy {
    @Input() public confirmObject;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    constructor(
        public activeModal: NgbActiveModal,
        private _sendReturnService: SendReturnService
    ) {
    }

    ngOnInit() {
        console.log(this.confirmObject);
    }

    public closeModal(): void {
        console.log('[MODAL] IS`NOT DONE');
        this.activeModal.close();
    }

    public doneModal(): void {
        let obj = {
            order_id: this.confirmObject.order_id
        };
        if (this.confirmObject.type === 'done') {
            this._sendReturnService.doneService(obj)

                .subscribe((data) => {
                    console.log('[MODAL] DONE');
                    this.passEntry.emit(this.confirmObject.status_code);
                    this.activeModal.close();
                });
        } else if (this.confirmObject.type === 'clientSend') {
            this.activeModal.close();
            this.sendOrderId(this.confirmObject.order_id);
        }
    }

    public sendOrderId(order_id) {
        let orderData;
        this._sendReturnService.sendOrderId(order_id).subscribe(data => {
            orderData = data;
            if (orderData.status === 400) {
                this._sendReturnService.message.next(orderData.message);
                // = orderData.message;
            } else {
                window.location.reload();
            }
            // this.orders = this.orders.filter(i => i.order.tracking_code !== orderData.data.order.tracking_code);

        });

    }

    ngOnDestroy() {
    }
}
