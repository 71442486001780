import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/_services';
import {ScriptLoaderService} from 'src/app/_services/script-loader.service';

@Component({
  selector: 'app-parcels-create',
  templateUrl: './parcels-create.component.html',
  styleUrls: ['./parcels-create.component.scss']
})
export class ParcelsCreateComponent implements OnInit {
  pahests = [];
  parcel: any = {};
  isSAdmin: boolean;
  wh;
  data;
  messege;
  ok;
  isGermany: boolean;

  constructor(private _userService: UserService, private _script: ScriptLoaderService) {
      this.isGermany = localStorage.getItem('detectUser') === '6';
 }

  ngOnInit() {
    this.isSAdmin = localStorage.getItem('role') === 'super_admin';
    this._userService.getPahest().subscribe(
      data => {
        if (this.isSAdmin) {
          this.pahests = data.pahests;
        } else {
          this.wh = data.pahests;
        }
      },
      error => console.log(error)
    );
  }

  creatparcel() {
   // if (!this.isGermany) {
     if (!this.isSAdmin) {
       this.parcel.parcel_warehouse = this.wh.id;
     }
     this._script.loadScripts('app-parcels-create', ['/assets/js/henrik.js']);

     this._userService.createParcel(this.parcel).subscribe(
       data => {
         this.data = data;
         if (this.data.status == 200) {
           this.messege = this.data.message;
           this.ok = 1;
           this._script.loadScripts('app-parcels-create', ['/assets/js/henrik.js']);
           this.parcel = {};
         } else if (this.data.status == 400) {
           this.messege = this.data.messages;
           this.ok = 2;
           this._script.loadScripts('app-parcels-create', ['/assets/js/henrik.js']);
         }


       },
       error => console.log(error)
     );
   // }

  }

}
