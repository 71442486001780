import {NgModule} from '@angular/core';
import {AppRussianParcelsComponent} from './russian-parcels.component';
import {AppShowRussianParcelsComponent} from './show-russian-parcels/show-russian-parcels.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {AppRussianParcelsScanComponent} from './russian-parcels-scan/russian-parcels-scan.component';

@NgModule({
    declarations: [
        AppRussianParcelsComponent,
        AppShowRussianParcelsComponent,
        AppRussianParcelsScanComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
    ]
})

export class RussianParcelsModule {

}
