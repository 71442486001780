import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';

const host = environment.API_ENDPOINT_ROOT;

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class AuthenticationService {
    admin_activation: string;
    role: string;
    returnUrl: string;
    admin: boolean = false;
    isLoggedIn = false;
    redirectUrl: string;
    interval; // Moved it out.

    constructor(private httpClient: HttpClient, private _router: Router) {
    }

    login(email: string, password: string) {
        return this.httpClient.post(host + '/signin', {email: email, password: password});
    }

    logOut() {
        this.isLoggedIn = false;
        let items = ['currentUser', 'role', 'name', 'exp', 'iat', 'countgroups'];
        for (let i = 0; i < items.length; i++) {
            localStorage.removeItem(items[i]);
        }
        return '/login';
    }

    checkLogin() {
        let current = localStorage.getItem('exp');
        if (current != null) {
            this.updateData();
            let now = new Date(),
                expD = +localStorage.getItem('exp'),
                parseDate = +now.getTime().toString().slice(0, -3);
            this.isLoggedIn = expD >= parseDate;
        } else {
            this.isLoggedIn = false;
        }
    }

    autoLogOut() {
        let now = new Date(),
            expD = +localStorage.getItem('exp'),
            parseDate = +now.getTime().toString().slice(0, -3);
        if (expD < parseDate) {
            this.isLoggedIn = false;
            this._router.navigate([this.logOut()]);
            this.clearCustomInterval();
        }
    }

    updateData() {
        let ms = 10 * 1000;
        this.interval = setInterval(() => {
            this.autoLogOut();
        }, ms);
    }

    clearCustomInterval() {
        clearInterval(this.interval);
    }


}
