import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cuttingDecimalNumbers',
  pure: true
})
export class CuttingDecimalNumbersPipe implements PipeTransform {

  transform(value: any): any {
    if (value > 0) {
      value = value.toString().split('.');
      value[1] = value[1].length > 3 ? value[1].slice(0, 2) : value[1];
      value = value.join('.');
    }
    return parseFloat(value);
  }

}
