import {Component, EventEmitter, forwardRef, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserService} from '../../../_services';

@Component({
    selector: 'app-custom-switch',
    template: `
        <div class="switch-main">
            <span class="menu-title">{{checked ? "Russian Orders" : "Standard"}}</span>
            <div class="switch" [class.checked]="checked" (click)="toggleSwitch()">
                <div class="switch-inner"></div>
            </div>
        </div>
    `,
    styleUrls: ['./switcher.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomSwitchComponent),
            multi: true
        }
    ]
})
export class CustomSwitchComponent implements ControlValueAccessor, OnInit {
    @Output() onChecked: EventEmitter<boolean> = new EventEmitter<boolean>();
    public checked: boolean;
    public switchData;
    private onChange: (value: boolean) => void;
    private onTouched: () => void;

    constructor(private _userService: UserService) {
    }

    ngOnInit() {
        this._userService.getSwitchValue().subscribe(
            (data: any) => {
                this.checked = data.option !== 'standard';
            });
    }

    toggleSwitch() {
        this.checked = !this.checked;
        if (this.onChange) {
            this.onChange(this.checked);
        }
        if (this.onTouched) {
            this.onTouched();
        }
        this.onChecked.emit(this.checked);
        if (this.checked) {
            this.switchData = {option: 'russian_orders'};
        } else {
            this.switchData = {option: 'standard'};
        }
        this._userService.changeSwitchValue(this.switchData).subscribe(
            (data) => {
            });

    }

    // ControlValueAccessor methods
    writeValue(value: boolean): void {
        this.checked = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // Not implemented in this example
    }
}
