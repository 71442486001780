import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TrackingReportService} from '../_services/tracking-report.service';

@Component({
    selector: 'app-team-report',
    templateUrl: './team-report.component.html',
    styleUrls: ['./team-report.component.scss'],
})

export class TeamReportComponent implements OnInit {
    public workDataTable = [
        'Name',
        'Repack',
        'User Set',
        'Boxing',
        // 'Work Time'
    ];
    public adminsReport: FormGroup;
    public workData: any;
    public workTime: string;
    loading = false;
    public userSet = 0;
    public repack_ = 0;
    public boxing_ = 0;
    public message = '';

    constructor(private teamReport: TrackingReportService, private formBuilder: FormBuilder) {
        this.adminsReport = this.setControls();
    }

    public async handleChangeDate(date: string) {
        let timeDate = {start: null, end: null};
        console.log(timeDate.end);
        this.loading = true;
        switch (date) {
            case 'today':
                const todayStart = new Date().toLocaleString('sv-SE').slice(0, 10);
                timeDate.start = todayStart + ' ' + '00:00:00';
                timeDate.end = new Date().toLocaleString('sv-SE');
                break;
            case 'yesterday':
                const startDay = new Date();
                startDay.setDate(startDay.getDate() - 1);
                const yesterday = startDay.toLocaleString('sv-SE').slice(0, 10);
                timeDate.start = yesterday + ' ' + '00:00:00';
                timeDate.end = yesterday + ' ' + '23:59:59';
                break;
            case 'current-week':
                const mon = this.getMonday(new Date());
                const monday = mon.toLocaleString('sv-SE').slice(0, 10);
                timeDate.start = monday + ' ' + '00:00:00';
                timeDate.end = new Date().toLocaleString('sv-SE');
                break;
            case 'current-month':
                const now = new Date();
                const monthDay = new Date(now.getFullYear(), now.getMonth(), 1).toLocaleString('sv-SE').slice(0, 10);
                timeDate.start = monthDay + ' ' + '00:00:00';
                timeDate.end = now.toLocaleString('sv-SE');
                break;

        }

        const start = new Date(timeDate.start);
        const end = new Date(timeDate.end);
        // @ts-ignore
        let result = (end - start) / 1000;
        this.getTime(result);


        await this.teamReport.getWorkData(timeDate).subscribe((data) => {
            if (data.length) {
                this.userSet = 0;
                this.repack_ = 0;
                this.boxing_ = 0;
                this.loading = false;
                this.message = ' ';
                this.workData = data;
                this.getTotalValues(this.workData);
            } else {
                this.workData = null;
                this.loading = false;
                this.message = 'No Result';
            }
            setTimeout(() => {
                this.message = '';
            }, 1000);
        });


    }

    public search() {
        this.loading = true;
        if ((this.adminsReport.value.workDate.start && this.adminsReport.value.workDate.end) || this.adminsReport.value.workDate.start) {

            const dateTime = {
                start: new Date(this.adminsReport.value.workDate.start).toLocaleString('sv-SE'),
                end: this.adminsReport.value.workDate.end ? new Date(this.adminsReport.value.workDate.end).toLocaleString('sv-SE') : new Date().toLocaleString('sv-SE')
            };

            this.teamReport.getWorkData(dateTime).subscribe((newData) => {
                if (newData.length) {
                    this.userSet = 0;
                    this.repack_ = 0;
                    this.boxing_ = 0;
                    this.loading = false;
                    this.message = ' ';
                    this.workData = newData;
                    this.getTotalValues(this.workData);
                } else {
                    this.workData = null;
                    this.loading = false;
                    this.message = 'No Result';
                }
                setTimeout(() => {
                    this.message = '';
                }, 1000);
            });
            const start = new Date(dateTime.start);
            const end = new Date(dateTime.end);
            // @ts-ignore
            let result = (end - start) / 1000;
            this.getTime(result);
        } else {
            this.loading = false;
        }
    }

    public getTime(result) {
        let h = Math.floor(result / 3600);
        let m = Math.floor(result % 3600 / 60);
        let hDisplay = h > 0 ? h + (' h ') : '';
        let mDisplay = m > 0 ? m + (' m ') : '';
        this.workTime = hDisplay + ' ' + mDisplay;
        return this.workTime;
    }

    public getTotalValues(array) {
        if (array.length) {
            array.map((el) => {
                this.repack_ += Number(el.repack);
                this.userSet += Number(el.user_set);
                this.boxing_ += Number(el.boxing);
            });
        }
        console.log(this.repack_, this.userSet, this.boxing_);
    }


    getMonday(d) {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }


    ngOnInit() {
    }

    private setControls(): FormGroup {
        return this.formBuilder.group({
            workDate: this.formBuilder.group({
                start: [null],
                end: [null],
            }),
        });
    }
}
