import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WebcamImage} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs-compat';
import {Iservice} from 'src/app/_services/iservice.service';

@Component({
    selector: 'app-attach-photo',
    templateUrl: 'attachPhoto.component.html',
    styleUrls: ['attachPhoto.component.scss']
})


export class AttachPhotoComponent implements OnInit, AfterViewInit {
    title = 'onexCamera';
    public imgArray: any = [];
    public allowCameraSwitch = true;
    public fileToUpload: File = null;
    public webcamImage: WebcamImage | undefined;
    private trigger: Subject<void> = new Subject<void>();
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    // Custom success WebCamera variables;
    @ViewChild('video') videosSelector: ElementRef;
    @ViewChild('canvas') canvasSelector: ElementRef;
    private width = 1920;
    private height = 0;

    private streaming = false;

    private videos = null;
    private canvas = null;
    private startbutton = null;

    constructor(public activeModal: NgbActiveModal, public iService: Iservice) {
    }

    ngOnInit() {
        this.startup();
    }

    ngAfterViewInit() {

    }

    triggerSnapshot(): void {
        this.trigger.next();
    }

    // handleImage(webcamImage: WebcamImage): void {
    //     this.webcamImage = webcamImages;
    //     this.imgArray.push(this.webcamImage.imageAsDataUrl);
    // }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }


    public doneBack() {
        this.activeModal.close(this.imgArray);
    }

    public delete(i) {
        this.imgArray = this.imgArray.filter((img, index) => index !== i);
        if (this.imgArray.length <= 0) {
            this.activeModal.close(this.imgArray);
        }

    }


    public onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
                reader.onload = (event: any) => {
                    this.imgArray.push(event.target.result);
                };
                reader.readAsDataURL(event.target.files[i]);
            }
        }
    }

    // Custom success WebCamera methods;
    private async startup() {
        this.videos = document.getElementById('video');
        this.canvas = document.getElementById('canvas');
        let aaa = this.videos;
        let ccc = this.canvas;
        this.startbutton = document.getElementById('startbutton');
        const constraints = {
            video: {
                width: {
                    min: 1920
                },
                height: {
                    min: 1280
                }
            }
        };

        try {
            let myStream = await window.navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    console.log(stream);
                    aaa.srcObject = stream;
                    aaa.play();
                })
                .catch(function (err) {
                    console.log('An error occurred: ' + err);
                });
            console.log(myStream);

        } catch (e) {
            console.log(e.message);
        }


        this.videos.addEventListener('canplay', function (ev) {
            if (!this.streaming) {
                this.height = aaa.videoHeight / (aaa.videoWidth / this.width);

                if (isNaN(this.height)) {
                    this.height = this.width / (4 / 3);
                }

                aaa.setAttribute('width', this.width);
                aaa.setAttribute('height', this.height);
                ccc.setAttribute('width', this.width);
                ccc.setAttribute('height', this.height);
                this.streaming = true;
            }
            console.log(navigator.mediaDevices);
        }, false);
        //
        //         // this.startbutton.addEventListener('click', function(ev) {
        //         //     takepicture();
        //         //     ev.preventDefault();
        //         // }, false);
        //         //
        //         // clearphoto();
    }


    public takepicture() {
        console.log(1111);
        this.height = 1200;
        const context = this.canvas.getContext('2d');
        console.log(this.width, this.height);
        if (this.width && this.height) {
            this.canvas.width = this.width;
            this.canvas.height = this.height;
            context.drawImage(this.videos, 0, 0, this.width, this.height);
            let data = this.canvas.toDataURL('image/png');
            this.imgArray.push(data);
            console.log(this.imgArray);
        }
    }
}
