import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasPayFile'
})
export class HasPayFilePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!Array.isArray(value)) {
      return;
    }
    switch (args) {
      case 'empty':
        value = value.filter(item => item.pay_confirmed_url == null);
        break;
      case 'hasItem':
        value = value.filter(item => item.pay_confirmed_url !== null);
        break;
    }
    return value;
  }

}
