import {Component, OnInit} from '@angular/core';
import {ShelfService} from '../../_services';
import {ScrollBar} from '@ng-bootstrap/ng-bootstrap/util/scrollbar';
import {animate, animation} from '@angular/animations';

@Component({
  selector: 'app-shelves',
  templateUrl: './shelves.component.html',
  styleUrls: ['./shelves.component.scss']
})
export class ShelvesComponent implements OnInit {

  sh_code: string;
  sh_box: string;
  check_order: string;
  selectedShelfBox;
  shelves;
  shelfBoxes; emptyShelfBox; hasItemsShelfBox;
  message; checkMessage;
  ordersInShelfBox; addedOrders = [];
  shelfBoxSelected = false;
  singleShelfBox = []; hidden;
  tr_codeToAdd; tr_codeToRemove;
  tracking_message_add; tracking_message_rm; tracking_rm;
  ordersToRM; ordersOk;
  activeSort = 'name'; activeFilter = 'all'; sortAsc = true;
  constructor(private shelfService: ShelfService) {
  }

  ngOnInit() {
    this.getShelves();
  }

  getShelves() {
    this.shelfService.getShelves().subscribe(
      r => {
        if (r.status === 'ok') {
          this.shelves = r.data;
        }
      },
      e => {
        console.log(e);
      }
    );
  }

  searchShelfDetails(value) {
    this.checkMessage = null;
    this.shelfBoxSelected = false;
    this.singleShelfBox = null;
    this.ordersInShelfBox = null;
    this.sh_box = null;
    if (value != '') {
      this.shelfService.getShelfDetails({'shelf': value}).subscribe(
        r => {
          if (r.status === 'ok') {
            // console.log('search details response is: ', r.dataResponse);
            this.shelfBoxes = r.dataResponse;
            this.emptyShelfBox = this.shelfBoxes.filter(item => item.quantity == 0).length;
            this.hasItemsShelfBox = this.shelfBoxes.filter(item => item.quantity !== 0).length;
            this.emptyShelfBox = this.emptyShelfBox === 0 ? '' : this.emptyShelfBox;
            this.hasItemsShelfBox = this.hasItemsShelfBox === 0 ? '' : this.hasItemsShelfBox;
          } else {
            console.log('search details error is:', r.message);
            this.message = r.message;
            this.shelfBoxes = null;
            setTimeout(() => {
              this.message = null;
            }, 3000);
          }
        }, e => {
          console.log('search details error is:', e);
          this.message = e.statusText;
          setTimeout(() => {
            this.message = null;
          }, 3000);
        }
      );
    }
  }

  getShelfBox(value) {
    this.checkMessage = null;
    this.addedOrders = [];
    this.tr_codeToAdd = '';
    this.tr_codeToRemove = '';
    if (value != '') {
      this.shelfService.getShelfBox({'shelf_box': value}).subscribe(
        r => {
          if (r.status === 'ok') {
            // console.log('response shelf box is: ', r);
            this.shelfBoxSelected = true;
            this.selectedShelfBox = r.shelf_box;
            this.singleShelfBox = r.orders;
            this.ordersToRM = r.ordersToRM;
            this.ordersOk = r.ordersOk;
          } else {
            this.singleShelfBox = null;
            this.shelfBoxSelected = false;
            this.message = r.message;
            setTimeout(() => {
              this.message = null;
            }, 2000);
            console.log('error shelf box is:', r.message);
          }
        }, e => {
          this.singleShelfBox = null;
          this.shelfBoxSelected = false;
          this.message = e.statusText;
          setTimeout(() => {
            this.message = null;
          }, 3000);
          console.log('error shelf box is:', e);
        }
      );
    }
  }

  addOrderInShelf(value, shelfBox) {
    this.checkMessage = null;
    if (value.length > 7) {
      this.shelfService.addOrderInShelfBox({'tracking_code': value, 'shelf_box': shelfBox})
        .subscribe(
          r => {
            if (r.status === 'ok') {
              console.log('added order in box', r);
              this.tr_codeToAdd = '';
              this.addedOrders.push({client_fullname: r.client_fullname, tracking_code: r.tracking_code, waight: r.weight, status: r.order_status});
              this.singleShelfBox.push({client_fullname: r.client_fullname, tracking_code: r.tracking_code, waight: r.weight, status: r.order_status});
            } else {
              console.warn(r);
              this.tracking_message_add = r.message;
              setTimeout(() => {
                this.tracking_message_add = null;
              }, 3000);
            }
          },
          e => {
            console.log(e);
            this.tracking_message_add = e.statusText;
            setTimeout(() => {
              this.tracking_message_add = null;
            }, 3000);
          }
        );
    }
  }

  removeOrderFromShelf(value) {
    this.checkMessage = null;
    if (value.length > 7) {
      this.shelfService.removeOrderFromShelfBox({'tracking_code': value, 'shelf_box': this.selectedShelfBox})
        .subscribe(
          r => {
            if (r.status === 'ok') {
              // console.log(r);
              this.tr_codeToRemove = '';
              this.addedOrders = this.addedOrders.filter(item => item.tracking_code !== r.tracking_code);
              this.singleShelfBox = this.singleShelfBox.filter(item => item.tracking_code !== r.tracking_code);
              this.tracking_rm = r.message;
              setTimeout(() => {
                this.tracking_rm = null;
              }, 3000);
            } else {
              console.warn(r);
              this.tracking_message_rm = r.message;
              setTimeout(() => {
                this.tracking_message_rm = null;
              }, 3000);
            }
          },
          e => {
            console.log(e);
            this.tracking_message_rm = e.statusText;
            setTimeout(() => {
              this.tracking_message_rm = null;
            }, 3000);
          }
        );
    }
  }

  checkOrder(value) {
    this.checkMessage = null;
    if (value.length > 7) {
      this.shelfService.checkOrder({'tracking_code': value}).subscribe(
        r => {
          if (r.status === 'ok') {
            this.checkMessage = r.message;
          } else {
            this.message = r.message;
            setTimeout(() => {
              this.message = null;
            }, 3000);
          }
        },
        e => {
          console.log(e);
        }
      );
    }
  }

  toggleSortClass(className) {
    if (className == this.activeSort) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }
    this.activeSort = className;
  }

  filterShelfBoxes (filter) {
    this.activeFilter = filter;
  }
  scroll(el: HTMLElement) {
    setTimeout(() => el.scrollIntoView({ behavior: "smooth"  }), 300);
  }
}
