import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


const host = environment.API_ENDPOINT_ROOT;

@Injectable({
    providedIn: 'root'
})
export class EditOrdersService {

    constructor(private httpClient: HttpClient) {
    }

    public searchTracking(trackingCode) {
        return this.httpClient.get<any>(host + `/admin/action/${trackingCode}`);
    }

    public editOrder(data) {
        return this.httpClient.post<any>(host + '/admin/action/update', data);
    }

    public deleteOrder(id) {
        return this.httpClient.get<any>(host + `/admin/action/delete/${id}`);
    }

}
