import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from "@angular/core";

@Component({
  selector: "app-custom-autocomplete",
  templateUrl: "./custom-autocomplete.component.html",
  styleUrls: ["./custom-autocomplete.component.css"],
})
export class CustomAutocompleteComponent {
  @Input() items: any[] = [];
  @Input() selectedItems: any[] = [];
  filteredItems: any[] = [];
  searchText = "";
  showDropdown = false;
  focusedItemIndex: number | null = null;
  @Output() categoriesSelected = new EventEmitter<any[]>();

  propagateChange = (_: any) => {};

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedItems = value;
      this.filteredItems = this.items.filter((item) => !this.isSelected(item));
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.filteredItems = this.items;
  }

  toggleItem(item: any) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
      this.filteredItems = this.filteredItems.filter((i) => i !== item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
      this.filteredItems.push(item);
    }

    this.searchText = "";
    this.categoriesSelected.emit(this.selectedItems);
  }

  removeItem(item: any) {
    this.selectedItems = this.selectedItems.filter((i) => i !== item);
    this.filteredItems.push(item);
    this.propagateChange(this.selectedItems);
    this.categoriesSelected.emit(this.selectedItems);
  }

  filterItems() {
    this.filteredItems = this.items.filter(
      (item) =>
        !this.isSelected(item) &&
        item.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  isSelected(item: any): boolean {
    return this.selectedItems.some((i) => i === item);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      // Handle Enter key
      if (
        this.focusedItemIndex !== null &&
        this.filteredItems.length > this.focusedItemIndex
      ) {
        const focusedItem = this.filteredItems[this.focusedItemIndex];
        this.toggleItem(focusedItem);
      }
    } else if (event.key === "ArrowDown") {
      // Handle Arrow Down key
      if (this.focusedItemIndex === null) {
        this.focusedItemIndex = 0;
      } else if (this.focusedItemIndex < this.filteredItems.length - 1) {
        this.focusedItemIndex++;
      }

      this.scrollIntoView();
    } else if (event.key === "ArrowUp") {
      // Handle Arrow Up key
      if (this.focusedItemIndex === null) {
        this.focusedItemIndex = this.filteredItems.length - 1;
      } else if (this.focusedItemIndex > 0) {
        this.focusedItemIndex--;
      }

      this.scrollIntoView();
    }
  }

  private scrollIntoView(): void {
    if (
      this.focusedItemIndex !== null &&
      this.filteredItems.length > this.focusedItemIndex
    ) {
      const focusedElement = this.el.nativeElement.querySelector(
        `.dropdown-item:nth-child(${this.focusedItemIndex + 1})`
      );

      if (focusedElement) {
        focusedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }
}
