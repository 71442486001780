import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_services';
import { Event, NavigationStart, NavigationEnd, Router} from '@angular/router';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
    selector: 'app-packed',
    templateUrl: './packed.component.html',
    styleUrls: ['./packed.component.css']
})
export class PackedComponent implements OnInit {
    orders:any = [];
    showLoading = true;
    orderfromOrdersgroup = [];
    ogtracking;
    ponyTracking;
    checked = [];
    scaned_orders = [];
    ordersid = [];
    p;


    constructor(private _userService: UserService, private _router: Router) {
        this.showLoading = false;


    }

    ngOnInit(){
        this._userService.getOrderGroup().subscribe(
            data => {
                for (var i = 0; i < data.orders.orderGroups.length; i++){
                    if(data.orders.orderGroups[i].pony_status == 2){
                        data.orders.orderGroups[i].box_count = 0;
                        for(let k = 0; k < data.orders.orderGroups[i].order.length; k++){
                            if(data.orders.orderGroups[i].order[k].box_id != null){
                                data.orders.orderGroups[i].box_count++;
                            }
                        }
                        this.orders.push(data.orders.orderGroups[i]);
                    }
                }
            },
            error => console.log(error)
        )
        this._userService.getAllOrders().subscribe(
            data => {
                this.scaned_orders = data.orders;
                // for (let i = 1; i <= data.orders.lenght; i++) {
                //     this.scaned_orders.push(`${i}`);
                // }
                // this.showLoading = false;
            },
            error => {
                console.log(error)
                // this.showLoading = false;
            }
        )
    }

    get_orders(ordergroup,ogtracking){


        this.showLoading = true;
        this.checked = ordergroup;

        this._userService.getorders(ordergroup).subscribe(
            data => {
                this.ogtracking = ogtracking ;
                this.orderfromOrdersgroup = data.orders.orders;
                this.showLoading = false;
            },
            error => console.log(error)
        )
    }
    updateCheckedOptions(event, order){
        this.ordersid.push(order);
        if(event.target.checked){
            this.checked.push(event.target.value);
            console.log(this.checked);
        }else{
            for (var i = 0; i < this.checked.length; i++) {
                if(event.target.value == this.checked[i]) {

                    this.checked.splice(i, 1);
                }
            }
            return this.checked;
        }

    }
    sent(){
        this.showLoading = true;
        this._userService.change_pony_status_tosent(this.checked).subscribe(
            data => {
                for(let i = 0; i < this.orders.length; i++){
                    for(let j = 0; j < this.ordersid.length; j++){
                        if(this.orders[i] == this.ordersid[j] ){
                            this.orders.splice(i,1);
                        }
                    }
                }
                this.showLoading = false;
            },
            error => console.log(error)
        );
    }


}