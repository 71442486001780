import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

const host = environment.API_ENDPOINT_ROOT;

@Injectable({
    providedIn: 'root'
})

export class RussianParcelsService {

    constructor(private httpClient: HttpClient) {
    }

    // users Routing

    getRuParcels() {

        return this.httpClient.get<any>(host + '/ru-parcels');
    }

    getRuOrders(id) {

        return this.httpClient.get<any>(host + `/parcels/${id}/orders`);
    }

    searchRuOrders(data) {
        return this.httpClient.post(host + `/orders/scan-ru`, data);
    }

    searchRuOrderGroup(data) {

        return this.httpClient.post(host + `/orders/activated-from-ru`, data);
    }

    getRuOrdersGroup() {

        return this.httpClient.get(host + `/ru-orderGroups`);
    }

    getRuParcelWithTracking(tracking) {
        console.log(tracking);
        return this.httpClient.get(host + `/orders/${tracking}/orderGroup`);
    }

    getRuOrder(orderGroupTrackingCode) {

        return this.httpClient.get(host + `/groups/${orderGroupTrackingCode}/orders`);
    }

    doneOrderGroupTracking(order_group_tracking: string) {

        return this.httpClient.post(host + `/groups-ready`, {order_group_tracking_code: order_group_tracking});
    }

    readyToSend() {
        return this.httpClient.get(host + `/ready-to-send`);
    }

    readyForHome(order_group_tracking) {
        return this.httpClient.post(host + `/orders/in-home-way`, {order_group_tracking_code: order_group_tracking});
    }

    export() {
        return this.httpClient.get(host + `/in-home-way`);
    }

    getCourier() {
        return this.httpClient.get(host + '/delivery-companies');
    }

    changeCourier(id, order_group_tracking) {
        return this.httpClient.post(host + `/fix-delivery-company`, {
            order_group_tracking_code: order_group_tracking,
            delivery_company_id: id
        });
    }

    updateTrackingGroupVWeight(data) {
        return this.httpClient.post(host + `/orders/update-order-group-dimensions`, data);
    }


}
