import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditOrdersService} from '../../_services/edit-orders.service';

@Component({
    selector: 'app-modal-edit',
    templateUrl: 'modal-edit.component.html',
    styleUrls: ['modal-edit.component.scss']
})

export class ModalEditOrdersComponent implements OnInit, OnDestroy {
    public showLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: any,
        public dialogRef: MatDialogRef<ModalEditOrdersComponent>,
        private _delete: EditOrdersService,
    ) {
    }

    confirm(): void {
        if (this.id) {
            this.showLoading = true;
            this._delete.deleteOrder(this.id).subscribe((data: any) => {
                this.dialogRef.close({doneORno: 'Yes'});
                this.showLoading = false;
            });
        }
    }

    cancel(): void {
        this.dialogRef.close({doneORno: 'No'});
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
