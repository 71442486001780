import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {TrackingReportService} from '../_services/tracking-report.service';

export interface TrackingCode {
    tracking_code: string;
}

@Component({
    selector: 'app-tracking-report',
    templateUrl: './tracking-report.component.html',
    styleUrls: ['./tracking-report.component.css']
})
export class TrackingReportComponent implements OnInit {

    @ViewChild('tackInput') public tackInput: any;
    @ViewChild('printButton') public printButton: any;
    error = false;
    message;
    role;
    warehouse = [];
    result = [];
    showLoading = false;
    public trackingCode: string;
    public top = 'top';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public sendTackInput: TrackingCode;
    public sendTackMessage = null;
    public userFullName: string | undefined;
    public disabledBtn = true;
    destinations = [
        {id: '', val: 'All'},
        {id: 1, val: 'Armenia'},
        {id: 2, val: 'Russia'},
        {id: 4, val: 'Amanati'},
        {id: 5, val: 'Ukraine'},
        {id: 6, val: 'SpaceCargo'},
        {id: 7, val: 'Moldova'}
    ];
    statuses = ['All', 'Scan', 'Repack', 'User Set'];
    stats = [{id: '', val: 'All'}, {id: 1, val: 'Scan'}, {id: 2, val: 'Repack'}, {id: 4, val: 'User Set'}];
    reportForm = this.fb.group({
        parcel: [''],
        warehouse: [''],
        order: this.fb.group({
            tracking_code: [''],
            services: [false],
            created_at: [''],
            belongs: [''],
            client_fullname: [''],
            status: [''],
        }),
    });

    constructor(private trackingReport: TrackingReportService, private fb: FormBuilder) {
        this.role = localStorage.getItem('role');
    }

    ngOnInit() {
        this.index(this.role);
    }

    index(role) {
        this.trackingReport.index({role: role}).subscribe(
            d => {
                if (d.status == 200) {
                    this.warehouse = d.warehouse;
                    if (d.warehouse.length == 1) {
                        this.reportForm.controls['warehouse'].setValue(d.warehouse[0].id);
                    } else {
                        this.reportForm.controls['warehouse'].setValue(d.wh_id);
                    }
                }
            }, e => {
                console.log(e);
            }
        );
    }

    search() {
        this.showLoading = true;
        for (let prop in this.reportForm.value) {
            if (this.reportForm.value.hasOwnProperty(prop)) {
                if (this.reportForm.value[prop] === '') {
                    // console.log(`${prop} --> ${this.reportForm.value[prop]}`);
                    delete this.reportForm.value[`${prop}`];
                }
            }
        }
        for (let prop in this.reportForm.value.order) {
            if (this.reportForm.value.order.hasOwnProperty(prop)) {
                if (this.reportForm.value.order[`${prop}`] === ''
                    || this.reportForm.value.order[`${prop}`] == false) {
                    // console.log(`${prop} --> ${this.reportForm.value.order[prop]}`, Object.keys(this.reportForm.value.order[prop]).length);
                    delete this.reportForm.value.order[`${prop}`];
                }
            }
        }
        console.log('searching for: ', this.reportForm.value);
        this.trackingReport.search(this.reportForm.value).subscribe(
            d => {
                console.log(d);
                if (d.status == 200) {
                    this.result = d.data;
                } else {
                    this.result = [];
                    console.log('error status: ', d);
                    this.error = true;
                    this.message = d.message;
                    setTimeout(() => {
                        this.error = false;
                        this.message = null;
                    }, 4000);
                }
                this.showLoading = false;
            }, e => {
                console.log(e);
                this.showLoading = false;
                this.error = true;
                this.message = e.errorMessage;
                setTimeout(() => {
                    this.error = false;
                    this.message = null;
                }, 4000);
            }
        );
    }

    public onEnter(e) {
        e.preventDefault();
        this.sendTackMessage = null;
        if (this.tackInput.nativeElement.value !== '') {
            this.sendTackInput = {
                tracking_code: ''
            };

            this.sendTackInput.tracking_code = this.tackInput.nativeElement.value;
            this.trackingCode = this.tackInput.nativeElement.value;
            this.trackingReport.chekInputTrack(this.sendTackInput).subscribe(data => {
                if (data.status === 200) {
                    if (data.order) {
                        if (data.order.dispatch === '2') {
                            this.userFullName = `${data.order.client_fullname.split(' ')[0]} ${data.order.client_fullname.split(' ')[1]} ${data.order.client_fullname.split(' ')[2].replace('ARM', 'MT')} `;
                        } else {
                            this.userFullName = data.order.client_fullname;
                        }
                        this.disabledBtn = false;
                        setTimeout(() => {
                            this.printButton.nativeElement.click();
                        }, 0);
                    }
                    if (data.message) {
                        this.disabledBtn = true;
                        this.sendTackMessage = data.message;
                    }
                }

            });
        }
    }

    public focusOut(e) {
        console.log(e.target.value);
        if (e.target.value !== '') {
            this.disabledBtn = false;
            setTimeout(() => {
                this.printButton.nativeElement.focus();
            }, 0);
        } else {
            this.disabledBtn = true;
        }
    }
}
