import {Component} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'page-not-found',
    template: '<h2>page not found</h2>',
    styles: ['h2 {text-align: center; margin-top: 50px}']
})

export class PageNotFoundComponent {

}
