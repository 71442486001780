import { Component, OnDestroy, OnInit } from "@angular/core";
import { TrackingReportService } from "../_services/tracking-report.service";
import { MatDialog } from "@angular/material";
import { ModalInventoriesComponent } from "./modalForInventories/modal-inventories.component";

@Component({
  selector: "app-inventories",
  templateUrl: "./inventories.component.html",
  styleUrls: ["./inventories.component.scss"],
})
export class InventoriesComponent implements OnInit, OnDestroy {
  public orders: any[] = [];
  public showLoading = false;
  public trackingCode = "";
  public trackingData: any;
  public message: string;
  public dd = false;
  public count: number;

  constructor(
    private _inventories: TrackingReportService,
    private _search: TrackingReportService,
    private _refresh: TrackingReportService,
    public dialog: MatDialog
  ) {}

  handleValue(e) {
    this.trackingCode = e.value;
  }

  enter() {
    if (this.trackingCode) {
      this.showLoading = true;
      this._search.searchTracking(this.trackingCode).subscribe((data) => {
        this.showLoading = false;
        this.trackingData = data;

        if (data.success === true) {
          this.orders.push(this.trackingData.data);
          this.count = this.orders.length;
          this.message = "Order Find";
          this.trackingCode = "";
          this.showLoading = false;
        } else {
          this.message = this.trackingData.message;
          this.trackingCode = "";
          this.showLoading = false;
        }
        setTimeout(() => {
          this.message = null;
        }, 1500);
      });
    } else {
      this.showLoading = false;
    }
  }

  refresh() {
    if (this.orders.length) {
      const dialogRef = this.dialog.open(ModalInventoriesComponent, {
        width: "500px",
        panelClass: "custom-dialog-container",
        data: this.orders,
      });
      dialogRef.afterClosed().subscribe((data) => {
        this.message = data.doneORno;
        this.dd = true;
        if (data.doneORno === "Yes") {
          this.count = this.orders.length;
          this.dd = false;
        } else {
          this.dd = false;
        }
        setTimeout(() => {
          this.message = null;
        }, 1500);
      });
    } else {
      return;
    }
    this.trackingCode = "";
  }

  exportOrders() {
    window.open(`https://warehouseback.onex.am/inventories`, "_blank");
  }

  // if (this.orders.length) {
  //     this.showLoading = true;
  //     this._refresh.refreshTrackingPage().subscribe((data: any) => {
  //         console.log(data);
  //         this.trackingData = data;
  //         this.orders = this.trackingData.data;
  //         this.message = this.trackingData.message;
  //         this.showLoading = false;
  //         setTimeout(() => {
  //             this.message = null;
  //         }, 1500);
  //     });
  // } else {
  //     return;
  // }

  ngOnInit() {
    this.showLoading = true;
    this._inventories.getTrackings().subscribe((data: any) => {
      this.orders = data.data;
      this.showLoading = false;
      this.count = this.orders.length;
    });
  }

  ngOnDestroy() {}
}
