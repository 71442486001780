import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {AuthenticationService, UserService} from '../_services';


@Injectable()
export class AuthGuard implements CanActivate {

    // constructor(private _router: Router, private _authService: AuthenticationService) {
    // }
    //
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //     let currentUser = localStorage.getItem('currentUser');
    //     // this.checkToken();
    //     if (currentUser != null) {
    //         return true;
    //     }
    //     // error when verify so redirect to login page with the return url
    //     this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    //     return false;
    //
    //     // return this._userService.getAll().map(
    //     //     data => {
    //     //         if (data[0].role === "admin" && currentUser != null  ) {
    //     //             // logged in so return true
    //     //             return true;
    //     //         }
    //     //         // error when verify so redirect to login page with the return url
    //     //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //     //         return false;
    //     //     },
    //     //     error => {
    //     //         // error when verify so redirect to login page with the return url
    //     //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //     //         return false;
    //     //     });
    // }

    constructor(private _router: Router, private _auth: AuthenticationService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        let url: string = state.url;

        return this.checkLogin(url, state);
    }

    checkLogin(url: string, state: RouterStateSnapshot): boolean {
        this._auth.checkLogin();
        if (this._auth.isLoggedIn) { return true; }

        // Store the attempted URL for redirecting
        this._auth.redirectUrl = url;

        // Navigate to the login page with extras
        this._router.navigate(['/login'], {queryParams: { returnUrl: state.url } });
        return false;
    }

}


