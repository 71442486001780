import {Component, OnInit} from '@angular/core';
import {UserService} from '../../_services';
import {Router} from '@angular/router';

export interface UpdatedBox {
    weight: string | null;
    width: string | null;
    length: string | null;
    height: string | null;
    v_weight: string | null;
    is_brand: boolean;
    is_export: boolean;
    is_company: boolean;
}

@Component({
    selector: 'app-box-edit',
    templateUrl: 'box-edit.component.html',
    styleUrls: ['box-edit.component.scss']
})

export class AppBoxEditComponent implements OnInit {
    boxes = [];
    box: any = {};
    pahests: any;
    parcels = [];
    public message = null;
    superadmin = false;
    activeFilter = 'all';
    public allSelected = false;
    public selected = [];
    public updatedBox: UpdatedBox = {
        weight: null,
        width: null,
        length: null,
        height: null,
        v_weight: null,
        is_brand: false,
        is_export: false,
        is_company: false,
    };

    constructor(private _userService: UserService, private router: Router) {
    }

    ngOnInit() {
        this._userService.getBox().subscribe(
            data => {
                this.boxes = data.boxes;
            },
            error => console.log(error)
        );
        this._userService.getPahest().subscribe(
            data => {
                this.pahests = data.pahests;
            },
            error => console.log(error)
        );
        this._userService.getParcel().subscribe(
            data => {
                this.parcels = data.parcels;
                this.parcels = this.parcels.filter(item => item.status == 1);
            },
            error => console.log(error)
        );
    }

    getboxid(boxId) {
        this.box.id = boxId;
        this._userService.getByIdBox(boxId).subscribe(
            data => {
                this.box = data.box;
                this.box.with = data.box.width;
                this.box.lenght = data.box.length;
                this.box.height = data.box.height;
                this.box.total = data.box.v_waight;
            },
            error => console.log(error)
        );

        this._userService.getParcel().subscribe(
            data => {
                this.parcels = data.parcels;
            },
            error => console.log(error)
        );
    }

    editboxid() {
        this._userService.editBox(this.box).subscribe(
            data => {
                console.log(data);
            },
            error => console.log(error)
        );
    }

    getOrderInfo(trackingPrefix) {
        this._userService.boxTrackingPrefix = trackingPrefix;
        this.router.navigate(['boxing']);
    }

    filter(filter) {
        this.activeFilter = filter;
    }


    public isAllSelected(e, i) {
        if (e.target.checked) {
            this.selected.push(e.target.value);
        } else {
            const index = this.selected.indexOf(e.target.value);
            if (index > -1) {
                this.selected.splice(index, 1);
            }
        }
    }

    public selectAll() {
        if (this.allSelected) {
            for (let i = 0; i < this.boxes.length; i++) {
                this.boxes[i].selected = this.allSelected;
                if (this.selected.some(data => data != this.boxes[i].id) || !this.selected.length) {
                    this.selected.push(this.boxes[i].id);
                }
            }
        } else {
            for (let i = 0; i < this.boxes.length; i++) {
                this.boxes[i].selected = this.allSelected;
                this.selected = [];
            }
        }

    }

    public setPreBox(box, e, type) {
        box.type = e.checked;
    }

    public updateBox(box) {
        this._userService.editBox(box).subscribe(
            data => {
                if (data.status === 200) {
                    this.router.navigate(['/edit-boxes']);
                }
                this.message = data.message;
                setTimeout(() => {
                    this.message = null;
                }, 1500);
            },
            error => console.log(error)
        );
        // this.updatedBox
        // if (this.selected.length) {
        //     console.log(this.selected);
        // } else {
        //     console.log(box.height);
        // }
        // console.log(this.updatedBox);
    }

    public calcVWeight(box) {
        box.v_waight = (+((box.width ? +box.width : 1) * (box.length ? +box.length : 1) * (box.height ? +box.height : 1)) / (this.pahests.id === 4 ? 6000 : 5000)).toFixed(3);
    }
}
