import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/_services';
import {Router} from '@angular/router';


@Component({
  selector: 'app-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss']
})
export class BoxesComponent implements OnInit {

  boxes = [];
  box: any = {};
  pahests = [];
  parcels = [];
  superadmin = false;
  activeFilter = 'all';
  constructor(private _userService: UserService, private router: Router) {
  }

  ngOnInit() {
    this._userService.getBox().subscribe(
      data => {
        this.boxes = data.boxes;
      },
      error => console.log(error)
    );
    this._userService.getPahest().subscribe(
      data => {
        this.pahests = data.pahests;
      },
      error => console.log(error)
    );
  }

  getboxid(boxId) {
    this.box.id = boxId;
    this._userService.getByIdBox(boxId).subscribe(
      data => {
        this.box = data.box;
        this.box.with = data.box.width;
        this.box.lenght = data.box.length;
        this.box.height = data.box.height;
        this.box.total = data.box.v_waight;
      },
      error => console.log(error)
    );

    this._userService.getParcel().subscribe(
      data => {
        this.parcels = data.parcels;
      },
      error => console.log(error)
    );
  }

  editboxid() {
    this._userService.editBox(this.box).subscribe(
      data => {
        console.log(data);
      },
      error => console.log(error)
    );
  }

  getOrderInfo(trackingPrefix) {
    this._userService.boxTrackingPrefix = trackingPrefix;
    this.router.navigate(['boxing']);
    localStorage.setItem('data', JSON.stringify(this._userService.boxTrackingPrefix));
  }

  filter(filter) {
    this.activeFilter = filter;
  }
}
