import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs/Observable';
import {AuthenticationService} from '../_services';


@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private _router: Router, private _auth: AuthenticationService) {
    }

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //     let currentUser = localStorage.getItem('currentUser');
    //     if (currentUser == null) {
    //         return true;
    //     }
    //     // error when verify so redirect to login page with the return url
    //     this._router.navigate(['/dashboard'], {queryParams: {returnUrl: state.url}});
    //     return false;
    // }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        let url: string = state.url;
        return this.checkLogin(url, state);
    }

    checkLogin(url: string, state: RouterStateSnapshot): boolean {
        // console.log(url);
        this._auth.checkLogin();
        if (!this._auth.isLoggedIn) { return true; }

        // Store the attempted URL for redirecting
        this._auth.redirectUrl = url;

        // Navigate to the login page with extras
        this._router.navigate(['/'], {queryParams: {returnUrl: state.url}});
        return false;
    }

}


