import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'user-set-modal',
    templateUrl: 'user-set-modal.component.html',
    styleUrls: ['user-set-modal.component.scss']
})

export class UserSetModalComponent implements OnInit, OnDestroy {
    public showLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<UserSetModalComponent>,
    ) {
    }

    ngOnInit() {
        console.log(this.data);
    }

    public doneModal() {
        this.dialogRef.close({doneORno: 'Yes'});

    }

    public closeModal() {
        this.dialogRef.close({doneORno: 'no'});
    }

    ngOnDestroy() {
    }
}
