import {Component, OnInit} from '@angular/core';
import {TrackingReportService} from '../../_services/tracking-report.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-extra-orders',
    templateUrl: './extra-orders.component.html',
    styleUrls: ['./extra-orders.component.scss'],
})


export class ExtraOrdersComponent implements OnInit {
    public extraOrders;
    public adminsReport: FormGroup;
    public users;
    public modalImage;
    public extraOrdersTable = [
        'Tracking Code',
        'Status',
        'Comment',
        'Created Date',
        'Updated Date',
        'Admin Id',
    ];

    public stats = [
        {id: 1, val: 'waiting'},
        {id: 2, val: 'skip'},
        {id: 3, val: 'reject'},
        {id: 4, val: 'success'},
        // {id: 5, val: 'Box'},
    ];
    public notes = [
        {id: 1, val: 'letters'},
        {id: 2, val: 'numbers'},
        {id: 3, val: 'photo'},
        {id: 5, val: 'label'},
        {id: 6, val: 'no code'},
        {id: 7, val: 'other'},
        // {id: 5, val: 'Box'},
    ];

    public params = {
        status: '',
        comment: '',
        from_datetime: '',
        to_datetime: '',
        admin_id: ''

    };

    constructor(private teamReport: TrackingReportService, private formBuilder: FormBuilder) {
        this.adminsReport = this.setControls();
    }

    handleStatus(status) {
        this.params.status = status.value;
        if (status.value === 'all') {
            this.params.status = '';
        } else {
            this.params.status = status.value;
        }
        this.teamReport.getExtraOrders(this.params).subscribe((newData) => {
            this.extraOrders = newData.orders;
        });
    }

    handleComment(comment) {
        this.params.comment = comment.value;
        if (comment.value === 'all') {
            this.params.comment = '';
        } else {
            this.params.comment = comment.value;
        }
        this.teamReport.getExtraOrders(this.params).subscribe((newData) => {
            this.extraOrders = newData.orders;
        });
    }

    handleUser(user) {
        this.params.admin_id = user.value;
        if (user.value === 'all') {
            this.params.admin_id = '';
        } else {
            this.params.admin_id = user.value;
        }
        this.teamReport.getExtraOrders(this.params).subscribe((newData) => {
            this.extraOrders = newData.orders;
        });
    }

    handleStartDate(start) {
        this.params.from_datetime = new Date(start).toLocaleString('sv-SE');
        this.teamReport.getExtraOrders(this.params).subscribe((newData) => {
            this.extraOrders = newData.orders;
        });
    }

    handleEndDate(end) {
        this.params.to_datetime = new Date(end).toLocaleString('sv-SE');
        this.teamReport.getExtraOrders(this.params).subscribe((newData) => {
            this.extraOrders = newData.orders;
        });
    }

    openImgModal(src) {
        this.modalImage = src;
    }

    ngOnInit() {
        this.teamReport.getExtraOrders(this.params).subscribe((newData) => {
            this.extraOrders = newData.orders;
        });

        this.teamReport.getUsers().subscribe((usersData) => {
            this.users = usersData.users;
        });
    }

    private setControls(): FormGroup {
        return this.formBuilder.group({
            workDate: this.formBuilder.group({
                start: [null],
                end: [null],
            }),
        });
    }
}
