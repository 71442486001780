import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/_services';

@Component({
    selector: 'app-create-order',
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {

    order: any = {};
    pahests = [];
    user: boolean;

    constructor(private _userService: UserService) {
    }

    ngOnInit() {
        this._userService.getPahest().subscribe(
            data => {
                this.pahests = data.pahests;
            },
            error => console.log(error)
        );
        this.user = (localStorage.getItem('role') == 'super_admin') ? true : false;

    }

    creatorder() {

        this._userService.createOrder(this.order).subscribe(
            data => {
                console.log(data);
            },
            error => console.log(error)
        );
    }

    generateTrack() {
        this._userService.getTracking().subscribe(
            data => {
                this.order.tracking_code = data.tracking_code;
            },
            error => console.log(error)
        );

    }

}
