import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderStatsTotalWeightLength'
})
export class OrderStatsTotalWeightLengthPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (args) {
            case 'special':
                value = value.filter(item => item.activated == '2');
                value = calculateAndCutDecimals(value);
                break;
            case 'normal':
                value = value.filter(item => item.activated == '1');
                value = calculateAndCutDecimals(value);
                break;
            default:
                value = calculateAndCutDecimals(value);
        }

        function calculateAndCutDecimals(arrayGiven) {
            if (arrayGiven.length > 0) {
                let totalWeight: any = 0;
                arrayGiven.map((item) => {
                    totalWeight = (totalWeight * 1000 + parseFloat(item.waight) * 1000) / 1000;
                });
                totalWeight = totalWeight.toString().split('.');
                totalWeight[1] = totalWeight[1].length > 3 ? totalWeight[1].slice(0, 2) : totalWeight[1];
                totalWeight = totalWeight.join('.');
                return `Total Items:&nbsp;<b>${arrayGiven.length}</b>&nbsp;Total Weight:&nbsp;<b>${totalWeight}</b>`;
            }
        }

        return value;
    }

}
