import {Component, OnInit} from '@angular/core';
import {UserService} from '../_services';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {

  selectedFile: File = null;
  pahests = [];
  createModel: any = {};
  closeResult: string;

  constructor(private _userService: UserService) {}

  ngOnInit() {
    this._userService.getPahest().subscribe(
      data => {
        this.pahests = data.pahests;
      },
      error => console.log(error)
    );
  }

  eventfile(event) {
    this.selectedFile = event.target.files[0];
  }

  createWerehouse() {

    let fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('box_prefix', this.createModel.box_prefix);
    fd.append('country', this.createModel.country);
    fd.append('dispatch', this.createModel.dispatch);
    fd.append('name', this.createModel.name);
    fd.append('tracking_prefix', this.createModel.tracking_prefix);

    this._userService.createWerehouse(fd).subscribe(
      data => {
        console.log(data);
      },
      error => console.log(error)
    );

  }

  werhouseId(id) {
    this.createModel.id = id;

  }

  updateWerehouse() {
    let fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('box_prefix', this.createModel.box_prefix);
    fd.append('country', this.createModel.country);
    fd.append('dispatch', this.createModel.dispatch);
    fd.append('name', this.createModel.name);
    fd.append('tracking_prefix', this.createModel.tracking_prefix);

    this._userService.editwherehouse(this.createModel.id, fd).subscribe(
      data => {
        console.log(data);
      },
      error => console.log(error)
    );
  }
}
