import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'declared'
})
export class DeclaredPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!Array.isArray(value)) {
      return;
    }
    switch (args) {
      case 'empty':
        value = value.filter(item => item.declarations.length == 0);
        break;
      case 'hasItem':
        value = value.filter(item => item.declarations.length !== 0);
        break;
    }
    return value;
  }

}
