import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {Iservice} from 'src/app/_services/iservice.service';
import {ImagesDate} from '../../_models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-selider-action-modal',
    templateUrl: 'slider-action.component.html',
    styleUrls: ['slider-action.component.scss']
})

export class SliderActionModal implements OnInit, OnDestroy {
    @Input() approvedImages;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    public showNavigationIndicators = false;
    public show = true;
    public imgSroures: any;
    public loading = false;
    constructor(
        config: NgbCarouselConfig,
        // public activeModal: NgbActiveModal,
        public dialogRef: MatDialogRef<SliderActionModal>,
        public iService: Iservice,
        public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        // config.showNavigationIndicators = false;
    }

    ngOnInit() {
        this.getImg();
    }

    getImg() {
        this.iService.getImages(this.data.dataKey).subscribe((data: ImagesDate) => {
            this.imgSroures = JSON.parse(data.data.images);
            this.loading = true;
            console.log(this.imgSroures, `[GET IMAGES]`);
        });
    }

    public delete(sendSrc) {
        this.imgSroures = this.iService.array.filter(src => src != this.iService.srcThisImg);
        this.show = false;
        this.imgSroures = this.iService.array.filter((src) => src != sendSrc);
        if (this.iService.array.length <= 0) {
            this.dialogRef.close(this.approvedImages);
            this.passEntry.emit(this.approvedImages);
            this.dialogRef.close();
        }
    }

    ngOnDestroy() {
        // this.iService.array = [];
        this.imgSroures = [];
        console.log(this.imgSroures, `[ngOnDestroy]`);
    }
}
