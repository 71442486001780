import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {UserService} from 'src/app/_services';


@Component({
    selector: 'app-user-set-extra',
    templateUrl: './user-set-extra.component.html',
    styleUrls: ['./user-set-extra.component.scss'],
})


export class UserSetExtraComponent implements OnInit {
    @ViewChild('user_code') userCodeInput: any;
    @ViewChild('dItem') dItem: any;
    @ViewChild('comment') comment: any;
    @ViewChild('f') form: ElementRef;
    @ViewChild('save') saveBtn: any;
    public user;
    order: any = {};
    autoScan = false;
    public loading = false;
    arm_clients = [];
    ru_clients = [];
    ga_clients = [];
    ge_amanati = [];
    ua_clients = [];
    ge_space_cargo_clients = [];
    public success = 0;
    commentDisabled: boolean;
    disabled = false;
    public disableBtn = true;
    user_id;
    data;
    public message = '';
    public scannedStatus: boolean;
    fullNameForLabel;
    public str = '';
    public defaultService: string;
    public status = 'waiting';
    public activeFilter = this.status;
    public userOrder;
    function;
    public user_order_img;


    public user_codes = [
        {id: '', val: 'ARM'},
        {id: 1, val: 'UAM'},
        {id: 2, val: 'GA'},
        {id: 3, val: 'UGA'},
        {id: 4, val: 'GE'},
        {id: 5, val: 'UGE'},
        {id: 6, val: 'SC'},
        {id: 7, val: 'RU'},
    ];

    public stats = [];


    public issues = [
        {id: '', val: 'Select issue'},
        {id: 1, val: 'Letters'},
        {id: 2, val: 'Numbers'},
        {id: 3, val: 'Photo'},
        {id: 4, val: 'Label'},
        {id: 5, val: 'No code'},
        {id: 6, val: 'Other'},

    ];

    public jsonString;
    public jsonObject;
    public jsonWidth;
    public jsonHeight;
    public jsonLength;
    public jsonWeight;
    public us_code_value = null;


    constructor(private _userService: UserService, private cdr: ChangeDetectorRef) {
        this.order.user_code = 'ARM';
        this.order.code = 'ARM';
    }

    userSetExtra(value) {
        this._userService.extraUserSet(value).subscribe(
            (data: any) => {
                if (data.status === 200 && data.order) {
                    this.stats = data.count;
                    this.userOrder = data.order;
                    this.status = data.order.status;
                    this.activeFilter = this.status;
                    this.jsonString = data.order.dws_request;
                    this.user_order_img = data.order.image_path;
                    this.jsonObject = JSON.parse(this.jsonString);
                    // this.activeFilter = data.order.status;
                    this.jsonWidth = this.jsonObject.width;
                    this.jsonHeight = this.jsonObject.height;
                    this.jsonLength = this.jsonObject.length;
                    this.jsonWeight = this.jsonObject.weight;
                    this.imageZoom('myimage', 'myresult');
                    this._userService.checkOrder({tracking_code: data.order.tracking_code, auto_scan: true}).subscribe(
                        (newData) => {
                        });
                }
            });

    }

    ngOnInit() {
        this.disabled = true;
        this.user = localStorage.getItem('detectUser');
        this.order.dispatch = this.user == 9 ? 1 : 0;
        if (this.user == 9) {
            this.order.user_code = 'GD';
        }
        this.userSetExtra(this.status);
        if (this.userOrder.length) {
            return;
        } else {
            this.status = 'skip';
            this.userSetExtra('skip');
        }
    }

    handleChange(userCodeValue) {
        this.us_code_value = userCodeValue.value;
    }

    filter(filter) {
        this.activeFilter = filter;
        this.userSetExtra(filter);
    }

    changeFilter(filter) {
        this.activeFilter = filter;
        this.userSetExtra(filter);
    }

    @HostListener('keydown.tab', ['$event'])
    onTab(event: KeyboardEvent) {
        if (event.target == this.userCodeInput.nativeElement) {
            event.preventDefault();
            return false;
        }
        if (event.target == this.comment.nativeElement) {
            if (this.comment.nativeElement.value === ' ' || !this.comment.nativeElement.value) {
                this.commentDisabled = true;
                this.saveBtn.nativeElement.focus();
                this.saveBtn.nativeElement.select();
                event.preventDefault();
                return false;
            } else {
                this.commentDisabled = true;
                this.saveBtn.nativeElement.focus();
                this.saveBtn.nativeElement.select();
                event.preventDefault();
                return false;
            }
        }
    }

    @HostListener('keyup.arrowDown', ['$event'])
    onArrowDown(event: KeyboardEvent) {
        if (event.target == this.userCodeInput.nativeElement) {
            if (
                this.arm_clients.length > 0 ||
                this.ru_clients.length > 0 ||
                this.ga_clients.length > 0 ||
                this.ge_amanati.length > 0 ||
                this.ua_clients.length > 0 ||
                this.ge_space_cargo_clients.length > 0
            ) {
                this.dItem.nativeElement
                    .getElementsByClassName('dropdown-item')[0]
                    .focus();
                event.preventDefault();
                return false;
            }
        }
    }

    userSet(f) {
        f.value.user_id = this.userOrder.id;
        f.value.waight = this.jsonWeight;
        f.value.tracking_code = this.userOrder.tracking_code;
        f.value.image_path = this.user_order_img;
        if (
            (f.valid && f.value.user_code.length > 5) ||
            f.value.code == 'SC' ||
            f.value.code == 'USC'
        ) {
            this.loading = true;
            this._userService.userSet(f.value).subscribe(
                (data) => {
                    this.loading = false;
                    this.message = data.message;
                    setTimeout(() => {
                        this.message = null;
                    }, 1500);
                    this.data = data;
                    if (this.data.status == 200) {
                        this.success = 1;
                        const statusData = {
                            id: this.userOrder.id, status: 'success', note: this.order.status
                        };
                        this._userService.changeStatus(statusData).subscribe((status: any) => {
                            if (status.status === 200) {
                                this.userSetExtra(this.status);

                            }
                        });

                        // this.message = this.data.message;
                        // this.ok = 1;
                        f.reset({
                            user_code: 'ARM',
                            code: 'ARM',
                            autoscan: this.autoScan,
                            dispatch: '0',
                        });
                        this.scannedStatus = false;
                        this.fullNameForLabel = null;
                        this.disabled = true;
                    }
                },
                (error) => {
                    this.disabled = false;
                }
            );
        } else {
            if (f.value.user_code.length <= 5) {
                this.message = 'User is not selected.';
            } else {
                this.message = 'Issue is not selected.';
            }
            setTimeout(() => {
                this.message = null;
                this.disabled = false;
            }, 1500);
        }

    }

    setOrderCode(value) {
        this.str = value;
        this.order.user_code = value;
        if (this.order.user_code.substr(this.order.user_code.length - 6) === 'delete') {
            this.order.user_code = '';
        }

        switch (true) {
            case this.str.startsWith('A') || this.str.startsWith('M') || this.str.startsWith('GD'):
                this.order.code = 'ARM';
                if (this.str.startsWith('M')) {
                    this.order.dispatch = '2';
                } else if (this.str.startsWith('G')) {
                    this.order.dispatch = '1';
                } else {
                    this.order.dispatch = '0';
                }
                break;
            case this.str.startsWith('RU'):
                this.order.code = 'RU';
                this.order.dispatch = '0';
                break;
            case this.str.startsWith('GA'):
                this.order.code = 'GA';
                this.order.dispatch = '0';
                break;
            case this.str.startsWith('GE'):
                if (this.str.includes('10013')) {
                    this.order.code = 'UGE';
                    this.order.dispatch = '0';
                } else {
                    this.order.code = 'GE';
                    this.order.dispatch = '0';
                }
                break;
            case this.str.startsWith('SC'):
                this.order.code = 'SC';
                this.order.dispatch = '0';
                break;
            default:
                this.order.dispatch = '0';
                this.order.code = 'ARM';
        }
    }

    dispatchSelect(value) {
        this.order.dispatch = value;
        this.arm_clients = [];
        this.ga_clients = [];
        this.ru_clients = [];
        this.ua_clients = [];
        this.ge_amanati = [];
        this.ge_space_cargo_clients = [];
        if (value == 2) {
            this.order.user_code = 'MT';
        } else if (value == 0) {
            this.order.user_code = 'ARM';
        } else {
            this.order.user_code = 'GD';
        }
    }

    statusSelect(value) {
        this.order.status = value;
        if (value && this.us_code_value && this.us_code_value.length > 5) {
            this.disabled = false;
        }
    }

    onChangeIssue(value) {
        this.order.status = value;
    }

    checkUserCode(us_code, code, $event) {

        let reg = /[a-z]{2,3}-[a-z]{4}/gm;

        // @ts-ignore
        if (reg.test($event.target.value)) {
            // @ts-ignore
            let userCode = event.target.value.match(reg)[0].toUpperCase();

            // @ts-ignore
            if (userCode.length < 8) {

                // @ts-ignore
                event.target.value = userCode.substr(0, 2)
                    .toUpperCase() == 'AM' ? 'ARM' : userCode.substr(0, 2).toUpperCase();


            }
            // @ts-ignore
            this.setOrderCode(event.target.value);
        }

        this.ru_clients = [];
        this.ga_clients = [];
        let ln = code == 'ARM' ? 7 : 6;
        if (us_code.startsWith('MT', 0)) {
            us_code = us_code.replace('MT', 'ARM').toUpperCase();
        }
        if (us_code.startsWith('GD', 0)) {
            us_code = us_code.replace('GD', 'ARM').toUpperCase();
        }
        if (us_code.length >= ln || code === 'SC') {
            this._userService
                .checkUserCode({user_code: us_code, code: code})
                .subscribe(
                    (d) => {
                        if (d.status == 200) {
                            if (d.arm_data != undefined && d.arm_data.length > 0) {
                                if (this.order.dispatch == 2) {
                                    d.arm_data = d.arm_data.map((item) => {
                                        const splittedName = item.fullname.split(' ');
                                        splittedName[splittedName.length - 1] = splittedName[
                                        splittedName.length - 1
                                            ]
                                            .replace('ARM', 'MT')
                                            .toUpperCase();
                                        item.fullname = splittedName.join(' ');
                                        return item;
                                    });
                                }
                                if (this.order.dispatch == 1) {
                                    d.arm_data = d.arm_data.map((item) => {
                                        const splittedName = item.fullname.split(' ');
                                        splittedName[splittedName.length - 1] = splittedName[
                                        splittedName.length - 1
                                            ]
                                            .replace('ARM', 'GD')
                                            .toUpperCase();
                                        item.fullname = splittedName.join(' ');
                                        return item;
                                    });
                                }
                                this.arm_clients = d.arm_data;
                            }
                            if (d.ru_data != undefined && d.ru_data.length > 0) {
                                this.ru_clients = d.ru_data;
                            }
                            if (d.ge_amanati != undefined && d.ge_amanati.length > 0) {
                                this.ge_amanati = d.ge_amanati;
                            }
                            if (d.ua_data != undefined && d.ua_data.success) {
                                this.ua_clients = d.ua_data.recipients;
                            }
                            if (d.space_cargo != undefined) {
                                this.ge_space_cargo_clients = d.space_cargo;
                            }
                            if (d.onex_georgia != undefined && d.onex_georgia.length > 0) {
                                this.ga_clients = d.onex_georgia;
                            }
                        }
                    },
                    (e) => {
                        console.log(e);
                    }
                );
        } else {
            this.arm_clients = [];
            this.ga_clients = [];
            this.ru_clients = [];
            this.ua_clients = [];
            this.ge_amanati = [];
            this.ge_space_cargo_clients = [];
        }

    }

    public selectUserCode() {
        this.userCodeInput.nativeElement.focus();
        this.commentDisabled = false;
        this.disableBtn = true;
    }

    selectedClient(id, recipient, fullName, is_person, us_code, dest, defaultService) {
        this.disableBtn = false;
        this.fullNameForLabel = fullName;
        let armPos;
        let user_code;
        if (
            id !== '12244' &&
            id !== '17' &&
            id !== '4903' &&
            id !== '1004' &&
            id !== '1004' &&
            id !== '0' &&
            id !== '1'
        ) {
            if (this.order.dispatch == 2) {
                armPos = fullName.lastIndexOf('MT');
                fullName = this.breakingFullName(fullName, 'MT', 'ARM');
                user_code = fullName;
            } else if (this.order.dispatch == 1) {
                armPos = fullName.lastIndexOf('GD');
                fullName = this.breakingFullName(fullName, 'GD', 'ARM');
                user_code = fullName;
            } else {
                armPos = fullName.lastIndexOf(us_code);
                user_code = fullName;
            }
        } else {
            armPos = fullName.lastIndexOf(us_code);
            user_code = fullName;
        }
        this.defaultService = defaultService;
        if (this.userOrder.tracking_code != undefined) {
            this.order.code = us_code;
            let dataToSend = {
                dest: dest,
                dispatch: `${this.order.dispatch}`,
                user_id: id,
                user_code: user_code,
                is_person: is_person,
                recipient: fullName,
                defaultService: defaultService,
                idrecipient: recipient,
                trackingcode: this.userOrder.tracking_code,
                // weight: this.jsonWeight ? this.jsonWeight : null,
                // width: this.jsonWidth ? this.jsonWidth : null,
                // height: this.jsonHeight ? this.jsonHeight : null,
                // length: this.jsonLength ? this.jsonLength : null

            };
            this.user_id = id;
            if (dest != 'NL') {
                this._userService.setClientInfo(dataToSend).subscribe(
                    (r) => {
                        this.arm_clients = [];
                        this.ga_clients = [];
                        this.ru_clients = [];
                        if (this.order.dispatch == 2) {
                            fullName = this.breakingFullName(fullName, 'ARM', 'MT');
                            user_code = fullName.slice(armPos);
                        }
                        if (this.order.dispatch == 1) {
                            fullName = this.breakingFullName(fullName, 'ARM', 'GD');
                            user_code = fullName.slice(armPos);
                        }
                        this.order.user_code = user_code;
                    },
                    (e) => {
                        console.log(e);
                    }
                );
            }
        }
    }

    onChange(deviceValue) {
        if (deviceValue.value === 'UAM') {
            this.order.user_code = 'ARM267071';
            this.selectedClient('12244', 0, 'Unknown ARM CODE ARM267071', '', 'ARM', 'AM', '');
        }
        if (deviceValue.value === 'UGA') {
            this.order.user_code = 'GA112233';
            this.selectedClient('17', 29, 'Unknown GA CODE GA112233', '', 'GA', 'GA', '');
        }
        if (deviceValue.value === 'UGE') {
            this.order.user_code = 'GE10013';
            this.selectedClient('1004', 0, 'Unknown GE CODE GE10013', '', 'GE', 'GE', '');
        }
        this.order.user_code = deviceValue.value;
    }

    rejectOrder() {
        this.success = 0;
        if (this.order.status) {
            const statusData = {
                id: this.userOrder.id, status: 'reject', note: this.order.status
            };
            this.loading = true;

            this._userService.changeStatus(statusData).subscribe((status: any) => {
                this.loading = false;
                if (status.status === 200) {
                    this.success = 1;
                    this.message = status.message;
                    this.order.status = null;
                    setTimeout(() => {
                        this.message = null;
                    }, 1500);
                    this.userSetExtra(this.status);

                }
            });
        } else {
            this.message = 'Select Issue';
            setTimeout(() => {
                this.message = null;
            }, 1500);
        }
    }

    skipOrder() {
        this.success = 0;
        if (this.order.status) {
            const statusData = {
                id: this.userOrder.id, status: 'skip', note: this.order.status
            };
            this.loading = true;
            this._userService.changeStatus(statusData).subscribe((status: any) => {
                this.loading = false;
                if (status.status === 200) {
                    this.success = 1;
                    this.message = status.message;
                    this.order.status = null;
                    setTimeout(() => {
                        this.message = null;
                    }, 1500);
                    this.userSetExtra(this.status);

                }
            });
        } else {
            this.message = 'Select Issue';
            setTimeout(() => {
                this.message = null;
            }, 1500);
        }
    }

    imageZoom(imgID, resultID) {
        let img, lens, result, cx, cy;
        img = document.getElementById(imgID);
        result = document.getElementById(resultID);
        if (img && img.parentElement && result && result.parentElement) {

            /* Create lens: */
            lens = document.createElement('DIV');
            lens.setAttribute('class', 'img-zoom-lens');
            /* Insert lens: */
            img.parentElement.insertBefore(lens, img);
            /* Calculate the ratio between result DIV and lens: */
            cx = result.offsetWidth / lens.offsetWidth;
            cy = result.offsetHeight / lens.offsetHeight;
            /* Set background properties for the result DIV */
            result.style.backgroundImage = 'url(\'' + img.src + '\')';
            result.style.backgroundSize = (img.width * cx) + 'px ' + (img.height * cy) + 'px';
            /* Execute a function when someone moves the cursor over the image, or the lens: */
            lens.addEventListener('mousemove', moveLens);
            img.addEventListener('mousemove', moveLens);
            /* And also for touch screens: */
            lens.addEventListener('touchmove', moveLens);
            img.addEventListener('touchmove', moveLens);
        }


        function moveLens(e) {
            let pos, x, y;
            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();
            /* Get the cursor's x and y positions: */
            pos = getCursorPos(e);
            /* Calculate the position of the lens: */
            x = pos.x - (lens.offsetWidth / 2);
            y = pos.y - (lens.offsetHeight / 2);
            /* Prevent the lens from being positioned outside the image: */
            if (x > img.width - lens.offsetWidth) {
                x = img.width - lens.offsetWidth;
            }

            if (x < 0) {
                x = 0;
            }

            if (y > img.height - lens.offsetHeight) {
                y = img.height - lens.offsetHeight;
            }

            if (y < 0) {
                y = 0;
            }

            /* Set the position of the lens: */
            lens.style.left = x + 'px';
            lens.style.top = y + 'px';
            /* Display what the lens "sees": */
            result.style.backgroundPosition = '-' + (x * cx) + 'px -' + (y * cy) + 'px';
        }

        function getCursorPos(e) {
            let a, x = 0, y = 0;
            e = e || window.event;
            /* Get the x and y positions of the image: */
            a = img.getBoundingClientRect();
            /* Calculate the cursor's x and y coordinates, relative to the image: */
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /* Consider any page scrolling: */
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {x: x, y: y};
        }
    }

    private breakingFullName(name, defaultStr, replaceWith): string {
        const splittedName = name.split(' ');
        splittedName[splittedName.length - 1] = splittedName[
        splittedName.length - 1
            ]
            .replace(defaultStr, replaceWith)
            .toUpperCase();
        return splittedName.join(' ');
    }
}
