import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DeclarationService} from '../declaration.service';

@Component({
  selector: 'app-declare',
  templateUrl: './declare.component.html',
  styleUrls: ['./declare.component.scss']
})
export class DeclareComponent implements OnInit {

  public declarationForm: FormGroup;
  public declarations = [];
  public order;
  public loading = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private decService: DeclarationService,
              private fb: FormBuilder) {
    this.declarationForm = fb.group({formControls: this.fb.array([])});
  }

  get formControls(): FormArray {
    return this.declarationForm.get('formControls') as FormArray;
  }

  addFormControls() {
    this.formControls.push(this.newFormControls());
  }

  newFormControls(): FormGroup {
    return this.fb.group({
      id: [null],
      order_id: [+this.route.snapshot.paramMap.get('id')],
      name: [null],
      cost: [null],
      quantity: [null],
      description: [null],
    });
  }

  cloneFormControls() {
    if (this.declarations.length > 0) {
      for (const i in this.declarations) {
        if (this.declarations.hasOwnProperty(i)) {
          this.formControls.push(this.clonedFormControls(this.declarations[i]));
        }
      }
    }
  }

  clonedFormControls(declaration): FormGroup {
    return this.fb.group({
      id: [declaration.id],
      order_id: [declaration.order_id],
      name: [declaration.name],
      cost: [declaration.cost],
      quantity: [declaration.quantity],
      description: [declaration.description],
    });
  }

  removeDeclaration(i: number) {
    if (this.formControls.at(i).value.id) {
      this.decService.deleteDeclaration(this.formControls.at(i).value.id).subscribe(
        data => {
          this.formControls.removeAt(i);
        }
      );
    } else {
      this.formControls.removeAt(i);
    }
  }

  ngOnInit() {
    this.getOrder(+this.route.snapshot.paramMap.get('id'));
  }

  gotoDeclarations() {
    this.router.navigate(['/declarations']);
  }

  private getOrder(id: number) {
    this.decService.getOrderById(id).subscribe(
      resp => {
        this.order = resp.order;
        this.declarations = resp.order.declarations;
        if (this.declarations.length > 0) {
          this.cloneFormControls();
        } else {
          this.addFormControls();
        }
      }
    );
  }

  submitDeclarationForm() {
    if (!this.loading) {
      this.create(this.declarationForm.controls.formControls.value);
    }
    this.loading = true;
  }

  create(formValue) {
    const formValues = {declaration: formValue, order_id: this.order.id};
    this.decService.declare(formValues).subscribe(
      data => {
        if (data.status === 200) {
          this.declarations = data.declarations;
          this.cloneFormControls();
          this.gotoDeclarations();
        }
        this.loading = false;
      }, e => this.loading = false
  );
  }
}
