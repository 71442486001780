import {Component, OnInit} from '@angular/core';
import {ShelfService, UserService} from '../../_services';

@Component({
  selector: 'app-shelfing',
  templateUrl: './shelfing.component.html',
  styleUrls: ['./shelfing.component.scss']
})
export class ShelfingComponent implements OnInit {
  // @ViewChild('audio') audioPlayerRef: any;
  barcode;
  selectedShelfBox;
  status;
  finishedFirst;
  status1;
  actions = [];
  orders = [];
  orderTrackingCodes = [];
  finalOrders = [];
  showLoading = true;

  constructor(private _userService: UserService, private shelfService: ShelfService) {
  }

  ngOnInit() {
    this.getData();
  }

  playTo() {
    if (this.status1) {
      this.status = this.status1;
      // this.audioPlayerRef.nativeElement.play();
      this.status1 = null;
    }
  }

  filterBarcode(barcode) {
    // console.log(barcode);
    if (barcode != undefined) {
      if (barcode.charAt(0) == '^') {
        this.getShelfBox(barcode.slice(1));
      } else {
        this.actionForTracking(barcode);
      }
    } else {
      this.actions.unshift({message: 'please first select the shelf box', type: 'error'});
      this.status = null;
      this.status = '../assets/audio/error.mp3';
      // this.audioPlayerRef.nativeElement.play();
      this.checkAndReverse();
    }
  }

  getShelfBox(code) {
    this.status = null;
    // console.log(code);
    this.shelfService.getShelfBox({'shelf_box': code}).subscribe(
      r => {
        if (r.status === 'ok') {
          // {status: "ok", shelf_box: "^1A11", orders: Array(0), ordersToRM: 0, ordersOk: 0}
          // console.log('response shelf box is: ', r);
          this.actions.unshift({message: r.shelf_box + ' shelf box was selected', type: 'success'});
          this.selectedShelfBox = r.shelf_box;
          this.status = '../assets/audio/shelf.mp3';
        } else {
          this.actions.unshift({message: r.message, type: 'error'});
          this.status = '../assets/audio/error.mp3';
          console.log('error shelf box is:', r);
        }
        this.checkAndReverse();
      }, e => {
        // console.log('error shelf box is:', e);
        this.actions.unshift({message: 'server responded with some error! please contact with admin', type: 'error'});
        this.status = '../assets/audio/error.mp3';
        this.checkAndReverse();
      }
    );
    this.barcode = null;
    // this.audioPlayerRef.nativeElement.play();
  }

  actionForTracking(tracking_code) {
    this.status = null;
    this.status1 = null;
    const initialItem = this.finalOrders.map((item) => {
      return item.tracking_code.toUpperCase();
    }).indexOf(tracking_code.toUpperCase());
    let ruChecked = initialItem !== -1;
    // console.log(initialItem, ruChecked);
    if (this.selectedShelfBox) {
      this.shelfService.autoAddRm({tracking_code: tracking_code, shelf_box: this.selectedShelfBox, ru_check: ruChecked}).subscribe(
        d => {
          if (d.status === 'ok') {
            // console.log(d);
            switch (d.record) {
              case 'change':
                if (d.pony) {
                  this.actions.unshift({message: tracking_code + ' ready for shipment to Russia', type: 'success'});
                  this.status1 = '../assets/audio/changed.mp3';
                  this.status = '../assets/audio/pony.mp3';
                } else {
                  if (d.is_for_return) {
                    this.status = '../assets/audio/return.mp3';
                    this.status1 = '../assets/audio/changed.mp3';
                  } else {
                    this.status = '../assets/audio/changed.mp3';
                  }
                }
                this.actions.unshift({message: tracking_code + ' changed the shelf box ' + this.selectedShelfBox, type: 'change'});
                break;
              case 'rm':
                if (d.pony) {
                  this.actions.unshift({message: tracking_code + ' ready for shipment to Russia', type: 'success'});
                  this.status1 = '../assets/audio/removed.mp3';
                  this.status = '../assets/audio/pony.mp3';
                } else {
                  if (d.is_for_return) {
                    this.status = '../assets/audio/return.mp3';
                    this.status1 = '../assets/audio/removed.mp3';
                  } else {
                    this.status = '../assets/audio/removed.mp3';
                  }
                }
                this.actions.unshift({message: tracking_code + ' removed from the shelf box ' + this.selectedShelfBox, type: 'remove'});
                break;
              case 'add':
                if (d.pony) {
                  this.actions.unshift({message: tracking_code + ' ready for shipment to Russia', type: 'success'});
                  this.status1 = '../assets/audio/added.mp3';
                  this.status = '../assets/audio/pony.mp3';
                } else {
                  if (d.is_for_return) {
                    this.status = '../assets/audio/return.mp3';
                    this.status1 = '../assets/audio/added.mp3';
                  } else {
                    this.status = '../assets/audio/added.mp3';
                  }
                }
                this.actions.unshift({message: tracking_code + ' added to the shelf box ' + this.selectedShelfBox, type: 'add'});
                break;
              default:
                return;
            }
          } else {
            this.status = '../assets/audio/error.mp3';
            this.actions.unshift({message: 'server responded with some error! please contact with admin', type: 'error'});
          }
          this.checkAndReverse();
        }, e => {
          // console.log(e);
          this.status = '../assets/audio/error.mp3';
          this.actions.unshift({message: 'server responded with some error! please contact with admin', type: 'error'});
          this.checkAndReverse();
        }
      );
    } else {
      this.status = '../assets/audio/error.mp3';
      this.actions.unshift({message: 'please first select the shelf box', type: 'error'});
      this.checkAndReverse();
    }
    this.barcode = null;
  }

  getData() {
    this.orders = [];
    this.orderTrackingCodes = [];
    this._userService.getOrderGroup().subscribe(
      data => {
        // console.log(data);
        if (data.status == 200) {
          data.orders.orderGroups.map(key => {
            if (key.ordergroup_status == 3) {
              // console.log('all orderd from data: ', key.order);
              let user = key.profile.full_name + ' ' + key.profile.address2;
              let recipient = key.recipmy.full_name + ' ' + key.recipmy.address2;
              key.order.map(k => {
                this.orders.unshift({group_tracking: key.tracking_code, tracking_code: k.tracking_code, user: user, recipient: recipient});
                this.orderTrackingCodes.unshift(k.tracking_code);
                // console.log(k);
              });
            }
          });
          if (this.orders.length > 0) {
            // console.log(this.orders);
            // console.log(this.orderTrackingCodes);
            this.get_orders(this.orders, this.orderTrackingCodes);
          } else {
            this.showLoading = false;
          }
        }
      },
      error => console.log(error)
    );
  }

  get_orders(groups, tracking_code) {
    // console.log(groups, tracking_code);
    this.shelfService.gettingShelfBoxesForConsolidation({tracking_code: tracking_code}).subscribe(
      d => {
        if (d.status == 200) {
          // console.log(d);
          d.orders.map(k => {
            if (groups.filter(item => item.tracking_code.toUpperCase() == k.tracking_code.toUpperCase()).length > 0) {
              k.client_fullname = groups.filter(item => item.tracking_code.toUpperCase() == k.tracking_code.toUpperCase())[0].recipient;
              k.group_tracking = groups.filter(item => item.tracking_code.toUpperCase() == k.tracking_code.toUpperCase())[0].group_tracking;
              k.user = groups.filter(item => item.tracking_code.toUpperCase() === k.tracking_code.toUpperCase())[0].user;
            }
          });
          // console.log(d.orders);
          this.finalOrders = d.orders;
          this.finalOrders = this.finalOrders.sort((a, b) => (a.shelf_box > b.shelf_box) ? 1 : -1);
          this.showLoading = false;
          // let joins = groups.join(d.orders, 'tracking_code');
          // console.log(joins);
        }
      }, e => {
        console.log(e);
      }
    );
  }

  private checkAndReverse(): void {
    if (this.actions.length > 40) {
      this.actions.pop();
    }
  }
}
