import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../_services';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss']
})
export class EditOrderComponent implements OnInit {

  wareHouseData;
  clientData;
  tracking_code = '9410810202061014183513';
  closeResult: string;
  @ViewChild('content') modal;
  constructor(private userService: UserService, private modalService: NgbModal) { }

  ngOnInit() {
  }

  editOrder(order) {
    console.log(order);
  }
  search(tracking) {
    this.userService.getOrderForEdit({tracking_code: tracking}).subscribe(
      d => {
        if (d.status === 200) {
          this.wareHouseData = d.order;
          this.clientData = d.resp.order;
        }
        console.log(d);
      }, e => {
        console.log(e);
      }
    );
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
