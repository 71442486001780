import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from "src/app/_services";
import { ScriptLoaderService } from "../../_services/script-loader.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-scan-order",
  templateUrl: "./scan-order.component.html",
  styleUrls: ["./scan-order.component.css"],
})
export class ScanOrderComponent implements OnInit {
  @ViewChild("tracking_code") inputText;
  @ViewChild("openModal") openModal: ElementRef;
  tracking_codes: any = {};
  public audioFile = null;
  public scanDataMessageWarnning: string | undefined;
  public message = null;
  public defaultServiceModal = false;
  public defaultServiceAudio: string;
  public defService: null;
  ok = 0;
  showtracking;
  data;
  isGermany: boolean;
  isChina: boolean;

  constructor(
    private _userService: UserService,
    private _script: ScriptLoaderService,
    private _router: Router
  ) {
    this.isGermany = localStorage.getItem("detectUser") === "6";
    localStorage.getItem("detectUser") === "8"
      ? _router.navigate(["/russian-parcels"])
      : null;
    this.isChina = localStorage.getItem("detectUser") === "4";
  }

  ngOnInit() {}

  enter(tracking_code, page) {
    this.tracking_codes.tracking_code = tracking_code;
    if (this.isChina && page) {
      this.tracking_codes.page = page;
      this._userService.checkOrder(this.tracking_codes).subscribe((data) => {
        if (data.status === 200) {
          if (data.dispatch == 0) {
            this.checkAudioStatus(7, "Expected Air");
          } else if (data.dispatch == 2) {
            this.checkAudioStatus(3, "Expected Marine");
          } else {
            this.checkAudioStatus(1, "Order saved successfully");
          }
        } else if (data.status === 403) {
          this.checkAudioStatus(4, data.message);
        }
        this.inputText.nativeElement.value = "";
      });
    } else {
      this._userService.createScanOrder(this.tracking_codes).subscribe(
        (data) => {
          this.data = data;
          this.defService = this.data.cli_data
            ? this.data.cli_data.data.default_service
            : null;
          if (this.data.messages === "Order trashed") {
            this.checkAudioStatus(6, this.data.messages);
            this.inputText.nativeElement.value = "";
          } else {
            if (this.data.status == 200) {
              if (this.data.service) {
                this.checkAudioStatus(2, this.data.messages);
              } else if (this.data.dispatch) {
                this.checkAudioStatus(3, this.data.messages);
              } else if (
                this.data.dispatch === 0 &&
                localStorage.getItem("name").includes("China Warehouse") &&
                this.data.cli_data.data.recipient !== null
              ) {
                this.checkAudioStatus(7, this.data.messages);
              } else if (this.data.print_label) {
                this.checkAudioStatus(5, this.data.messages);
                this.companyPopup("Print Label");
              } else {
                this.checkAudioStatus(1, this.data.message);
              }
              console.log(this.data);
            } else if (this.data.status == 400) {
              this.checkAudioStatus(4, this.data.messages);
            }
            this.inputText.nativeElement.value = "";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }

    this.ok = 0;
  }

  private checkAudioStatus(audioStatus, message): void {
    switch (audioStatus) {
      case 1:
        if (this.defService === null || this.defService === undefined) {
          this.audioFile = "../assets/audio/ok.mp3";
        } else {
          this.audioFile = "../assets/audio/special-voice.mp3";
        }
        this.message = message;
        break;
      case 2:
        this.audioFile = "../assets/audio/service.mp3";
        this.message = message;
        break;
      case 3:
        this.audioFile = "../assets/audio/marine.mp3";
        this.message = message;
        break;
      case 4:
        this.audioFile = "../assets/audio/repeat.mp3";
        this.message = message;
        break;
      case 5:
        this.audioFile = "../assets/audio/generate_label.mp3";
        this.message = message;
        break;
      case 6:
        this.audioFile = "../assets/audio/oldItem.mp3";
        this.message = message;
        break;
      case 7:
        this.audioFile = "../assets/audio/air.mp3";
        this.message = message;
        break;
    }
    setTimeout(() => {
      this.ok = null;
      this.audioFile = null;
      this.message = null;
    }, 3500);
  }

  private companyPopup(dataMessage): void {
    this.openModal.nativeElement.click();
    this.scanDataMessageWarnning = dataMessage;
  }
}
