import { Component, OnInit, ViewChild } from "@angular/core";
import { EditOrdersService } from "../_services/edit-orders.service";
import { MatDialog } from "@angular/material";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalEditOrdersComponent } from "./modalEditOrders/modal-edit.component";

@Component({
  selector: "app-edit-orders",
  templateUrl: "./edit-orders.component.html",
  styleUrls: ["./edit-orders.component.scss"],
})
export class EditOrdersComponent implements OnInit {
  public orders: any[] = [];
  public trackingCode = "";
  public showLoading = false;
  public trackingData: any;
  public message: string;
  public status: number;
  public count: number;
  @ViewChild("quantity") quantityInput: any;
  public editForm: FormGroup;

  constructor(
    private _search: EditOrdersService,
    private _edit: EditOrdersService,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  handleValue(e) {
    this.trackingCode = e.value;
  }

  enter() {
    if (this.trackingCode) {
      this.showLoading = true;
      this._search.searchTracking(this.trackingCode).subscribe((data) => {
        this.orders = [];
        this.trackingData = null;

        if (data.status === 200) {
          this.trackingData = data;
          this.status = data.status;
          this.orders.push(this.trackingData.order);
          this.message = data.message;
          this.trackingCode = "";
          this.showLoading = false;
        } else {
          this.message = data.message;
          this.status = data.status;
          this.trackingCode = "";
          this.showLoading = false;
        }
        setTimeout(() => {
          this.message = null;
        }, 1500);
      });
    } else {
      this.showLoading = false;
    }
  }

  ngOnInit() {
    this.setControls();
  }

  editOrder() {
    this.editForm.patchValue({
      width: this.trackingData.order.width,
      height: this.trackingData.order.height,
      length: this.trackingData.order.length,
      dispatch: this.trackingData.order.dispatch,
      v_weight: this.trackingData.order.v_waight,
      weight: this.trackingData.order.waight,
      box_id: this.trackingData.order.box
        ? this.trackingData.order.box.id
        : null,
      is_brand: this.trackingData.order.is_brand,
      battery_type: +this.trackingData.order.battery_type,
      dangerous: this.trackingData.order.dangerous,
      additional_cost: this.trackingData.order.additional_cost,
      add_charge_cost: this.trackingData.order.additional_charge_cost,
      comment: this.trackingData.order.comment,
      comment2: this.trackingData.order.comment2,
    });
  }

  public editWeight(): void {
    this.showLoading = true;
    const formData = {
      height: this.editForm.get("height").value,
      weight: this.editForm.get("weight").value,
      v_weight: this.editForm.get("v_weight").value,
      width: this.editForm.get("width").value,
      length: this.editForm.get("length").value,
      dispatch: this.editForm.get("dispatch").value,
      box_id: this.editForm.get("box_id").value,
      client_order_id: this.trackingData.order.id,
      tracking: this.trackingData.order.tracking_code,
      category: this.editForm.get("category_id").value,
      is_brand: this.editForm.get("is_brand").value,
      dangerous: this.editForm.get("dangerous").value,
      additional_cost: this.editForm.get("additional_cost").value,
      add_charge_cost: this.editForm.get("add_charge_cost").value,
      comment: this.editForm.get("comment").value,
      comment2: this.editForm.get("comment2").value,
      battery_type: this.editForm.get("battery_type").value,
    };
    this._edit.editOrder(formData).subscribe((data) => {
      this.orders = [];
      this.trackingData = null;
      if (data) {
        this.trackingData = data;
        this.orders.push(this.trackingData.order);
        this.showLoading = false;
        this.message = data.message;
        this.status = data.status;
        setTimeout(() => {
          this.message = null;
        }, 1500);
      } else {
        return;
      }
    });
  }

  public calc(w, h, l): void {
    let vWeight;
    if (
      this.trackingData.order.user_code &&
      this.trackingData.order.user_code.includes("ARM")
    ) {
      vWeight = 5000;
    } else {
      vWeight = 6000;
    }
    let totalV = (w * h * l) / vWeight;
    if (w && h && l) {
      this.editForm.patchValue({
        v_weight: totalV,
      });
    }
  }

  delete() {
    const dialogRef = this.dialog.open(ModalEditOrdersComponent, {
      width: "500px",
      panelClass: "custom-dialog-container",
      data: this.trackingData.order.id,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.doneORno === "Yes") {
        this.message = "Order deleted successfully";
        this.trackingData = null;
      } else {
        this.message = "Canceled";
      }
      setTimeout(() => {
        this.message = null;
      }, 1500);
    });
  }

  private setControls(): void {
    this.editForm = this.fb.group({
      width: [null],
      height: [null],
      length: [null],
      dispatch: [1],
      v_weight: [null],
      weight: [null],
      box_id: [null],
      additional_cost: [null],
      add_charge_cost: [null],
      dangerous: [false],
      comment: [null],
      comment2: [null],
      battery_type: [0],
      category_id: [1],
      is_brand: [false],
    });
  }
}
