import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartsModule } from "ng2-charts";
import { NgCircleProgressModule } from "ng-circle-progress";
import { DragulaModule } from "ng2-dragula";
import { NgxGaugeModule } from "ngx-gauge";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./partials/footer/footer.component";
import { NavbarComponent } from "./partials/navbar/navbar.component";
import { SidebarComponent } from "./partials/sidebar/sidebar.component";
import { AuthComponent } from "./auth/auth.component";
import { AuthenticationService, ShelfService, UserService } from "./_services";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { UsersComponent } from "./users/users.component";
import { RepackComponent } from "./order-actions/repack/repack.component";
import { OrdersComponent } from "./orders/orders.component";
import { ScanOrderComponent } from "./order-actions/scan-order/scan-order.component";
import { CreateOrderComponent } from "./orders/create-order/create-order.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { WarehouseComponent } from "./warehouse/warehouse.component";
import { AdminAuthGuard, AuthGuard, LoginGuard } from "./_guards";
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ServiceComponent } from "./order-actions/service/service.component";
import { BoxingComponent } from "./boxes/boxing/boxing.component";
import { UserSetComponent } from "./order-actions/user-set/user-set.component";
import { UserSetExtraComponent } from "./order-actions/user-set-extra/user-set-extra.component";
import { ImageZoomComponent } from "./order-actions/user-set-extra/image-zoom/image-zoom.component";
import { SendReturnComponent } from "./order-actions/send-return/send-return.component";
import { ExtraServiceComponent } from "./order-actions/extra-service/extra-service.component";
import { ParcelsComponent } from "./boxes/parcels/parcels.component";
import { ParcelsCreateComponent } from "./boxes/parcels-create/parcels-create.component";
import { BoxesComponent } from "./boxes/boxes/boxes.component";
import { BoxCreateComponent } from "./boxes/box-create/box-create.component";
import { ServicesComponent } from "./services/services.component";
import { NgxPaginationModule } from "ngx-pagination";
import { OrdersGroupComponent } from "./orders/order-group/ordergroup.component";
import { PackedComponent } from "./orders/order-group/packed/packed.component";
import { SentComponent } from "./orders/order-group/sent/sent.component";
import { httpInterceptorProviders } from "./_http-interceptors";
import { PageNotFoundComponent } from "./page-not-found.component";
import { ShelvesComponent } from "./order-actions/shelves/shelves.component";
import { CreateShelfComponent } from "./order-actions/create-shelf/create-shelf.component";
import { AddOrderShelfComponent } from "./order-actions/shelves/add-order-shelf/add-order-shelf.component";
import { RemoveOrderShelfComponent } from "./order-actions/shelves/remove-order-shelf/remove-order-shelf.component";
import { ShelfDetailsComponent } from "./order-actions/create-shelf/shelf-details/shelf-details.component";
import { SortShelfBoxesPipe } from "./_pipes/sort-shelf-boxes.pipe";
import { MainComponent } from "./partials/main/main.component";
import {
  NgbdModalContentComponent,
  ParcelDetailsComponent,
} from "./boxes/parcel-details/parcel-details.component";
import { CuttingDecimalNumbersPipe } from "./_pipes/cutting-decimal-numbers.pipe";
import { ShelfingComponent } from "./order-actions/shelfing/shelfing.component";
import { OrderStatsComponent } from "./orders/order-stats/order-stats.component";
import { FilterSentOrNotPipe } from "./_pipes/filter-sent-or-not.pipe";
import { TrackingReportComponent } from "./tracking-report/tracking-report.component";
import { SearchResultComponent } from "./tracking-report/search-result/search-result.component";
import { TrackingReportService } from "./_services/tracking-report.service";
import { EditOrderComponent } from "./order-actions/edit-order/edit-order.component";
import { ReturnComponent } from "./order-actions/return/return.component";
import { OrderStatsTotalWeightLengthPipe } from "./_pipes/order-stats-total-weight-length.pipe";
import { CheckWhDirective } from "./check-wh.directive";
import { GermanyPayConfirmComponent } from "./orders/germany-pay-confirm/germany-pay-confirm.component";
import { GermanyDeclarationsComponent } from "./orders/germany-declarations/germany-declarations.component";
import { DeclareComponent } from "./orders/germany-declarations/declare/declare.component";
import { DeclarationService } from "./orders/germany-declarations/declaration.service";
import { HasPayFilePipe } from "./_pipes/has-pay-file.pipe";
import { DeclaredPipe } from "./_pipes/declared.pipe";
import { UkraineComponent } from "./orders/ukraine/ukraine.component";
import { IUserSetComponent } from "./order-actions/i-user-set/i-user-set.component";
import { ReportComponent } from "./report/report.component";
import { InventoriesComponent } from "./inventories/inventories.component";
import { EditOrdersComponent } from "./edit-orders/edit-orders.component";
import { NgxBarcodeModule } from "ngx-barcode";
import { NgxPrintModule } from "ngx-print";
import { IappService } from "./service/iservice.component";
import { AttachPhotoComponent } from "./service/attachPhoto/attachPhoto.component";
import { WebcamModule } from "ngx-webcam";
import { SliderActionModal } from "./service/slider-action/slider-action.component";
import { Iservice } from "./_services/iservice.service";
import { SendReturnService } from "./_services/send-return.service";
import { ReturnServiceComponent } from "./return-service/return-service.component";
import { DoneModalComponent } from "./order-actions/send-return/done-modal/done-modal.component";
import { ChangeStatusModalComponent } from "./order-actions/extra-service/chnage-status-modal/change-status-modal.component";
import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatDialogModule,
  MatIconModule,
  MatRadioModule,
  MatSelectModule,
} from "@angular/material";
import { RussianConsolidationComponent } from "./orders/russian-consolidation/russian-consolidation.component";
import { SearchConsolidationTrackingPipe } from "./_pipes/search-consolidation-tracking.pipe";
import { SearchRussianConsolidationTrackingPipe } from "./_pipes/search-russian-consolidation-tracking.pipe";
import { UkraineConsolidationComponent } from "./orders/ukraine-consolidation/ukraine-consolidation.component";
import { TrackingPrintComponent } from "./order-actions/i-user-set/tracking-print/tracking-print.component";
import { CommonModule } from "@angular/common";
import { PrintTrackBtnComponent } from "./report/print-track-btn/print-track-btn.component";
import { RussianOrdersComponent } from "./orders/russian-orders/russian-orders.component";
import { ServicesBadgeComponent } from "./order-actions/send-return/services-badge/services-badge.component";
import { RussianParcelsModule } from "./boxes/russian-parcels/russian-parcels.module";
import { AppRussianWarehouseConsolidationComponent } from "./orders/russian-warehouse-consolidation/russian-warehouse-consolidation.component";
import { AppReadyToSendComponent } from "./orders/ready-to-send/ready-to-send.component";
import { SharedModule } from "./shared/shared.module";
import { AppRussianWarehouseConsolidationShowDialogComponent } from "./orders/russian-warehouse-consolidation/russian-warehouse-consolidation-show/russian-warehouse-consolidation-show-dialog.component";
import { ReadTosendSuggetionModal } from "./orders/ready-to-send/readTosendSuggetion";
import { ModalInventoriesComponent } from "./inventories/modalForInventories/modal-inventories.component";
import { AppBoxEditComponent } from "./boxes/box-edit/box-edit.component";
import { UserSetModalComponent } from "./order-actions/user-set/user-set-modal/user-set-modal.component";
import { TeamReportComponent } from "./team-report/team-report.component";
import { ModalEditOrdersComponent } from "./edit-orders/modalEditOrders/modal-edit.component";
import { CustomSwitchComponent } from "./partials/sidebar/switcher/switcher.component";
import { ExtraOrdersComponent } from "./orders/extra-orders/extra-orders.component";
import { ExpectedComponent } from "./orders/expected/expected.component";
import { CustomAutocompleteComponent } from "./order-actions/user-set/autocomplete-input/custom-autocomplete.component";

// <-- import the module

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    CustomSwitchComponent,
    FooterComponent,
    DashboardComponent,
    AuthComponent,
    UsersComponent,
    RepackComponent,
    OrdersComponent,
    ScanOrderComponent,
    CreateOrderComponent,
    ExpectedComponent,
    WarehouseComponent,
    ServiceComponent,
    BoxingComponent,
    UserSetComponent,
    UserSetExtraComponent,
    ExtraOrdersComponent,
    SendReturnComponent,
    ExtraServiceComponent,
    ParcelsComponent,
    ParcelsCreateComponent,
    BoxesComponent,
    BoxCreateComponent,
    ServicesComponent,
    OrdersGroupComponent,
    PackedComponent,
    SentComponent,
    PageNotFoundComponent,
    ShelvesComponent,
    CreateShelfComponent,
    AddOrderShelfComponent,
    RemoveOrderShelfComponent,
    ShelfDetailsComponent,
    SortShelfBoxesPipe,
    MainComponent,
    ParcelDetailsComponent,
    CuttingDecimalNumbersPipe,
    ShelfingComponent,
    OrderStatsComponent,
    SearchConsolidationTrackingPipe,
    SearchRussianConsolidationTrackingPipe,
    FilterSentOrNotPipe,
    TrackingReportComponent,
    SearchResultComponent,
    NgbdModalContentComponent,
    EditOrderComponent,
    ReturnComponent,
    OrderStatsTotalWeightLengthPipe,
    CheckWhDirective,
    GermanyPayConfirmComponent,
    GermanyDeclarationsComponent,
    DeclareComponent,
    HasPayFilePipe,
    ReadTosendSuggetionModal,
    ModalInventoriesComponent,
    ModalEditOrdersComponent,
    DeclaredPipe,
    UkraineComponent,
    IUserSetComponent,
    ReportComponent,
    InventoriesComponent,
    EditOrdersComponent,
    EditOrderComponent,
    TeamReportComponent,
    IappService,
    SendReturnComponent,
    AttachPhotoComponent,
    SliderActionModal,
    ReturnServiceComponent,
    DoneModalComponent,
    UserSetModalComponent,
    ChangeStatusModalComponent,
    RussianConsolidationComponent,
    UkraineConsolidationComponent,
    TrackingPrintComponent,
    PrintTrackBtnComponent,
    RussianOrdersComponent,
    ServicesBadgeComponent,
    AppRussianWarehouseConsolidationComponent,
    AppReadyToSendComponent,
    AppRussianWarehouseConsolidationShowDialogComponent,
    AppBoxEditComponent,
    ImageZoomComponent,
    CustomAutocompleteComponent,
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    FormsModule,
    MatRadioModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule,
    HttpClientModule,
    SharedModule,
    HttpModule,
    NgxGaugeModule,
    CommonModule,
    NgbModule.forRoot(),
    DragulaModule.forRoot(),
    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      showBackground: false,
      startFromZero: false,
    }),
    NgxPaginationModule,
    NgxBarcodeModule,
    NgxPrintModule,
    WebcamModule,
    BarcodeScannerLivestreamModule,
    NgbCarouselModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    RussianParcelsModule,
    MatSelectModule,
  ],
  providers: [
    AuthenticationService,
    UserService,
    ShelfService,
    TrackingReportService,
    AuthGuard,
    AdminAuthGuard,
    LoginGuard,
    httpInterceptorProviders,
    ScriptLoaderService,
    FilterSentOrNotPipe,
    CuttingDecimalNumbersPipe,
    SearchConsolidationTrackingPipe,
    SearchRussianConsolidationTrackingPipe,
    DeclaredPipe,
    HasPayFilePipe,
    DeclarationService,
    Iservice,
    SendReturnService,
  ],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [
    ReadTosendSuggetionModal,
    ModalInventoriesComponent,
    ModalEditOrdersComponent,
    NgbdModalContentComponent,
    AttachPhotoComponent,
    SliderActionModal,
    DoneModalComponent,
    UserSetModalComponent,
    ChangeStatusModalComponent,
    AppRussianWarehouseConsolidationShowDialogComponent,
  ],
})
export class AppModule {}
