import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services';

@Injectable()
export class CheckToken implements HttpInterceptor {
    constructor(private _router: Router, private _auth: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let reqUrl = new URL(request.url);
        this._auth.checkLogin();
        if (!this._auth.isLoggedIn && reqUrl.pathname != '/api/signin') {
            // @ts-ignore
            return this._router.navigate(['/login']);
        }
        // console.log('first this: ', request);
        return next.handle(request);
    }
}
