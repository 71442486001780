import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-shelf-details',
  templateUrl: './shelf-details.component.html',
  styleUrls: ['./shelf-details.component.scss']
})
export class ShelfDetailsComponent implements OnChanges, OnInit {

  @Input() shelfDetails;
  details = [];
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    const changedValue: SimpleChange = changes.shelfDetails;
    // console.log('prev value: ', changedValue.previousValue);
    // console.log('got changedValue: ', changedValue.currentValue);
    this.details = changedValue.currentValue;
  }

  ngOnInit() {
    if (this.shelfDetails) {
      this.details = this.shelfDetails;
    }
  }

  barcode (value) {
    // console.log(value);
    // window.open("http://warehouseback.onex.am/api/print_lable/" + value);
    window.open("http://warehouseback.onex.am/api/lable_print_code/" + value);
  }

}
