import {Component, OnInit} from '@angular/core';
import {RussianParcelsService} from '../../../_services';

@Component({
    selector: 'app-russian-parcels-scan',
    templateUrl: 'russian-parcels-scan.component.html',
    styleUrls: ['russian-parcels-scan.component.scss']
})

export class AppRussianParcelsScanComponent implements OnInit {
    public message: string;
    public loading = false;
    public status;
    public searchRecipientData;
    public tracking = '';
    public audio;

    constructor(
        private russianParcelService: RussianParcelsService,
    ) {
    }

    ngOnInit() {
    }

    setTracking(tracking) {
        this.tracking = tracking.value;
    }

    search(tracking) {
        this.loading = true;
        this.russianParcelService.searchRuOrders({
            tracking_code: tracking,
            is_scan_ru_warehouse: 1
        }).subscribe(
            (data: any) => {
                this.loading = false;
                this.status = data.success;
                this.tracking = '';
                if (data.success) {
                    this.audio = '../assets/audio/ok.mp3';
                    this.searchRecipientData = data.data;
                    this.message = data.message;
                } else {
                    this.audio = '../assets/audio/error.mp3';
                    this.message = data.message;
                    this.searchRecipientData = null;
                }
                setTimeout(() => {
                    this.message = null;
                }, 1500);

            }, e => {
                this.loading = false;
                console.log(e);
            }
        );
    }
}
