import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from './_services';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[checkWh]'
})
export class CheckWhDirective implements OnInit {


  /*
  * detectUser = warehouseID
  * 4 = china
  * 6 = germany
  * */
  // tslint:disable-next-line:no-input-rename
  @Input('checkWh') appCheckWh: number[];

  constructor(private authService: AuthenticationService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit() {
    const userWarehouseId = +localStorage.getItem('detectUser');
    !this.appCheckWh.includes(userWarehouseId) ? this.viewContainer.createEmbeddedView(this.templateRef)
      : this.viewContainer.clear();
  }
}
