import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchRussianConsolidationTracking'
})

export class SearchRussianConsolidationTrackingPipe implements PipeTransform {
    transform(value: any, tracking?): any {
        if (tracking) {
            return value.filter(item => item.tracking_code.includes(tracking));
        }
        return value;
    }
}
