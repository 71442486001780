import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FilterShelfBoxesPipe} from '../_pipes/filter-shelf-boxes.pipe';
import {SearchTrackingPipe} from '../_pipes/search-tracking.pipe';


@NgModule({
    declarations: [
        FilterShelfBoxesPipe,
        SearchTrackingPipe
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
    ],
    providers: [
        FilterShelfBoxesPipe,
        SearchTrackingPipe
    ],
    exports: [
        FilterShelfBoxesPipe,
        SearchTrackingPipe
    ]
})


export class SharedModule {
}

