import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/_services";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-parcels",
  templateUrl: "./parcels.component.html",
  styleUrls: ["./parcels.component.scss"],
})
export class ParcelsComponent implements OnInit {
  parcels = [];
  parcel: any = {};
  pahests = [];
  parcelId;
  auth_user;
  superadmin = false;
  activeFilter = "not_sent";
  userWarehouseId;
  message = "";

  constructor(
    private _userService: UserService,
    private httpClient: HttpClient
  ) {
    this.userWarehouseId = +localStorage.getItem("detectUser");
    if (this.userWarehouseId == 4) {
      this.activeFilter = "all";
    }
  }

  ngOnInit() {
    this._userService.getParcel().subscribe(
      (data) => {
        this.parcels = data.parcels;
      },
      (error) => console.log(error)
    );
    this._userService.getPahest().subscribe(
      (data) => {
        this.pahests = data.pahests;
      },
      (error) => console.log(error)
    );
  }

  getid(getid) {
    this.parcel.parcel_warehouse = getid;
    this.parcel.id = getid;
    this._userService.getByIdParcel(getid).subscribe(
      (data) => {
        this.pahests = data.pahests;
        this.parcel = data.parcel;
        this.auth_user = data.auth_user;

        if (this.auth_user == 1) {
          this.superadmin = true;
        }
      },
      (error) => console.log(error)
    );
  }

  export(id: number) {
    if (id != undefined) {
      return window.open(
        `https://warehouseback.onex.am/parcels/${id}/export`,
        "_blank"
      );
    } else {
      alert("tracking code and user code fields are required");
    }
  }

  editparcel() {
    this._userService.editParcel(this.parcel).subscribe(
      (data) => {
        console.log(data);
        this.message = data.message;
        // this.parcel = data.parcel;
      },
      (error) => console.log(error)
    );
    setTimeout(() => {
      this.message = "";
    }, 2000);
  }

  filter(filter) {
    this.activeFilter = filter;
    if (filter === "sent") {
      this.parcels = this.parcels.sort(
        (a, b) =>
          new Date(b.in_mmy_way_date).getTime() -
          new Date(a.in_mmy_way_date).getTime()
      );
    }
  }
}
