import {Component, Input, Output, OnChanges, OnInit, SimpleChange, SimpleChanges, EventEmitter} from '@angular/core';
import {ShelfService} from '../../../_services';

@Component({
  selector: 'app-remove-order-shelf',
  templateUrl: './remove-order-shelf.component.html',
  styleUrls: ['./remove-order-shelf.component.scss']
})
export class RemoveOrderShelfComponent implements OnChanges, OnInit {

  @Input() selectedShelfBox;
  @Output() updatedShelfBox  = new EventEmitter<any>();
  ordersInBox = [];
  constructor(private shelfService: ShelfService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const changedValue: SimpleChange = changes.selectedShelfBox;
    this.ordersInBox = changedValue.currentValue;
  }

  ngOnInit() {
    if (this.selectedShelfBox) {
      this.ordersInBox = this.selectedShelfBox.reverse();
    }
  }
  remove(tracking) {
    if (confirm('are you sure to remove tracking ' + tracking)) {
      this.updatedShelfBox.emit(tracking);
    } else {
      // console.log('cannot delete');
    }
  }

}
