import {Component, OnInit} from '@angular/core';
import {UkraineService} from './ukraine.service';

@Component({
    selector: 'app-ukraine',
    templateUrl: './ukraine.component.html',
    styleUrls: ['./ukraine.component.scss']
})
export class UkraineComponent implements OnInit {

    public orders = [];
    public totalWeight = 0;
    public Weight;
    activeFilter = 'all';
    searchTrackingCode;
    public activated: string;
    public ordersForCalc = [];
    public totalWeightCalc;

    constructor(private ukraineService: UkraineService) {
    }

    ngOnInit() {
        this.ukraineService.getUkraineOrders().subscribe(data => {
            this.orders = data.orders;
            this.ordersForCalc = data.orders;
            this.totalWeightCalc = 0;
            for (let weight of this.orders) {
                this.totalWeight += Number(weight.waight);
                this.totalWeightCalc = this.totalWeight.toPrecision(4);
            }
        }, err => {
            console.log(err);
        });
    }

    checkOrdersActivate() {
        if (this.activeFilter === 'special') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.orders) {
                if (weight.activated === '2') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.waight);
                    this.totalWeightCalc = this.totalWeight.toPrecision(3);
                }
            }
        } else if (this.activeFilter === 'normal') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.orders) {
                if (weight.activated === '1') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.waight);
                    this.totalWeightCalc = this.totalWeight.toPrecision(4);
                }
            }
        } else {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.orders) {
                this.ordersForCalc.push(weight);
                this.totalWeight += Number(weight.waight);
                this.totalWeightCalc = this.totalWeight.toPrecision(4);
            }
        }
    }

    search(tracking) {
        this.searchTrackingCode = tracking;
    }


    print_label(item) {
        this.ukraineService.labelGenerate(+item.id).subscribe(data => {
            if (data.status === 200) {
                window.open(data.url, '_blank');
            } else {
                alert(data.message);
            }
        });
    }

    setPreBox(order, event) {
        this.ukraineService.setPreBox({checked: event.checked}, order.id).subscribe(
            d => {
                console.log(d);
                order.activated = d.activated;
            }, e => {
                console.log(e);
            }
        );
    }
}
