import { Component, OnInit } from "@angular/core";
import { AuthenticationService, UserService } from "../_services";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
  providers: [AuthenticationService, UserService],
})
export class AuthComponent implements OnInit {
  model: any = {};
  loading: boolean;
  user: any;
  error;

  constructor(
    private _authService: AuthenticationService,
    private _userService: UserService,
    private _router: Router
  ) {}

  ngOnInit() {}

  signin() {
    this.loading = true;
    this._authService
      .login(this.model.email, this.model.password)
      .pipe(
        catchError(this.handleError()),
        map((response) => {
          // @ts-ignore
          this.user = response.user;
          // @ts-ignore
          const token = response.token;
          const base64url = token.split(".")[1];
          const base64 = base64url.replace("-", "+").replace("_", "/");
          localStorage.setItem("currentUser", token);
          localStorage.setItem("detectUser", this.user.pahest_id);
          // console.log(response);
          // console.log(base64);
          // console.log(base64url);
          // console.log(JSON.parse(window.atob(base64)));
          return {
            token: token,
            user: this.user,
            decoded: JSON.parse(window.atob(base64)),
          };
        })
      )
      .subscribe(
        (data) => {
          localStorage.setItem("name", data.user.name);
          localStorage.setItem("role", data.user.role);
          localStorage.setItem("exp", data.decoded.exp);
          localStorage.setItem("iat", data.decoded.iat);
          this._router.navigate(["/"]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      this.error = error;
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
