import {Component, Compiler} from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'star-admin-pro-angular';
    constructor (private compiler: Compiler) {
      compiler.clearCache();
    }

}
