import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchConsolidationTracking'
})

export class SearchConsolidationTrackingPipe implements PipeTransform {
    transform(value: any, consolidationTracking?: any): any {
        if (consolidationTracking) {
           value = value.filter(item => item.order_group_tracking_code.includes(consolidationTracking));
        }
        return value;
    }
}
