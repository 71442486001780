import {Component, OnInit} from '@angular/core';
import {UserService} from '../../_services';

@Component({
  selector: 'app-germany-pay-confirm',
  templateUrl: './germany-pay-confirm.component.html',
  styleUrls: ['./germany-pay-confirm.component.scss']
})
export class GermanyPayConfirmComponent implements OnInit {
  orders = [];
  tracking: string;
  activeFilter = 'all';
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.getOrders();
  }

  getOrders () {
    this.userService.getOrdersGermanyPayConf().subscribe(
      d => {
        if (d.status === 200) {
          this.orders = d.orders;
        }
        console.log(this.orders);
      },
      e => {
        console.log(e);
      }
    );
  }
  searchTracking() {
    if (this.tracking != undefined && this.tracking != '') {
      this.userService.getOrderByTrackingGermany({tracking_code: this.tracking}).subscribe(
        d => {
          if (d.status == 200) {
            this.orders = [d.order];
          }
          console.log(d);
        }, e => {
          console.log(e);
        }
      );
    } else {
      this.getOrders();
    }
  }
  setConfirmed(order) {
      this.userService.setOrderConfirmed({id: order.id}).subscribe(
        d => {
          console.log(d);
          order.pay_confirmed = d.order.pay_confirmed;
        }, e => {
          console.log(e);
        }
      );
  }
  setRejected(order) {
      this.userService.setOrderRejected({id: order.id, type: 'payment'}).subscribe(
          d => {
            console.log(d);
            order.pay_confirmed = d.order.pay_confirmed;
            order.pay_confirmed_url = d.order.pay_confirmed_url;
          }, e => {
            console.log(e);
          }
        );

  }
  needConfirm(order) {
    console.log(order);
    this.userService.needConfirmToggle({id: order.id}).subscribe(
      d => {
        console.log(d);
        order.pay_confirmed = d.order.pay_confirmed;
      }, e => {
        console.log(e);
      }
    );
  }
}
