import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs-compat";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const host = environment.API_ENDPOINT_ROOT;

@Injectable()
export class SendReturnService {
  constructor(private _httpClient: HttpClient) {}

  public message: BehaviorSubject<any> = new BehaviorSubject<any>("");

  public sendAndReturn() {
    return this._httpClient.get(host + "/services");
  }

  public doneService(order_service) {
    return this._httpClient.post(
      host + "/sendOrReturnOrderService",
      order_service
    );
  }

  public sendOrderId(order_id) {
    console.log(this.message);
    return this._httpClient.get(host + `/customer-send/${order_id}`);
  }

  public changeService(trackingCode) {
    return this._httpClient.get(host + `/orders/${trackingCode}/tracking-code`);
  }

  public getService() {
    return this._httpClient.get(host + `/all-services`);
  }

  public sendExtraService(data) {
    return this._httpClient.post(host + `/extra-service`, data);
  }

  public getExtraService(search) {
    if (search !== "") {
      return this._httpClient.get(host + `/extra-services/${search}`);
    }
    return this._httpClient.get(host + `/extra-services`);
  }

  public deleteService(service_id) {
    return this._httpClient.post(
      host + `/deleteExtraService/${service_id}`,
      service_id
    );
  }
}
