import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Shelf} from '../_models';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const host = environment.API_ENDPOINT_ROOT;


@Injectable()
export class ShelfService {
    constructor(private httpClient: HttpClient) {
    }

    createShelf(shelf: Shelf) {
        return this.httpClient.post<Shelf>(host + '/createShelf', shelf, httpOptions).pipe();
    }

    showShelves() {
        return this.httpClient.get<any>(host + '/getShelves', httpOptions).pipe();
    }

    getShelves() {
        return this.httpClient.get<any>(host + '/getShelves', httpOptions).pipe();
    }

    getShelfDetails(shelf) {
        return this.httpClient.post<any>(host + '/getShelfDetails', shelf, httpOptions).pipe();
    }

    getShelfBox(shelf_box) {
        return this.httpClient.post<any>(host + '/getShelfBox', shelf_box, httpOptions).pipe();
    }

    addOrderInShelfBox(order) {
        return this.httpClient.post<any>(host + '/addOrderInShelfBox', order, httpOptions).pipe();
    }

    removeOrderFromShelfBox(order) {
        return this.httpClient.post<any>(host + '/removeOrderFromShelfBox', order, httpOptions).pipe();
    }

    checkOrder(order) {
        return this.httpClient.post<any>(host + '/checkOrderInShelfBox', order, httpOptions).pipe();
    }

    autoAddRm(barcode) {
        return this.httpClient.post<any>(host + '/autoAddRm', barcode, httpOptions).pipe();
    }

    gettingShelfBoxesForConsolidation(barcode) {
        return this.httpClient.post<any>(host + '/gettingShelfBoxesForConsolidation', barcode, httpOptions).pipe();
    }

    public consolidationOrders() {
        return this.httpClient.get(host + '/consolidationOrders');
    }
}
