import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class OpenCloseMenuService {
    public iconOnlyToggled: boolean;
    public sidebarOpened: boolean;
}
