import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TrackingCode} from '../../tracking-report/tracking-report.component';
import {TrackingReportService} from '../../_services/tracking-report.service';

@Component({
    selector: 'app-print-btn',
    templateUrl: 'print-track-btn.component.html',
    styleUrls: ['print-track-btn.component.scss']
})


export class PrintTrackBtnComponent implements OnInit, OnDestroy {
    @ViewChild('tackInput') public tackInput: any;
    @ViewChild('printButton') public printButton: any;
    @Input() trackingCode: string | undefined;
    @Input() userFullName: string | undefined;
    public top = 'top';
    public left = 'center';
    public bottom = 'bottom';
    public center = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public sendTackInput: TrackingCode;
    public sendTackMessage = null;
    public disabledBtn = true;

    constructor(
        private trackingReport: TrackingReportService,
    ) {
    }

    ngOnInit() {
    }

    // public print_label(track) {
    //     this.sendTackMessage = null;
    //     if (track !== '') {
    //         this.sendTackInput = {
    //             tracking_code: ''
    //         };
    //
    //         this.sendTackInput.tracking_code = track;
    //         this.trackingReport.chekInputTrack(this.sendTackInput).subscribe(data => {
    //             console.log(data);
    //             if (data.status === 200) {
    //                 if (data.order) {
    //                     if (+data.order.dispatch === 2) {
    //                         this.userFullName = `${data.order.client_fullname.split(' ')[0]} ${data.order.client_fullname.split(' ')[1]} ${data.order.client_fullname.split(' ')[2].replace('ARM', 'MT')} `;
    //                     } else {
    //                         this.userFullName = data.order.client_fullname ? data.order.client_fullname : data.order.orderer_info;
    //                     }
    //                     console.log(this.userFullName);
    //                     this.sendTackMessage = data.message;
    //                     this.disabledBtn = false;
    //                     setTimeout(() => {
    //                         this.printButton.nativeElement.click();
    //                     }, 0);
    //                 }
    //                 if (data.message) {
    //                     this.disabledBtn = true;
    //                     this.sendTackMessage = data.message;
    //                 }
    //             }
    //
    //         });
    //     }
    //     setTimeout(() => {
    //         this.sendTackMessage = null;
    //     }, 2000);
    // }


    ngOnDestroy() {
    }
}
