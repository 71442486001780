import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TrackingReportService } from "../../_services/tracking-report.service";

@Component({
  selector: "app-expected",
  templateUrl: "./expected.component.html",
  styleUrls: ["./expected.component.scss"],
})
export class ExpectedComponent implements OnInit {
  public expectedForm: FormGroup;
  public partners = [
    { title: "Armenia", val: "AM" },
    { title: "Georgia", val: "GA" },
  ];
  public expectedOrdersList;
  public expectedOrdersTable = [
    "Partner",
    "Recipient",
    "Tracking code",
    "Status",
    "Created date",
    "Updated date",
  ];
  public message = "";
  public loading = false;
  public isEmpty = false;

  constructor(
    private getExpected: TrackingReportService,
    private formBuilder: FormBuilder
  ) {
    this.expectedForm = this.setControls();
  }

  ngOnInit() {
    if (localStorage.getItem("detectUser") === "10") {
      this.partners = [{ title: "Armenia", val: "AM" }];
    } else if (localStorage.getItem("detectUser") === "4") {
      this.partners = [
        { title: "Armenia", val: "AM" },
        { title: "Georgia", val: "GA" },
        { title: "PDD Armenia", val: "PAA" },
        { title: "PDD Georgia", val: "PGA" },
      ];
    }
  }

  public search() {
    if (this.expectedForm.value.expectedOrders.partner) {
      this.loading = true;
      const newParams = {};
      const newDate = {
        partner: this.expectedForm.value.expectedOrders.partner || null,
        date_from:
          new Date(
            this.expectedForm.value.expectedOrders.date_from
          ).toLocaleString("sv-SE") || null,
        date_to:
          (this.expectedForm.value.expectedOrders.date_to
            ? new Date(
                this.expectedForm.value.expectedOrders.date_to
              ).toLocaleString("sv-SE")
            : new Date().toLocaleString("sv-SE")) || null,
        tracking_code: this.expectedForm.value.expectedOrders.tracking || null,
        user_code: this.expectedForm.value.expectedOrders.user_code || null,
      };

      Object.entries(newDate).map(([key, val]) => {
        if (val) {
          newParams[key] = val;
        }
      });

      this.getExpected.fetchExpectedOrders(newParams).subscribe((data) => {
        if (data.status === 200) {
          this.loading = false;
          this.expectedOrdersList = data.data;
          this.isEmpty = true;
        } else {
          this.message = data.message;
          this.loading = false;
        }
      });
    } else {
      this.message = "Please select partner";
    }
    setTimeout(() => {
      this.message = "";
    }, 2000);
  }

  private setControls(): FormGroup {
    return this.formBuilder.group({
      expectedOrders: this.formBuilder.group({
        partner: [null],
        date_from: [null],
        date_to: [null],
        tracking: [null],
        user_code: [null],
      }),
    });
  }
}
