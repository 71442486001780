import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSentOrNot'
})
export class FilterSentOrNotPipe implements PipeTransform {

  transform(value: any, args?: string): any {
    if (!Array.isArray(value)) {
      return;
    }
    switch (args) {
      case 'sent':
        value = value.filter(item => +item.status !== 1);
        break;
      case 'not_sent':
        value = value.filter(item => item.status == 1);
        break;
      default:
    }
    return value;
  }
}
