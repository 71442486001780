import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/_services';
import {ScriptLoaderService} from 'src/app/_services/script-loader.service';
import {Router} from "@angular/router";

export interface CreateBox {
    box_warehouse: string;
    parcel_id: string;
    tracking_code: string;
    width: string;
    length: string;
    height: string;
    v_weight: string;
    gross_weigth: string;
    is_brand: boolean;
    is_export: boolean;
    is_company: boolean
}

@Component({
    selector: 'app-box-create',
    templateUrl: './box-create.component.html',
    styleUrls: ['./box-create.component.scss']
})
export class BoxCreateComponent implements OnInit {
    box: CreateBox = {
        box_warehouse: null,
        parcel_id: null,
        tracking_code: null,
        width: null,
        length: null,
        height: null,
        v_weight: null,
        gross_weigth: null,
        is_brand: false,
        is_export: false,
        is_company: false
    };
    pahests = [];
    wh;
    parcels = [];
    isSAdmin: boolean;
    data;
    messege;
    ok;
    total;
    isGermany: boolean;

    constructor(private _userService: UserService, private _script: ScriptLoaderService, private _router: Router) {
        this.isGermany = localStorage.getItem('detectUser') === '6';

    }

    ngOnInit() {
        this.isSAdmin = (localStorage.getItem('role') == 'super_admin') ? true : false;
        this._userService.getPahest().subscribe(
            data => {
                if (this.isSAdmin) {
                    this.pahests = data.pahests;
                } else {
                    this.wh = data.pahests;
                }
            },
            error => console.log(error)
        );
        this._userService.getParcel().subscribe(
            data => {
                this.parcels = data.parcels;
                this.parcels = this.parcels.filter(item => item.status == 1);
            },
            error => console.log(error)
        );
    }

    creatbox() {
        // if (!this.isGermany) {
        if (!this.isSAdmin) {
            this.box.box_warehouse = this.wh.id;
        }
        this._userService.createnewbox(this.box).subscribe(
            data => {
                this.data = data;
                if (this.data.status == 200) {
                    this.messege = this.data.message;
                    this.ok = 1;
                    this._script.loadScripts('app-box-create', ['/assets/js/henrik.js']);
                    this.box = null;
                    setTimeout(() => {
                        this._router.navigate(['/boxes'])
                    }, 3000)
                } else if (this.data.status == 400) {
                    this.messege = this.data.message;
                    this.ok = 2;
                    this._script.loadScripts('app-box-create', ['/assets/js/henrik.js']);
                }
                // this.pahests = data.pahests;
            },
            error => console.log(error)
        );
        // }

    }

    generateTrack() {
        this._userService.getBoxTracking().subscribe(
            data => {
                this.box.tracking_code = data.box_code;
            },
            error => console.log(error)
        );
    }

    public calcVWeight(box) {
        this.box.v_weight = (+((box.width ? +box.width : 1) * (box.length ? +box.length : 1) * (box.height ? +box.height : 1)) / 6000).toFixed(3)
    }

}
