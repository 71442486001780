import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { SendReturnService } from "../../../_services/send-return.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-change-status-modal",
  templateUrl: "change-status-modal.component.html",
  styleUrls: ["change-status-modal.component.scss"],
})
export class ChangeStatusModalComponent implements OnInit, OnDestroy {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public trackingCode = "";
  public comment = "";
  public trackingData: any;
  public loading: boolean;
  public message: string;
  public service = "";
  public allServices;
  public onSave: any;

  constructor(
    public _changeService: SendReturnService,
    private modalService: NgbModal,
    public _extraService: SendReturnService,
    public _getService: SendReturnService
  ) {}

  public handleTrackingValue(e) {
    this.trackingCode = e.value;
  }

  public handleComment(e) {
    this.comment = e.value;
  }

  searchOrder() {
    if (this.trackingCode) {
      this.loading = true;
      this._changeService.changeService(this.trackingCode).subscribe((data) => {
        this.loading = false;
        this.trackingData = data;
        if (this.trackingData.status === 200) {
          this.message = "Order Find";
        } else {
          this.message = this.trackingData.message;
          this.trackingCode = "";
        }
        setTimeout(() => {
          this.message = null;
        }, 1500);
      });
    } else {
      return;
    }
  }

  handleService() {
    if (
      this.trackingData &&
      this.trackingData.order &&
      this.service !== "" &&
      !this.trackingData.order.order
    ) {
      const serviceData = {
        service: this.service,
        comment: this.comment,
        tracking: this.trackingCode,
      };
      this._extraService
        .sendExtraService(serviceData)
        .subscribe((data: any) => {
          this.onSave = data;
          this.passEntry.emit(data);
          if (this.onSave.status === 200) {
            this.modalService.dismissAll();
          } else {
            this.message = data.message;
          }
          setTimeout(() => {
            this.message = null;
          }, 2500);
        });
    } else {
      return;
    }
  }

  cancelService() {
    this.modalService.dismissAll();
  }

  handleServiceValue(e) {
    this.service = e.target.value;
  }

  ngOnInit() {
    this._getService.getService().subscribe((dataServices: any) => {
      this.allServices = dataServices.data;
    });
  }

  ngOnDestroy() {}
}
