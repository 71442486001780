import {AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';


@Component({
    selector: 'app-image-zoom',
    templateUrl: './image-zoom.component.html',
    styleUrls: ['./image-zoom.component.scss'],
})


export class ImageZoomComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() imgUrl: string;
    public count = 0;

    ngAfterViewInit() {
        this.imageZoom('myimage', 'myresult');
    }

    ngOnDestroy() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.imgUrl) {
            this.imageZoom('myimage', 'myresult');
        }
    }


    imageZoom(imgID: string, resultID: string) {
        const img = document.getElementById(imgID);
        const result = document.getElementById(resultID);
        let lens = document.createElement('div');
        lens.style.position = 'absolute';
        lens.style.width = '20px';
        lens.style.height = '20px';
        img.parentElement.insertBefore(lens, img);
        const cx = result.offsetWidth / 20;
        const cy = result.offsetHeight / 20;
        // @ts-ignore
        result.style.backgroundImage = `url('${this.imgUrl}')`;
        // @ts-ignore
        result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;

        lens.addEventListener('mousemove', moveLens);
        img.addEventListener('mousemove', moveLens);

        lens.addEventListener('touchmove', moveLens);
        img.addEventListener('touchmove', moveLens);

        function moveLens(e: any) {
            e.preventDefault();
            const pos = getCursorPos(e);

            let x = pos.x - 20 / 2;
            let y = pos.y - 20 / 2;

            // @ts-ignore
            if (x > img.width - 20) {
                // @ts-ignore
                x = img.width - 20;
            }
            if (x < 0) {
                x = 0;
            }
            // @ts-ignore
            if (y > img.height - 20) {
                // @ts-ignore
                y = img.height - 20;
            }
            if (y < 0) {
                y = 0;
            }

            lens.style.left = `${x}px`;
            lens.style.top = `${y}px`;

            result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
            // console.log(`-${x * cx}px -${y * cy}px`);
        }

        function getCursorPos(e: any) {
            let x = 0;
            let y = 0;
            e = e || window.event;
            const a = img.getBoundingClientRect();
            x = e.pageX - a.left - window.pageXOffset;
            y = e.pageY - a.top - window.pageYOffset;
            return {x, y};
        }
    }

    rotateImg() {
        this.count += 1;
        const rotateImg = document.getElementById('myimage');

        if (this.count === 1) {
            rotateImg.style.transform = 'rotate(90deg)';
        } else if (this.count === 2) {
            rotateImg.style.transform = 'rotate(180deg)';
        } else if (this.count === 3) {
            rotateImg.style.transform = 'rotate(270deg)';
        } else {
            rotateImg.style.transform = 'rotate(0deg)';
            this.count = 0;
        }
    }

    openImageTab() {
        window.open(this.imgUrl, '_blank', 'noreferrer');
    }
}
