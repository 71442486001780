import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserService} from '../../_services';
import {FilterShelfBoxesPipe} from '../../_pipes/filter-shelf-boxes.pipe';
import {SearchDataInterface} from '../order-stats/interface/search-data.interface';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-russian-orders',
    templateUrl: 'russian-orders.component.html',
    styleUrls: ['russian-orders.component.scss']
})

export class RussianOrdersComponent implements OnInit {
    @ViewChild('tracking_code') public tracking_code: ElementRef;
    @ViewChildren('prebox') public prebox: QueryList<any>;
    @ViewChildren('printBtn') public printBtn: QueryList<any>;
    public ordersTracks = [];
    showLoading = true;
    orders = [];
    orderTrackingCodes = [];
    finalOrders = [];
    p;
    searchTrackingCode;
    staticCode = true;
    activeFilter = 'all';
    public ok = false;
    public top = 'top';
    public left = 'center';
    public mRight = 1;
    public mTop = 20;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public center = 'center';
    public bottom = 'bottom';
    public sizeBarcode = 1.5;
    public totalWeight = 0;
    public ordersForCalc = [];
    public totalWeightCalc;
    public audioStatus = '';
    public message: string;
    public scanedOrder = [];
    public alertSuccess = true;
    public lastSixDigit;
    public yellowRow: boolean;

    constructor(
        private _userService: UserService,
        private filter: FilterShelfBoxesPipe,
    ) {
    }


    ngOnInit() {
        this.getData();
    }

    getData() {
        this.orders = [];
        this.orderTrackingCodes = [];
        this._userService.getRuOrder().subscribe(
            data => {
                this.finalOrders = data.data;
                this.finalOrders.map(item => {
                    item.yellowRow = item.activated === '2';
                });
                this.finalOrders = this.finalOrders.sort((a, b) => {
                    if (a.orderer_info && b.orderer_info) {
                        if ((a.orderer_info.split(' ')[2] > b.orderer_info.split(' ')[2])) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                });
                this.showLoading = false;
                this.checkOrdersActivate();
            },
            error => console.log(error)
        );
    }

    checkOrdersActivate() {
        console.log(this.activeFilter, 'aaaaaaaa');
        if (this.activeFilter === 'special') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.activated === '2') {
                    this.ordersForCalc.push(weight);
                    if (Number(weight.weight) > 0) {
                        this.totalWeight += Number(weight.weight);
                    }
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else if (this.activeFilter === 'normal') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.activated === '1') {
                    this.ordersForCalc.push(weight);
                    if (Number(weight.weight) > 0) {
                        this.totalWeight += Number(weight.weight);
                    }
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                this.ordersForCalc.push(weight);
                if (Number(weight.weight) > 0) {
                    this.totalWeight += Number(weight.weight);
                }
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            }
        }
    }


    setPreBox(order, event) {
        order.yellowRow = event.checked;
        this._userService.setPreBox({id: order.id, checked: event.checked}).subscribe(
            d => {
                // if (+d.order.activated === 2) {
                //     if (d.state === 'send') {
                //         this.audioStatus = '../assets/audio/send_parcel.mp3';
                //         this.message = 'Send Parcel';
                //         this.alertSuccess = true;
                //     } else {
                //         this.alertSuccess = false;
                //         this.audioStatus = '../assets/audio/dont_send.mp3';
                //         this.message = 'Don\'t Send ' + this.searchTrackingCode;
                //     }
                //     setTimeout(() => {
                //         this.audioStatus = null;
                //         this.message = null;
                //     }, 1000);
                // } else {
                //     this.audioStatus = null;
                //     this.message = null;
                // }
                order.activated = d.activated;
            }, e => {
                console.log(e);
            }
        );
    }


    search(tracking) {
        this.searchTrackingCode = tracking;
        this.scanedOrder = [];
        if (this.searchTrackingCode) {
            if (this.staticCode) {
                this._userService.checkOrderPreBoxForRu(this.searchTrackingCode).subscribe((data: SearchDataInterface) => {
                    if (data.state === 'send') {
                        this.audioStatus = '../assets/audio/send.mp3';
                        this.message = 'Send';
                        this.alertSuccess = true;
                    } else {
                        this.alertSuccess = false;
                        this.audioStatus = '../assets/audio/dont_send.mp3';
                        this.message = 'Don\'t Send ' + this.searchTrackingCode;
                    }
                    setTimeout(() => {
                        this.audioStatus = null;
                        this.message = null;
                    }, 1000);

                    console.log(data);
                    if (data.status === 404) {
                        this.message = data.message;
                        this.audioStatus = '../assets/audio/notFound.mp3';
                        setTimeout(() => {
                            this.message = '';
                        }, 1000);
                    } else if (data.state === 'dont send') {
                        if (data.order.user_code.split('RU')[1].charAt(0) === '0') {
                            this.audioStatus = '../assets/audio/dont_send0.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '1') {
                            this.audioStatus = '../assets/audio/dont_send1.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '2') {
                            this.audioStatus = '../assets/audio/dont_send2.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '3') {
                            this.audioStatus = '../assets/audio/dont_send3.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '4') {
                            this.audioStatus = '../assets/audio/dont_send4.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '5') {
                            this.audioStatus = '../assets/audio/dont_send5.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '6') {
                            this.audioStatus = '../assets/audio/dont_send6.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '7') {
                            this.audioStatus = '../assets/audio/dont_send7.mp3';
                            this.alertSuccess = false;
                        } else if (data.order.user_code.split('RU')[1].charAt(0) === '8') {
                            this.audioStatus = '../assets/audio/dont_send8.mp3';
                            this.alertSuccess = false;
                        } else {
                            this.audioStatus = '../assets/audio/dont_send9.mp3';
                            this.alertSuccess = false;
                        }
                        // this.audioStatus = '../assets/audio/dont_send.mp3';
                        this.message = `Don't Send ${data.order.client_fullname ? data.order.client_fullname : data.order.orderer_info} ${data.order.tracking_code}`;

                        setTimeout(() => {
                            this.audioStatus = '';
                        }, 2500);
                    } else if (data.state === 'send') {

                        if (data.order.activated === '1') {
                            this.finalOrders.map((item, index) => {
                                if (item.tracking_code.toUpperCase() === this.searchTrackingCode.toUpperCase()) {
                                    item.yellowRow = true;
                                    this.lastSixDigit = item.tracking_code.slice(-6);
                                    this.prebox.map((i, idx) => {
                                        if (idx === index) {
                                            i.nativeElement.click();
                                        }
                                    });
                                }
                            });
                            // setTimeout(() => {
                            //     this.printBtn.map(item => {
                            //         if (+item.nativeElement.id === +data.order.id) {
                            //             item.nativeElement.click();
                            //         }
                            //     });
                            // }, 1500);
                        } else {
                            this.message = 'OK' + data.order.client_fullname;
                            this.audioStatus = '../assets/audio/ok.mp3';
                            // this.alertSuccess = false;
                            setTimeout(() => {
                                this.audioStatus = null;
                                this.message = null;
                            }, 1500);
                        }
                    } else if (data.state === 'old item') {
                        this.audioStatus = '../assets/audio/oldItem.mp3';
                        this.alertSuccess = false;
                        this.message = 'Old Item';
                        setTimeout(() => {
                            this.audioStatus = null;
                        }, 1500);
                    } else {
                        if (data.status === 400) {
                            this.message = 'Service';
                            this.audioStatus = '../assets/audio/service.mp3';
                        }
                        if (data.order.activated !== '2') {
                            this.finalOrders.map((item, index) => {
                                if (item.tracking_code === this.searchTrackingCode) {
                                    item.yellowRow = true;

                                    this.prebox.map((i, idx) => {
                                        if (idx === index) {
                                            i.nativeElement.click();
                                        }
                                    });
                                }
                                // setTimeout(() => {
                                // if (data.order.orderer_info === item.orderer_info) {
                                //     this.scanedOrder.push(item);
                                // }
                                // });
                            });
                        } else {
                            this.finalOrders.filter(item => data.order.orderer_info === item.orderer_info ? this.scanedOrder.push(item) : null);
                            this.alertSuccess = true;
                            this.audioStatus = '../assets/audio/ok.mp3';
                            this.message = 'OK' + data.order.client_fullname;
                        }
                        setTimeout(() => {
                            this.audioStatus = null;
                        }, 1500);
                    }
                });
                setTimeout(() => {

                    this.tracking_code.nativeElement.value = '';

                }, 1000);
            } else {
                this.staticCode = true;
            }
        }
    }


    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.ctrlKey && event.keyCode === 80 && this.scanedOrder.length) {
            event.preventDefault();
            // @ts-ignore
            document.querySelector(`#print-scaned-section${this.searchTrackingCode}`).click();
        }
    }


    //
    // setPreBoxScaned(order, event) {
    //     this.setPreBox(order, event)
    //
    // }

    printTable(finalOrders) {
        // window.print();
        finalOrders = this.filter.transform(finalOrders, this.activeFilter);
        let myWindow = window.open('', 'PRINT');
        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>table, th, td { border: 1px solid black; border-collapse: collapse;}</style>');
        myWindow.document.write('</head><body >');
        myWindow.document.write('<table style="width: 100%; text-align: center">');
        myWindow.document.write('<thead>\n' +
            '                        <tr>\n' +
            '                            <th>X</th>\n' +
            '                            <th>Recipient</th>\n' +
            '                            <th>User</th>\n' +
            '                            <th>Order Tracking</th>\n' +
            '                            <th>Weight</th>\n' +
            '                            <th>Order Group Tracking</th>\n' +
            '                        </tr>\n' +
            '                        </thead>');
        myWindow.document.write('<tbody>');
        for (let el of finalOrders) {
            let special = el.activated == '2' ? '*' : ' ';
            myWindow.document.write('<tr><td>' + special + '</td>' +
                '<td>' + el.recipient_full_name + '</td><td>' + el.orderer_info + '</td><td>' + el.tracking_code + '</td>' +
                '<td>' + el.weight + '</td><td>' + el.order_group_tracking_code + '</td></tr>');
        }
        myWindow.document.write('</tbody>');
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        // myWindow.close();

        return true;
    }

    exportOrders(activeTab) {
        const newData = {
            'activated': activeTab === 'all' ? '' : activeTab
        };
        window.open(`${environment.API_ENDPOINT_ROOT}/ruOrders/export?activated=${newData.activated}`);
    }

}
