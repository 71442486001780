import { Component, OnInit } from '@angular/core';
import {ShelfService} from '../../_services/shelf.service';

@Component({
  selector: 'app-create-shelf',
  templateUrl: './create-shelf.component.html',
  styleUrls: ['./create-shelf.component.scss']
})
export class CreateShelfComponent implements OnInit {
  showLoading = false;
  ShelfDetail = [];
  sh_code: string;
  errorMessage: string;
  shelves = [];
  constructor(private shelfService: ShelfService) { }
  ngOnInit() {
    this.getShelves();
  }

  create(f) {
    if (f.valid) {
      f.value.shelf_box = f.value.shelf.concat(f.value.row, f.value.col).toUpperCase();
      this.shelfService.createShelf(f.value).subscribe(
        r => {
          // @ts-ignore
          this.shelves.push(r.data);
        },
        e => {
          console.log('error: ', e);
          this.errorMessage = e.statusText + ' ' + e.error.shelf_box;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2500);
        }
      );
    }
  }

  getShelves() {
    this.shelfService.getShelves().subscribe(
      r => {
        if (r.status === 'ok') {
          this.shelves = r.data;
        }
      },
      e => {
        console.log(e);
      }
    );
  }
  getShelfDetails(value) {
    this.shelfService.getShelfDetails({'shelf': value}).subscribe(
      r => {
        if (r.status == 'ok') {
          this.ShelfDetail = r.dataResponse;
        } else {
          this.errorMessage = r.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2500);
        }
      },
      e => {
        console.log(e);
      }
    );
  }
  barcode (value) {
    let barcodeSymbols = '^';
    // console.log(value, barcodeSymbols, barcodeSymbols.split("").reverse().join(""));
    let fullBarcodeName = barcodeSymbols + value;
    // console.log(fullBarcodeName);
    // console.log(encodeURIComponent(fullBarcodeName));
    // console.log(value);
    window.open("http://warehouseback.onex.am/api/print_lable/" + encodeURIComponent(fullBarcodeName));
    // window.open("http://warehouseback.onex.am/api/lable_print_code/" + encodeURIComponent(fullBarcodeName));
    // window.open("http://127.0.0.1:8000/api/generateShelfBarcode/" + fullname);
  }
}
