import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UserService } from "src/app/_services";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { UserSetModalComponent } from "./user-set-modal/user-set-modal.component";

@Component({
  selector: "app-user-set",
  templateUrl: "./user-set.component.html",
  styleUrls: ["./user-set.component.css"],
})
export class UserSetComponent implements OnInit {
  @ViewChild("trackingcode") inputText;
  @ViewChild("user_code") userCodeInput: any;
  @ViewChild("weight") weightInput: any;
  @ViewChild("save") saveBtn: any;
  @ViewChild("comment") comment: any;
  @ViewChild("dItem") dItem: any;
  @ViewChild("openModal") openModal: ElementRef;
  @ViewChild("f") form: ElementRef;
  public audioStatus;
  user;
  public message;
  public weightLenghtMessage = false;
  public bottom = "bottom";
  public top = "top";
  public left = "left";
  public center = "center";
  public mTop = 20;
  public mBottom = 1;
  public sizetwo = 50;
  public fsize = 12;
  public sizeBarcode = 1.5;
  public scannedStatus: boolean;
  public isWithVolumeWeight = false;
  public loading: boolean;
  public generated = false;
  public disableBtn = true;
  public defaultService: string;
  public defaultServiceModal = false;
  public checkUnknown: string;
  public str = "";
  public weightValue = 0;
  public userSetModal = true;
  order: any = {};
  autoScan = false;
  data;
  user_id;
  trackingcode: any = {};
  chack_ok = 0;
  showtracking;
  chack_data;
  chack_messege;
  ordereddata = [];
  orderglobaldata = [];
  fullname;
  applay_data;
  showLoading = false;
  p_width;
  p_length;
  p_height;
  arm_clients = [];
  ru_clients = [];
  ga_clients = [];
  kg_clients = [];
  ge_amanati = [];
  ua_clients = [];
  ge_space_cargo_clients = [];
  fullNameForLabel;
  disabled = false;
  isGermany: boolean;
  commentDisabled: boolean;
  public answer = false;
  ru_categories;
  selectedCategoriesIds: any[] = [];
  selectedItems: any[] = [];
  isTrack = false;

  constructor(
    private _userService: UserService,
    private _router: Router,
    public dialog: MatDialog
  ) {
    try {
      this.order.user_code =
        localStorage.getItem("detectUser") === "11" ? "KG" : "ARM";
      this.order.code =
        localStorage.getItem("detectUser") === "11" ? "KG" : "ARM";
      this.isGermany = localStorage.getItem("detectUser") === "6";
    } catch (e) {
      this.order.user_code = "ARM";
      this.order.code = "ARM";
    }
  }

  @HostListener("keydown.tab", ["$event"])
  onTab(event: KeyboardEvent) {
    if (event.target == this.userCodeInput.nativeElement) {
      this.weightInput.nativeElement.focus();
      this.weightInput.nativeElement.select();
      event.preventDefault();
      return false;
    }
    if (event.target == this.weightInput.nativeElement) {
      if (
        this.weightInput.nativeElement.value === "0" ||
        !this.weightInput.nativeElement.value
      ) {
        return false;
      } else {
        if (this.checkUnknown || this.checkUnknown !== undefined) {
          if (
            this.checkUnknown.includes("Unknown") ||
            this.checkUnknown.includes("System System USC0")
          ) {
            this.comment.nativeElement.focus();
            this.comment.nativeElement.select();
            event.preventDefault();
            return false;
          } else {
            this.saveBtn.nativeElement.focus();
            this.saveBtn.nativeElement.select();
            event.preventDefault();
            return false;
          }
        } else {
          this.saveBtn.nativeElement.focus();
          this.saveBtn.nativeElement.select();
          event.preventDefault();
          return false;
        }
      }
    }
    if (event.target == this.comment.nativeElement) {
      if (
        this.comment.nativeElement.value === " " ||
        !this.comment.nativeElement.value
      ) {
        if (
          this.checkUnknown.includes("Unknown") ||
          this.checkUnknown.includes("System System USC0")
        ) {
          this.commentDisabled = false;
          this.disableBtn = false;
          return false;
        } else {
          this.commentDisabled = true;
          this.saveBtn.nativeElement.focus();
          this.saveBtn.nativeElement.select();
          event.preventDefault();
          return false;
        }
      } else {
        this.commentDisabled = true;
        this.saveBtn.nativeElement.focus();
        this.saveBtn.nativeElement.select();
        event.preventDefault();
        return false;
      }
    }
  }

  onCategoriesSelected(selectedCategories: any[]) {
    this.selectedCategoriesIds = selectedCategories.map((el) => el.id);
    // Do something with the selected categories in the parent component
  }

  @HostListener("keyup.enter", ["$event"])
  onEnter(event: KeyboardEvent) {
    if (event.target == this.inputText.nativeElement) {
      this.userCodeInput.nativeElement.focus();
      event.preventDefault();
      return false;
    }
    if (event.target == this.weightInput.nativeElement) {
      event.preventDefault();
      if (
        this.weightInput.nativeElement.value === "0" ||
        !this.weightInput.nativeElement.value
      ) {
        return false;
      } else {
        if (
          this.checkUnknown.includes("Unknown") ||
          this.checkUnknown.includes("System System USC0")
        ) {
          this.comment.nativeElement.focus();
          this.comment.nativeElement.select();
          event.preventDefault();
          return false;
        } else {
          this.userSet(this.form);
          event.preventDefault();
          return false;
        }
      }
    }
  }

  @HostListener("keyup.arrowDown", ["$event"])
  onArrowDown(event: KeyboardEvent) {
    if (event.target == this.userCodeInput.nativeElement) {
      if (
        this.arm_clients.length > 0 ||
        this.ru_clients.length > 0 ||
        this.ga_clients.length > 0 ||
        this.kg_clients.length > 0 ||
        this.ge_amanati.length > 0 ||
        this.ua_clients.length > 0 ||
        this.ge_space_cargo_clients.length > 0
      ) {
        this.dItem.nativeElement
          .getElementsByClassName("dropdown-item")[0]
          .focus();
        event.preventDefault();
        return false;
      }
    }
  }

  @HostListener("keyup.arrowUp", ["$event"])
  onArrowUp(event: KeyboardEvent) {
    if (
      this.arm_clients.length > 0 ||
      this.ru_clients.length > 0 ||
      this.ga_clients.length > 0 ||
      this.kg_clients.length > 0 ||
      this.ge_amanati.length > 0 ||
      this.ua_clients.length > 0 ||
      this.ge_space_cargo_clients.length > 0
    ) {
      if (
        event.target ==
        this.dItem.nativeElement.getElementsByClassName("dropdown-item")[0]
      ) {
        this.userCodeInput.nativeElement.focus();
        event.preventDefault();
        return false;
      }
    }
  }

  // private checkDispatch(): string {
  //   return localStorage.getItem('dispatch');
  // }
  //
  // private setDispatch(val): string {
  //   localStorage.setItem('dispatch', val);
  //   return this.checkDispatch();
  // }

  ngOnInit() {
    let mode = localStorage.getItem("autoScan");
    this.autoScan = mode == "on";
    this.user = localStorage.getItem("detectUser");
    this.order.dispatch = this.user == 9 || this.user == 10 ? 1 : 0;
    if (this.user == 9 || this.user == 10) {
      this.order.user_code = "GD";
    }

    if (this.user == 3) {
      this._userService.getRuCategories().subscribe((data) => {
        this.ru_categories = data;
      });
    }
  }

  calc(name, value) {
    switch (name) {
      case "width":
        this.p_width = value;
        break;
      case "length":
        this.p_length = value;
        break;
      case "height":
        this.p_height = value;
        break;
    }
    this.checkCalc(this.p_width, this.p_length, this.p_height);
  }

  checkCalc(w, l, h) {
    if (
      w != undefined &&
      w != "" &&
      l != undefined &&
      l != "" &&
      h != undefined &&
      h != ""
    ) {
      let vWeight;
      if (
        this.userCodeInput.nativeElement.value &&
        (this.userCodeInput.nativeElement.value.includes("ARM") ||
          this.userCodeInput.nativeElement.value.includes("MT"))
      ) {
        vWeight = 5000;
      } else {
        vWeight = 6000;
      }
      let len = (w * l * h) / vWeight;
      if (len.toString().includes(".")) {
        let str = len.toString();
        let splited = str.split(".");
        if (splited[1].length > 4) {
          splited[1] = splited[1].substring(0, 2);
          this.order.v_waight = +splited.join(".");
        } else {
          this.order.v_waight = +str;
        }
      } else {
        this.order.v_waight = len;
      }
    } else {
      this.order.v_waight = "";
    }
  }

  checkTheTrackingCode(trackingCode) {
    this.isTrack = true;
    // if (!this.isGermany) {
    this.message = null;
    this.audioStatus = null;
    // this.ok = null;
    this.p_width = null;
    this.p_length = null;
    this.p_height = null;
    let defaultData = { tracking_code: trackingCode };
    let withAutoScan = {
      tracking_code: trackingCode,
      auto_scan: this.autoScan,
    };
    let dataToReq = this.autoScan ? withAutoScan : defaultData;
    if (trackingCode) {
      this.showLoading = true;
      this._userService.checkOrder(dataToReq).subscribe(
        (data) => {
          if (data.status == 200) {
            if (data.details_am) {
              this.checkUnknown = data.details_am.client_fullname;
            }
            this.scannedStatus = true;
            /*
             * TODO add ukraine and space cargo expected
             * */
            if (data.georgia_amanati) {
              this.fullNameForLabel = data.georgia_amanati.client_fullname;
              this.order.user_code = data.georgia_amanati.user_code;
              this.order.code = "GE";
              this.weightInput.nativeElement.focus();
              this.weightInput.nativeElement.select();
              this.checkAudioStatus(7, "Expected");
            } else if (data.georgia_space_cargo) {
              this.fullNameForLabel = data.georgia_space_cargo.client_fullname;
              this.order.code = "SC";
              this.order.user_code =
                this.order.code + "" + data.georgia_space_cargo.user_code;
              this.weightInput.nativeElement.focus();
              this.weightInput.nativeElement.select();
              this.checkAudioStatus(7, "Expected");
            } else if (data.ukraine) {
              this.fullNameForLabel = data.ukraine.client_fullname;
              this.order.user_code = data.ukraine.user_code;
              this.order.code = "UA";
              this.weightInput.nativeElement.focus();
              this.weightInput.nativeElement.select();
              this.checkAudioStatus(7, "Expected");
            } else {
              if (
                data.onex_global !== null &&
                data.onex_am !== null &&
                data.georgia_onex &&
                data.onex_am.data != ""
              ) {
                console.log("found all data");
              } else {
                switch (true) {
                  case data.onex_am !== null && data.onex_am.data != "":
                    let fullname =
                      data.onex_am.data[0].recipient.length > 11
                        ? data.onex_am.data[0].recipient
                        : data.onex_am.data[0].firstname +
                          " " +
                          data.onex_am.data[0].lastname +
                          " " +
                          data.onex_am.data[0].user_code;
                    // this.order.dispatch = data.onex_am.data[0].dispatch;
                    if (+data.onex_am.data[0].dispatch == 2) {
                      this.checkAudioStatus(9, "dispatch Marine");
                      data.onex_am.data[0].user_code = data.onex_am.data[0].user_code
                        .replace("ARM", "MT")
                        .toUpperCase();
                      fullname = this.breakingFullName(fullname, "ARM", "MT");
                    } else if (+data.onex_am.data[0].dispatch == 1) {
                      this.checkAudioStatus(14, "dispatch Ground");
                      data.onex_am.data[0].user_code = data.onex_am.data[0].user_code
                        .replace("ARM", "GD")
                        .toUpperCase();
                      fullname = this.breakingFullName(fullname, "ARM", "GD");
                    } else {
                      /** TODO test this case */
                      if (
                        data.onex_am.data[0].is_person &&
                        data.onex_am.data[0].is_person !== "1"
                      ) {
                        this.checkAudioStatus(6, "Company");
                      } else {
                        this.defaultService = data.onex_am.data[0]
                          .default_service
                          ? data.onex_am.data[0].default_service
                          : null;
                        this.checkAudioStatus(7, "Expected");
                      }
                    }
                    this.order.dispatch = +data.onex_am.data[0].dispatch;
                    this.fullNameForLabel = fullname;
                    this.order.user_code = data.onex_am.data[0].user_code;
                    this.order.code = "ARM";
                    this.weightInput.nativeElement.focus();
                    this.weightInput.nativeElement.select();
                    break;
                  case data.onex_global !== null:
                    this.fullNameForLabel =
                      data.onex_global.recipients[0].full_name +
                      " " +
                      data.onex_global.recipients[0].address2;
                    this.order.user_code =
                      data.onex_global.recipients[0].address2;
                    if (+data.onex_global.orders[0].dispatch == 9) {
                      this.checkAudioStatus(9, "dispatch Ground");
                      data.onex_global.recipients[0].address2 = data.onex_global.recipients[0].address2
                        .replace("RU", "RGD")
                        .toUpperCase();
                      this.fullNameForLabel = this.breakingFullName(
                        this.fullNameForLabel,
                        "RU",
                        "RGD"
                      );
                      this.order.user_code = data.onex_global.recipients[0].address2
                        .replace("RU", "RGD")
                        .toUpperCase();
                    }
                    this.order.code = "RU";
                    this.checkAudioStatus(7, "Expected");
                    this.weightInput.nativeElement.focus();
                    this.weightInput.nativeElement.select();
                    break;
                  case data.georgia_onex !== null:
                    this.fullNameForLabel = data.georgia_onex.client_fullname;
                    this.order.user_code = data.georgia_onex.user_code;
                    if (+data.georgia_onex.dispatch == 1) {
                      this.checkAudioStatus(9, "dispatch Ground");
                      data.georgia_onex.user_code = data.georgia_onex.user_code
                        .replace("GA", "GG")
                        .toUpperCase();
                      fullname = this.breakingFullName(
                        this.fullNameForLabel,
                        "GA",
                        "GG"
                      );
                    }
                    this.order.code = "GA";
                    this.checkAudioStatus(7, "Expected");
                    this.weightInput.nativeElement.focus();
                    this.weightInput.nativeElement.select();
                    break;
                  case data.kyrgyzstan_onex !== null:
                    this.fullNameForLabel =
                      data.kyrgyzstan_onex.client_fullname;
                    this.order.user_code = data.kyrgyzstan_onex.user_code;
                    this.order.code = "KG";
                    this.order.dispatch = 0;
                    this.checkAudioStatus(7, "Expected");
                    this.weightInput.nativeElement.focus();
                    this.weightInput.nativeElement.select();
                    break;
                  case data.ukraine !== null:
                    //
                    this.fullNameForLabel = data.ukraine.client_fullname;
                    this.order.user_code = data.ukraine.user_code;
                    this.order.code = "UA";
                    break;
                  default:
                    console.log("default: ", data);
                }
              }
            }
          }
          if (data.status == 403) {
            if (data.service === 4) {
              this.checkAudioStatus(11, data.message);
            } else if (data.service === 9) {
              this.checkAudioStatus(12, data.message);
            } else if (data.service === 5) {
              this.checkAudioStatus(13, data.message);
            } else {
              this.checkAudioStatus(5, data.message);
            }
          }
          if (data.status === 402) {
            this.scannedStatus = true;
            this.checkAudioStatus(8, data.message);
          }
          if (data.status === 404) {
            this.checkAudioStatus(2, data.message);
          }

          if (data.print_label) {
            setTimeout(() => {
              this.checkAudioStatus(10, "Print label");
              this.companyPopup();
            }, 1500);
          }
          this.showLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    // }
  }

  userSet(f) {
    if (this.user == 3 && this.selectedCategoriesIds) {
      if (this.selectedCategoriesIds.length) {
        f.value.ru_categories = this.selectedCategoriesIds;
      }
    }

    if (this.weightValue >= 10) {
      const dialogRef = this.dialog.open(UserSetModalComponent, {
        width: "500px",
        panelClass: "custom-dialog-container",
        data: this.userSetModal,
      });
      dialogRef.afterClosed().subscribe((datas) => {
        if (datas.doneORno === "Yes") {
          if (this.checkUnknown) {
            if (
              this.checkUnknown.includes("Unknown") ||
              this.checkUnknown.includes("System System USC0")
            ) {
              this.disableBtn = false;
            } else {
              this.disableBtn = true;
            }
          } else {
            this.disableBtn = true;
          }
          if (this.comment.nativeElement.value === "") {
            this.commentDisabled = undefined;
          } else {
            this.commentDisabled = true;
          }
          if (this.commentDisabled || this.disableBtn) {
            this.commentDisabled = false;
            // if (!this.isGermany) {
            this.message = null;
            this.audioStatus = null;
            this.disabled = true;
            this.arm_clients = [];
            this.ru_clients = [];
            this.ga_clients = [];
            this.kg_clients = [];
            this.ge_amanati = [];
            this.ua_clients = [];
            this.ge_space_cargo_clients = [];
            if (+f.value.dispatch == 2) {
              f.value.user_code = f.value.user_code
                .replace("MT", "ARM")
                .toUpperCase();
            }
            if (+f.value.dispatch == 1 && f.value.code === "ARM") {
              f.value.user_code = f.value.user_code
                .replace("GD", "ARM")
                .toUpperCase();
            }
            if (+f.value.dispatch == 1 && f.value.code === "GA") {
              f.value.user_code = f.value.user_code
                .replace("GG", "GA")
                .toUpperCase();
            }
            if (+f.value.dispatch == 1 && f.value.code === "RU") {
              f.value.user_code = f.value.user_code
                .replace("RGD", "RU")
                .toUpperCase();
            }
            f.value.user_id = this.user_id;
            if (
              (f.valid && f.value.user_code.length > 5 && f.value.waight > 0) ||
              f.value.code == "SC" ||
              f.value.code == "USC"
            ) {
              f.value.additional_cost =
                f.value.additional_cost == undefined
                  ? null
                  : f.value.additional_cost;
              f.value.dangerous =
                f.value.dangerous == undefined ? null : f.value.dangerous;
              f.value.user_code = f.value.user_code.toUpperCase();
              this.showLoading = true;

              this._userService.userSet(f.value).subscribe(
                (data) => {
                  this.data = data;
                  this.showLoading = false;
                  if (this.data.status == 200) {
                    let mode = localStorage.getItem("autoScan");
                    this.autoScan = mode == "on";
                    // this.message = this.data.message;
                    // this.ok = 1;
                    if (this.data.response.hasOwnProperty("wh")) {
                      this.checkAudioStatus(
                        1,
                        `${this.data.message} ${this.data.response.wh.data.client_full_name}`
                      );
                    } else {
                      this.checkAudioStatus(1, `${this.data.message}`);
                    }
                    f.reset({
                      user_code:
                        f.value.dispatch == 1
                          ? "GD"
                          : localStorage.getItem("detectUser") === "11"
                          ? "KG"
                          : "ARM",
                      code:
                        f.value.dispatch == 1
                          ? "GD"
                          : localStorage.getItem("detectUser") === "11"
                          ? "KG"
                          : "ARM",
                      autoscan: this.autoScan,
                      dispatch: f.value.dispatch == 1 ? "1" : "0",
                    });
                    this.selectedItems = [];
                    this.selectedCategoriesIds = [];
                    this.scannedStatus = false;
                    this.fullNameForLabel = null;
                    this.disabled = false;
                  } else if (this.data.status == 400) {
                    // this.message = this.data.message;
                    if (
                      this.data.client &&
                      this.data.e != undefined &&
                      this.data.e.code == "1001"
                    ) {
                      // this.ok = 7;
                      this.checkAudioStatus(3, this.data.message);
                    } else {
                      // this.ok = 2;
                      this.checkAudioStatus(4, this.data.message);
                    }
                    this.disabled = false;
                  }
                  this.inputText.nativeElement.focus();
                },
                (error) => {
                  // this.ok = 2;
                  // this.message = error.message;
                  console.log(error);
                  this.checkAudioStatus(4, error.message);
                  this.disabled = false;
                }
              );
            } else {
              // this.ok = 0;
              // this.message = 'invalid weight';
              this.checkAudioStatus(2, "invalid weight");
              this.disabled = false;
            }
            // };
            // this.ok = null;
          } else {
            this.commentDisabled = true;
            return false;
          }
        }
      });
    } else {
      if (this.checkUnknown) {
        if (
          this.checkUnknown.includes("Unknown") ||
          this.checkUnknown.includes("System System USC0")
        ) {
          this.disableBtn = false;
        } else {
          this.disableBtn = true;
        }
      } else {
        this.disableBtn = true;
      }
      if (this.comment.nativeElement.value === "") {
        this.commentDisabled = undefined;
      } else {
        this.commentDisabled = true;
      }
      if (this.commentDisabled || this.disableBtn) {
        this.commentDisabled = false;
        // if (!this.isGermany) {
        this.message = null;
        this.audioStatus = null;
        this.disabled = true;
        this.arm_clients = [];
        this.ru_clients = [];
        this.ga_clients = [];
        this.kg_clients = [];
        this.ge_amanati = [];
        this.ua_clients = [];
        this.ge_space_cargo_clients = [];
        if (+f.value.dispatch == 2) {
          f.value.user_code = f.value.user_code
            .replace("MT", "ARM")
            .toUpperCase();
        }
        if (+f.value.dispatch == 1 && f.value.code === "ARM") {
          f.value.user_code = f.value.user_code
            .replace("GD", "ARM")
            .toUpperCase();
        }
        if (+f.value.dispatch == 1 && f.value.code === "GA") {
          f.value.user_code = f.value.user_code
            .replace("GG", "GA")
            .toUpperCase();
        }
        if (+f.value.dispatch == 1 && f.value.code === "RU") {
          f.value.user_code = f.value.user_code
            .replace("RGD", "RU")
            .toUpperCase();
        }
        f.value.user_id = this.user_id;
        // return;
        if (
          (f.valid && f.value.user_code.length > 5 && f.value.waight > 0) ||
          f.value.code == "SC" ||
          f.value.code == "USC"
        ) {
          f.value.additional_cost =
            f.value.additional_cost == undefined
              ? null
              : f.value.additional_cost;
          f.value.dangerous =
            f.value.dangerous == undefined ? null : f.value.dangerous;
          f.value.user_code = f.value.user_code.toUpperCase();
          this.showLoading = true;

          this._userService.userSet(f.value).subscribe(
            (data) => {
              this.data = data;
              this.showLoading = false;
              if (this.data.status == 200) {
                let mode = localStorage.getItem("autoScan");
                this.autoScan = mode == "on";
                // this.message = this.data.message;
                // this.ok = 1;
                if (this.data.response.hasOwnProperty("wh")) {
                  this.checkAudioStatus(
                    1,
                    `${this.data.message} ${this.data.response.wh.data.client_full_name}`
                  );
                } else {
                  this.checkAudioStatus(1, `${this.data.message}`);
                }
                f.reset({
                  user_code:
                    this.user == 9 || this.user == 10
                      ? "GD"
                      : localStorage.getItem("detectUser") === "11"
                      ? "KG"
                      : "ARM",
                  code:
                    this.user == 9 || this.user == 10
                      ? "GD"
                      : localStorage.getItem("detectUser") === "11"
                      ? "KG"
                      : "ARM",
                  autoscan: this.autoScan,
                  dispatch: this.user == 9 || this.user == 10 ? "1" : "0",
                });
                this.selectedCategoriesIds = [];
                this.selectedItems = [];
                this.scannedStatus = false;
                this.fullNameForLabel = null;
                this.disabled = false;
              } else if (this.data.status == 400) {
                // this.message = this.data.message;
                if (
                  this.data.client &&
                  this.data.e != undefined &&
                  this.data.e.code == "1001"
                ) {
                  // this.ok = 7;
                  this.checkAudioStatus(3, this.data.message);
                } else {
                  // this.ok = 2;
                  this.checkAudioStatus(4, this.data.message);
                }
                this.disabled = false;
              }
              this.inputText.nativeElement.focus();
            },
            (error) => {
              // this.ok = 2;
              // this.message = error.message;
              console.log(error);
              this.checkAudioStatus(4, error.message);
              this.disabled = false;
            }
          );
        } else {
          // this.ok = 0;
          // this.message = 'invalid weight';
          this.checkAudioStatus(2, "invalid weight");
          this.disabled = false;
        }

        // };
        // this.ok = null;
      } else {
        this.commentDisabled = true;
        return false;
      }
    }
    this.isTrack = false;
  }

  setOrderCode(value) {
    this.str = value;

    this.order.user_code = value;
    if (
      this.order.user_code.substr(this.order.user_code.length - 6) === "delete"
    ) {
      this.order.user_code = "";
    }

    switch (true) {
      case this.str.startsWith("A") ||
        this.str.startsWith("M") ||
        this.str.startsWith("GD"):
        this.order.code = "ARM";
        if (this.str.startsWith("M")) {
          this.order.dispatch = "2";
        } else if (this.str.startsWith("G")) {
          this.order.dispatch = "1";
        } else {
          this.order.dispatch = "0";
        }
        break;
      case this.str.startsWith("R") || this.str.startsWith("RGD"):
        this.order.code = "RU";
        if (this.str.startsWith("RGD")) {
          this.order.dispatch = "1";
        } else {
          this.order.dispatch = "0";
        }
        break;
      case this.str.startsWith("GG") || this.str.startsWith("GA"):
        this.order.code = "GA";
        if (this.str.startsWith("GG")) {
          this.order.dispatch = "1";
        } else {
          this.order.dispatch = "0";
        }
        break;
      case this.str.startsWith("KG"):
        this.order.code = "KG";
        this.order.dispatch = "0";
        break;
      case this.str.startsWith("GE"):
        if (this.str.includes("10013")) {
          this.order.code = "UGE";
          this.order.dispatch = "0";
        } else {
          this.order.code = "GE";
          this.order.dispatch = "0";
        }
        break;
      case this.str.startsWith("SC"):
        this.order.code = "SC";
        this.order.dispatch = "0";
        break;
      case this.str.startsWith("UA"):
        if (this.str.includes("M")) {
          this.order.code = "UAM";
          this.order.dispatch = "0";
        } else {
          this.order.code = "UA";
          this.order.dispatch = "0";
        }
        break;
      case this.str.startsWith("UK"):
        if (this.str.includes("UKG")) {
          this.order.code = "UKG";
          this.order.dispatch = "0";
        } else {
          this.order.code = "KG";
          this.order.dispatch = "0";
        }
        break;
      default:
        this.order.dispatch = "0";
        this.order.code = "ARM";
    }
    if (value == "ARM267071") {
      this.order.code = "UARM";
    }
    if (value == "RU999999") {
      this.order.code = "URU";
    }
    if (value == "NL000000") {
      this.order.code = "NL";
    }
    if (value == "UA100000") {
      this.order.code = "UUA";
    }
    if (value == "GE000000") {
      this.order.code = "GE";
    }
    if (value == "USC0") {
      this.order.code = "USC";
    }
    if (value == "GA112233") {
      this.order.code = "UGA";
    }
    if (value == "KG112233") {
      this.order.code = "UKG";
    }
  }

  dispatchSelect(value) {
    this.order.dispatch = value;
    this.arm_clients = [];
    this.ga_clients = [];
    this.kg_clients = [];
    this.ru_clients = [];
    this.ua_clients = [];
    this.ge_amanati = [];
    this.ge_space_cargo_clients = [];
    if (this.user != 9) {
      if (value == 2) {
        this.order.user_code = "MT";
      } else if (value == 0) {
        this.order.user_code = "ARM";
      } else {
        this.order.user_code = "GD";
      }
    }
  }

  checkUserCode(us_code, code, $event) {
    let reg = /[a-z]{2,3}-[a-z]{4}/gm;

    // @ts-ignore
    if (reg.test($event.target.value)) {
      // @ts-ignore
      let userCode = event.target.value.match(reg)[0].toUpperCase();

      // @ts-ignore
      if (userCode.length < 8) {
        // @ts-ignore
        event.target.value =
          userCode.substr(0, 2).toUpperCase() == "AM"
            ? "ARM"
            : userCode.substr(0, 2).toUpperCase();
      } else {
        if (userCode.substr(0, 3).toUpperCase() == "UAM") {
          // @ts-ignore
          event.target.value =
            userCode.substr(0, 3).toUpperCase() == "UAM"
              ? "ARM267071"
              : userCode.substr(0, 3).toUpperCase();
        }
        if (userCode.substr(0, 3).toUpperCase() == "UGE") {
          // @ts-ignore
          event.target.value =
            userCode.substr(0, 3).toUpperCase() == "UGE"
              ? "GE10013"
              : userCode.substr(0, 3).toUpperCase();
        }
        if (userCode.substr(0, 3).toUpperCase() == "UGA") {
          // @ts-ignore
          event.target.value =
            userCode.substr(0, 3).toUpperCase() == "UGA"
              ? "GA112233"
              : userCode.substr(0, 3).toUpperCase();
        }
        if (userCode.substr(0, 3).toUpperCase() == "UKG") {
          // @ts-ignore
          event.target.value =
            userCode.substr(0, 3).toUpperCase() == "UKG"
              ? "KG112233"
              : userCode.substr(0, 3).toUpperCase();
        }
      }
      // @ts-ignore
      this.setOrderCode(event.target.value);
    }
    this.arm_clients = [];
    this.ru_clients = [];
    this.ga_clients = [];
    this.kg_clients = [];
    let ln = code == "ARM" ? 7 : 6;
    if (us_code.startsWith("MT", 0)) {
      us_code = us_code.replace("MT", "ARM").toUpperCase();
    }
    if (us_code.startsWith("GD", 0)) {
      us_code = us_code.replace("GD", "ARM").toUpperCase();
    }
    if (us_code.startsWith("GG", 0)) {
      us_code = us_code.replace("GG", "GA").toUpperCase();
    }
    if (us_code.startsWith("RGD", 0)) {
      us_code = us_code.replace("RGD", "RU").toUpperCase();
    }
    if (this.scannedStatus) {
      if (us_code.length >= ln || code === "SC") {
        this.showLoading = true;
        this._userService
          .checkUserCode({ user_code: us_code, code: code })
          .subscribe(
            (d) => {
              if (d.status == 200) {
                if (d.arm_data != undefined && d.arm_data.length > 0) {
                  if (this.order.dispatch == 2) {
                    d.arm_data = d.arm_data.map((item) => {
                      const splittedName = item.fullname.split(" ");
                      splittedName[splittedName.length - 1] = splittedName[
                        splittedName.length - 1
                      ]
                        .replace("ARM", "MT")
                        .toUpperCase();
                      item.fullname = splittedName.join(" ");
                      return item;
                    });
                  }
                  if (this.order.dispatch == 1) {
                    d.arm_data = d.arm_data.map((item) => {
                      const splittedName = item.fullname.split(" ");
                      splittedName[splittedName.length - 1] = splittedName[
                        splittedName.length - 1
                      ].replace("ARM", "GD");
                      item.fullname = splittedName.join(" ");
                      return item;
                    });
                  }
                  this.arm_clients = d.arm_data;
                }
                if (d.ru_data != undefined && d.ru_data.length > 0) {
                  if (this.order.dispatch == 1) {
                    d.ru_data = d.ru_data.map((item) => {
                      const splittedName = (
                        item.full_name +
                        " " +
                        item.address2
                      ).split(" ");
                      splittedName[splittedName.length - 1] = splittedName[
                        splittedName.length - 1
                      ]
                        .replace("RU", "RGD")
                        .toUpperCase();
                      item.full_name = splittedName.join(" ");
                      return item;
                    });
                  }
                  this.ru_clients = d.ru_data;
                }
                if (d.ge_amanati != undefined && d.ge_amanati.length > 0) {
                  this.ge_amanati = d.ge_amanati;
                }
                if (d.ua_data != undefined && d.ua_data.success) {
                  this.ua_clients = d.ua_data.recipients;
                }
                if (d.space_cargo != undefined) {
                  this.ge_space_cargo_clients = d.space_cargo;
                }
                if (d.onex_georgia != undefined && d.onex_georgia.length > 0) {
                  if (this.order.dispatch == 1) {
                    d.onex_georgia = d.onex_georgia.map((item) => {
                      const splittedName = (
                        item.full_name +
                        " " +
                        item.user_code
                      ).split(" ");
                      splittedName[splittedName.length - 1] = splittedName[
                        splittedName.length - 1
                      ].replace("GA", "GG");
                      item.full_name = splittedName.join(" ");
                      return item;
                    });
                  }
                  this.ga_clients = d.onex_georgia;
                }
                if (
                  d.onex_kyrgyzstan != undefined &&
                  d.onex_kyrgyzstan.length > 0
                ) {
                  this.kg_clients = d.onex_kyrgyzstan;
                }
              }
              this.showLoading = false;
            },
            (e) => {
              console.log(e);
            }
          );
      } else {
        this.arm_clients = [];
        this.ga_clients = [];
        this.kg_clients = [];
        this.ru_clients = [];
        this.ua_clients = [];
        this.ge_amanati = [];
        this.ge_space_cargo_clients = [];
      }
    } else {
      this.checkAudioStatus(8, "first check the tracking code!");
    }
    // }
  }

  public selectUserCode() {
    this.userCodeInput.nativeElement.focus();
    this.checkUnknown = null;
    this.commentDisabled = false;
    this.disableBtn = true;
  }

  selectedClient(
    id,
    recipient,
    fullName,
    is_person,
    us_code,
    dest,
    defaultService
  ) {
    if (this.user == 10) {
      this.order.dispatch = 1;
    }

    if (us_code === "KG") {
      this.order.dispatch = 0;
    }

    if (this.user == 10 && !this.isTrack) {
      this.checkAudioStatus(8, "first check the tracking code!");
      return;
    }
    this.weightInput.nativeElement.focus();
    this.checkUnknown = fullName;
    this.message = null;
    this.audioStatus = null;
    this.disableBtn = false;
    // this.ok = null;
    if (is_person !== "1" && is_person !== 1 && is_person !== "") {
      this.checkAudioStatus(6, "Company");
    }
    this.fullNameForLabel = fullName;
    let armPos;
    let user_code;
    if (
      id !== "12244" &&
      id !== "17" &&
      id !== "1085" &&
      id !== "4903" &&
      id !== "1004" &&
      id !== "1004" &&
      id !== "0" &&
      id !== "1"
    ) {
      if (this.order.dispatch == 2) {
        armPos = fullName.lastIndexOf("MT");
        fullName = this.breakingFullName(fullName, "MT", "ARM");
        user_code = fullName.slice(armPos);
      } else if (this.order.dispatch == 1 && us_code === "ARM") {
        if (this.user != 10) {
          armPos = fullName.lastIndexOf("GD");
          fullName = this.breakingFullName(fullName, "GD", "ARM");
          user_code = fullName.slice(armPos);
        } else {
          const splitted = fullName.split(" ");
          const code = splitted[splitted.length - 1].slice(0, 2);
          if (code.startsWith("GD")) {
            armPos = fullName.lastIndexOf("GD");
            fullName = this.breakingFullName(fullName, "GD", "ARM");
            user_code = fullName.slice(armPos);
          } else {
            armPos = fullName.lastIndexOf("ARM");
            user_code = fullName.slice(armPos);
          }
        }
      } else if (this.order.dispatch == 1 && us_code === "GA") {
        armPos = fullName.lastIndexOf("GG");
        fullName = this.breakingFullName(fullName, "GG", "GA");
        user_code = fullName.slice(armPos);
      } else if (this.order.dispatch == 1 && us_code === "RU") {
        armPos = fullName.lastIndexOf("RGD");
        fullName = this.breakingFullName(fullName, "RGD", "RU");
        user_code = fullName.slice(armPos);
      } else {
        armPos = fullName.lastIndexOf(us_code);
        user_code = fullName.slice(armPos);
      }
    } else {
      armPos = fullName.lastIndexOf(us_code);
      user_code = fullName.slice(armPos);
    }

    this.defaultService = defaultService;

    if (this.order.tracking_code != undefined) {
      this.order.code = us_code;
      let dataToSend = {
        dest: dest,
        dispatch: `${this.order.dispatch}`,
        user_id: id,
        user_code: user_code,
        is_person: is_person,
        recipient: fullName,
        defaultService: defaultService,
        idrecipient: recipient,
        trackingcode: this.order.tracking_code,
      };
      this.user_id = id;
      if (dest != "NL") {
        this._userService.setClientInfo(dataToSend).subscribe(
          (r) => {
            this.arm_clients = [];
            this.ga_clients = [];
            this.ru_clients = [];
            if (this.order.dispatch == 2) {
              fullName = this.breakingFullName(fullName, "ARM", "MT");
              user_code = fullName.slice(armPos);
            }
            if (this.order.dispatch == 1 && us_code === "ARM") {
              fullName = this.breakingFullName(fullName, "ARM", "GD");
              user_code = fullName.slice(armPos);
            }
            if (this.order.dispatch == 1 && us_code === "GA") {
              fullName = this.breakingFullName(fullName, "GA", "GG");
              user_code = fullName.slice(armPos);
            }
            if (this.order.dispatch == 1 && us_code === "RU") {
              fullName = this.breakingFullName(fullName, "RU", "RGD");
              user_code = fullName.slice(armPos);
            }

            this.order.user_code = user_code;
          },
          (e) => {
            console.log(e);
          }
        );
      }
    } else {
      if (dest != "NL") {
        this.checkAudioStatus(8, "first check the tracking code!");
      }
    }
    // let user_code = armPos.slice(armPos, fullName.length);
  }

  /*
   * auto scan on|off toggle function
   * */
  changeMode() {
    let auto = this.autoScan ? "on" : "off";
    localStorage.setItem("autoScan", auto);
  }

  printLastDigits(t_code) {
    if (
      t_code != undefined &&
      this.fullNameForLabel != undefined &&
      this.fullNameForLabel.length > 7
    ) {
      window.open(
        "https://onex.am/api/pdflabel?tcode=" +
          t_code +
          "&name=" +
          this.fullNameForLabel
      );
    } else {
      alert("tracking code and user code fields are required");
    }
    this.generated = false;
  }

  generateTracking(key = "RUBA") {
    // if (!this.isGermany) {
    this.showLoading = true;
    this._userService.getTracking().subscribe(
      (data) => {
        if (key === "POSI") {
          const code = data.tracking_code.slice(4);
          this.order.tracking_code = "POSI" + code;
        } else {
          this.order.tracking_code = data.tracking_code;
        }
        if (data.status == 200) {
          this.autoScan = true;
          this.checkTheTrackingCode(data.tracking_code);

          this.userCodeInput.nativeElement.focus();
          this.generated = true;
          // this._userService.createScanOrder(data.tracking_code).subscribe();
        }
        this.showLoading = false;
      },
      (error) => console.log(error)
    );
    // }
  }

  enterChack(trackingcode) {
    // this.ok = null;
    // if (!this.isGermany) {
    this.message = null;
    this.audioStatus = null;
    this.showLoading = true;
    // this.trackingcode.tracking_code = trackingcode;
    this._userService.checkOrder({ tracking_code: trackingcode }).subscribe(
      (data) => {
        this.chack_data = data;
        if (data.onex_global != null && data.onex_am != null) {
          this.ordereddata = data.onex_am.data;
          this.orderglobaldata = data.onex_global.orders;
          this.fullname = data.onex_global.recipients;
        } else {
          if (
            this.chack_data.status == 200 &&
            this.chack_data.onex_am.data != ""
          ) {
            this.showtracking = trackingcode;
            this.chack_messege = "The tracking code has been found in ";
            this.chack_ok = 1;
            this.ordereddata = this.chack_data.onex_am.data;
          } else if (
            this.chack_data.onex_global != null &&
            this.chack_data.onex_global.orders != ""
          ) {
            this.showtracking = trackingcode;
            this.chack_messege = "The tracking code has been found in ";
            this.chack_ok = 1;
            this.orderglobaldata = this.chack_data.onex_global.orders;
            this.fullname = this.chack_data.onex_global.recipients;
          } else if (
            (this.chack_data.status == 200 &&
              this.chack_data.onex_am.data == "") ||
            this.chack_data.onex_global == null
          ) {
            this.showtracking = trackingcode;
            this.chack_messege = "The tracking code has not been found.";
            this.chack_ok = 2;
          }
        }
        // this.inputText.nativeElement.value = "";
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
    this.chack_ok = 0;
    // }
  }

  applyExpected(client_id, fullname, tracking, expected) {
    // if (!this.isGermany) {
    this.order.tracking_code = tracking;
    this.order.user_code = fullname;
    this.order.expected = expected;

    // this._userService.createexpectedtoorder(this.order).subscribe(
    //   data => {
    //     this.applay_data = data;
    //
    //     if (this.applay_data.status == 200) {
    //       this.chack_messege = this.applay_data.message;
    //       this.chack_ok = 1;
    //     } else if (this.applay_data.status == 400) {
    //       this.chack_messege = this.applay_data.message;
    //       this.chack_ok = 2;
    //     }
    //
    //   },
    //   error => {
    //     this.ok = 2;
    //     console.log(error);
    //   }
    // );
    // }
  }

  public closeModal(): void {
    this.defaultServiceModal = true;
  }

  // Func  for weight digit to  fixed 0.32321 -> 0.323   changeValueLenght($event)
  public changeValueLength($event) {
    this.weightValue = +$event.target.value;
    let tmp = $event.target.value + "";
    if (tmp.length > 5) {
      this.weightLenghtMessage = true;
      return;
    }
    this.weightLenghtMessage = false;
    return;
  }

  private companyPopup(): void {
    this.openModal.nativeElement.click();
  }

  private breakingFullName(name, defaultStr, replaceWith): string {
    const splittedName = name.split(" ");
    splittedName[splittedName.length - 1] = splittedName[
      splittedName.length - 1
    ].replace(defaultStr, replaceWith);
    return splittedName.join(" ");
  }

  private checkAudioStatus(audioStatus, message): void {
    switch (audioStatus) {
      case 1:
        this.audioStatus = "../assets/audio/ok.mp3";
        this.message = message;
        break;
      case 2:
        this.audioStatus = "../assets/audio/error.mp3";
        this.message = message;
        break;
      case 3:
        this.audioStatus = "../assets/audio/service_is_not_done.mp3";
        this.message = message;
        break;
      case 4:
        this.audioStatus = "../assets/audio/unknown_command.mp3";
        this.message = message;
        break;
      case 5:
        this.audioStatus = "../assets/audio/service.mp3";
        this.message = message;
        break;
      case 6:
        this.audioStatus = "../assets/audio/company.mp3";
        this.message = message;
        break;
      case 7:
        this.audioStatus = "../assets/audio/expected.mp3";
        this.message = message;
        break;
      case 8:
        this.audioStatus = null;
        this.message = message;
        break;
      case 9:
        this.audioStatus = "../assets/audio/marine.mp3";
        this.message = message;
        break;
      case 10:
        this.audioStatus = "../assets/audio/generate_label.mp3";
        this.message = message;
        break;
      case 11:
        this.audioStatus = "../assets/audio/dont_repack.mp3";
        this.message = message;
        break;
      case 12:
        this.audioStatus = "../assets/audio/change_recipient.mp3";
        this.message = message;
        break;
      case 13:
        this.audioStatus = "../assets/audio/additional_packaging.mp3";
        this.message = message;
        break;
      case 14:
        this.audioStatus = "../assets/audio/ground.mp3";
        this.message = message;
        break;
    }
    setTimeout(() => {
      // this.ok = null;
      this.audioStatus = null;
      this.message = null;
    }, 3500);
  }
}
