import {
    Component,
    HostListener,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {UserService} from '../../_services';
import {ScriptLoaderService} from '../../_services/script-loader.service';
import {Router} from '@angular/router';
import {FilterShelfBoxesPipe} from '../../_pipes/filter-shelf-boxes.pipe';

@Component({
    selector: 'app-russian-consolidation',
    templateUrl: 'ukraine-consolidation.component.html',
    styleUrls: ['ukraine-consolidation.component.scss']
})

export class UkraineConsolidationComponent implements OnInit {
    @ViewChild('tracking_code') searchInputText;
    @ViewChildren('prebox') public prebox: QueryList<any>;
    orders = [];
    model = [];
    orderfromOrdersgroup = [];
    ogtracking;
    checked = [];
    ponyTracking;
    showLoading = true;
    chack_messege;
    alert_messege;
    chack_ok = 0;
    alert_ok = 0;
    ordersid = [];
    modal = false;
    p;
    print_lable_og;
    print_list_og;
    public finalOrders = [];
    public top = 'top';
    public left = 'center';
    public mLeft = 30;
    public mRight = 1;
    public mTop = 10;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public printLabelId: string;
    public activeArr = [];
    public searchOrders;
    public searchConsolidationTrack;
    public activeFilter = 'all';
    public totalWeight = 0;
    public ordersForCalc = [];
    public totalWeightCalc;
    public windowScrolled: boolean;

    constructor(private _userService: UserService, private _script: ScriptLoaderService, private _router: Router, private filter: FilterShelfBoxesPipe) {
    }

    ngOnInit() {
        this.showLoading = true;
        this.getOrders();
    }

    private getOrders() {
        let regex = /\d+/g;
        this._userService.getUaOrderGroups().subscribe(data => {
            this.orders = data.data;
            this.orders = this.orders.sort(function (a, b) {

                if ((+a['recipient_full_name'].match(regex)) > (+b['recipient_full_name'].match(regex))) {
                    return 1;
                }
                if ((+a['recipient_full_name'].match(regex)) < (+b['recipient_full_name'].match(regex))) {
                    return -1;
                }

                return 0;

            });
            console.log(this.orders);
            this.finalOrders = this.orders;
            this.totalWeight = 0;
            this.ordersForCalc = this.finalOrders;
            this.finalOrders.map(item => {
                this.totalWeight += Number(item.weight);
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            });
            this.showLoading = false;

        });
    }


    get_print_code(poni_tracking = false) {

        if (!poni_tracking) {
            poni_tracking = this.ponyTracking;
        }

        window.open('http://warehouseback.onex.am/api/lable_print_code/' + poni_tracking);

    }

    packed() {
        this.showLoading = true;

        this._userService.change_pony_status(this.checked).subscribe(
            data => {
                for (let i = 0; i < this.orders.length; i++) {
                    for (let j = 0; j < this.ordersid.length; j++) {
                        if (this.orders[i] == this.ordersid[j]) {
                            this.orders.splice(i, 1);
                        }
                    }
                }
                this.showLoading = false;
            },
            error => console.log(error)
        );
    }

    get_pony_tracking(modal = false) {
        this.modal = modal;
        this.showLoading = true;
        this._userService.order_request(this.checked).subscribe(
            data => {
                if (data.orders.status === 1) {
                    if (this.modal) {
                        this.alert_ok = 1;
                        this.alert_messege = data.orders.notification;
                        this.ponyTracking = data.orders.pony_number;
                    } else {
                        this.chack_messege = data.orders.notification;
                        this.chack_ok = 1;
                        this.ponyTracking = data.orders.pony_number;
                    }
                    this._script.loadScripts('app-ordergroup', ['/assets/js/henrik.js']);
                } else if (data.orders.status === 0) {
                    if (this.modal) {
                        this.alert_ok = 2;
                        this.alert_messege = data.orders.notification;
                    } else {

                        this.chack_ok = 2;
                        this.chack_messege = data.orders.notification;
                    }
                    this._script.loadScripts('app-ordergroup', ['/assets/js/henrik.js']);
                }
                this.model = [];
                this.showLoading = false;
            },
            error => console.log(error)
        );
    }

    get_orders(consolidationTracking, orderGroupId) {
        this.activeArr = [];
        // this.ordersid.push(order);
        this.showLoading = true;
        // this.checked = ordergroup;
        // this.ponyTracking = ponytracking;
        // this.print_lable_og = order.tracking_code;
        // this.print_list_og = order;
        this._userService.getUaOrders(consolidationTracking).subscribe(
            data => {
                console.log(data);
                this.orderfromOrdersgroup = data.data;
                this.ogtracking = consolidationTracking;
                this.printLabelId = orderGroupId;
                // this.orderfromOrdersgroup = data.orders.orders;
                this.showLoading = false;


            },
            error => console.log(error)
        );
    }

    checkOrdersActivate() {
        if (this.activeFilter === 'partial') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'blue') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.weight);
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
            console.log(this.totalWeightCalc);
        } else if (this.activeFilter === 'notFound') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'white') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.weight);
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else if (this.activeFilter === 'found') {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                if (weight.color === 'yellow') {
                    this.ordersForCalc.push(weight);
                    this.totalWeight += Number(weight.weight);
                    this.totalWeightCalc = this.totalWeight.toFixed(2);
                }
            }
        } else {
            this.totalWeight = 0;
            this.ordersForCalc = [];
            this.totalWeightCalc = 0;
            for (let weight of this.finalOrders) {
                this.ordersForCalc.push(weight);
                this.totalWeight += Number(weight.weight);
                this.totalWeightCalc = this.totalWeight.toFixed(2);
            }
        }
    }

    printTable(finalOrders) {
        // window.print();
        finalOrders = this.filter.transform(finalOrders, this.activeFilter);
        let myWindow = window.open('', 'PRINT');
        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>table, th, td { border: 1px solid black; border-collapse: collapse;}</style>');
        myWindow.document.write('</head><body >');
        myWindow.document.write('<table style="width: 100%; text-align: center">');
        myWindow.document.write('<thead>\n' +
            '                        <tr>\n' +
            '                            <th>X</th>\n' +
            '                            <th>Recipient</th>\n' +
            '                            <th>User</th>\n' +
            '                            <th>Tracking Code</th>\n' +
            '                            <th>Weight</th>\n' +
            '                            <th>Consolidation Tracking</th>\n' +
            '                        </tr>\n' +
            '                        </thead>');
        myWindow.document.write('<tbody>');
        for (let el of finalOrders) {
            let special = el.activated == '2' ? '*' : ' ';
            let user = el.orderer_info ? el.orderer_info : ' ';
            myWindow.document.write('<tr><td>' + special + '</td>' +
                '<td>' + el.recipient_full_name + '</td>' +
                '<td>' + user + '</td>' +
                '<td>' + el.tracking_code + '</td>' +
                '<td>' + el.weight + '</td><td>' + el.order_group_tracking_code + '</td></tr>');
        }
        myWindow.document.write('</tbody>');
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        // myWindow.close();

        return true;
    }

    manifast() {
        window.open('https://www.onex.ru/api/exel/');
    }

    searchOrderFromOrdersGroup(tracking) {
        this.searchOrders = tracking;
        this.orderfromOrdersgroup.map((item, index) => {
            this.prebox.map((i, idx) => {
                if (idx === index && item.tracking_code === tracking) {
                    i.nativeElement.click();
                }
            });
        })
        setTimeout(() => {
            this.searchInputText.nativeElement.value = null;
        }, 2000)

    }

    searchConsolidationOrders(tracking) {
        this.searchConsolidationTrack = tracking;
    }

    setPreBox(order, event) {
        this._userService.setPreBoxForUk({id: order.id, checked: event.checked ? 1 : 0}).subscribe(
            d => {
                order.activated = d.order.activated;
                // this.activeArr.push(order.activated);
            }, e => {
                console.log(e);
            }
        );
    }

    public closePopup() {
        this.getOrders();
    }

    //
    print_label(track) {
        this.printLabelId = track;
        console.log(this.printLabelId);
        window.open('http://warehouseback.onex.am/printNpgLabel/' + this.printLabelId);
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    topFunction() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }

}
