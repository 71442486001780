import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ChangeStatusModalComponent } from "./chnage-status-modal/change-status-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SendReturnService } from "../../_services/send-return.service";

@Component({
  selector: "app-extra-service",
  templateUrl: "./extra-service.component.html",
  styleUrls: ["./extra-service.component.scss"],
})
export class ExtraServiceComponent implements OnInit, OnDestroy {
  public extraServiceTable = [
    "#",
    "User Name",
    "Tracking Code",
    "Weight",
    "V_Weight",
    "Service",
    "Created Date",
  ];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public trackingCode = "";
  public loading: boolean;
  public message = "";
  public search = "";
  public orders = [];
  public allServices;
  public foundOrder: null;
  public tracking_value = "";
  public status: null;
  public order_id: null;

  constructor(
    private modalService: NgbModal,
    public _getExtraService: SendReturnService,
    public _getService: SendReturnService
  ) {}

  public changeStatus() {
    const modalRef = this.modalService.open(ChangeStatusModalComponent, {
      windowClass: "doneConfirm",
    });
    modalRef.componentInstance.passEntry.subscribe(({ orderService }) => {
      if (orderService) {
        this.orders.unshift(orderService[0]);
      }
    });
  }

  onSearchChange(e) {
    this.tracking_value = e.value;
    if (this.tracking_value === "") {
      this.foundOrder = null;
    }
  }

  searchTracking(tracking) {
    this.loading = true;

    this._getExtraService.getExtraService(tracking).subscribe((data: any) => {
      if (data.orderServices.length === 0) {
        this.message = "Order is not defined";
        this.loading = false;
        this.foundOrder = null;
      } else {
        this.foundOrder = data.orderServices[0];
        this.loading = false;
      }

      setTimeout(() => {
        this.message = "";
      }, 1500);
    });
  }

  deleteExtraService(serviceId) {
    this.loading = true;
    this._getExtraService.deleteService(serviceId).subscribe((data: any) => {
      this.message = data.message;
      this.status = data.status;
      this.loading = false;
      if (data.status === 200) {
        this.orders = this.orders.filter((order) => order.id !== serviceId);
      }
    });
    setTimeout(() => {
      this.message = "";
      this.status = null;
    }, 1500);
  }

  ngOnInit() {
    this.loading = true;
    this._getExtraService
      .getExtraService(this.search)
      .subscribe((data: any) => {
        data.orderServices.map((el) => {
          this.orders.push(el);
        });
        this.loading = false;
      });
    this._getService.getService().subscribe((dataServices: any) => {
      this.allServices = dataServices.data;
    });
  }

  ngOnDestroy() {}
}
