import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs/Observable";
import { AuthenticationService } from "../_services";


@Injectable()
export class AdminAuthGuard implements CanActivate {

    constructor(private _router: Router, private _authUser: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let currentUser = localStorage.getItem('role');
        if (currentUser == 'super_admin') {
            return true;
        }
        // error when verify so redirect to login page with the return url
        this._router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}



