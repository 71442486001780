import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService, UserService} from 'src/app/_services';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import set = Reflect.set;
import {OpenCloseMenuService} from '../partial-services/open-close-menu.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  user;
  groupsCount = 0;
  whId;

  public sidebarOpened = false;
  public iconOnlyToggled = false;

  toggleOffcanvas() {
    this._openCloseService.sidebarOpened = !this._openCloseService.sidebarOpened;
    if (this._openCloseService.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    } else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }


  toggleIconOnlySidebar() {
    this._openCloseService.iconOnlyToggled = !this._openCloseService.iconOnlyToggled;
    if (this._openCloseService.iconOnlyToggled) {
      document.querySelector('body').classList.add('sidebar-icon-only');
    } else {
      document.querySelector('body').classList.remove('sidebar-icon-only');
    }
  }

  constructor(config: NgbDropdownConfig,
              private _userService: UserService,
              private _authService: AuthenticationService,
              private _router: Router,
              private _openCloseService: OpenCloseMenuService
              ) {
    config.placement = 'bottom-right';
    // _router.events.subscribe((val: NavigationEnd) => {
    // see also
    // console.log(val);
    // if (val instanceof NavigationEnd){
    //   console.log(val.urlAfterRedirects);
    // }
    // });
  }

  ngOnInit() {
    this.user = localStorage.getItem('name');
    this.whId = +localStorage.getItem('detectUser');
    // if (this.user != undefined || this.user != null) {
    //     this._userService.getOrderGroup().subscribe(
    //         data => {
    //             console.log(data);
    //             let groupcount = data.orders.orderGroups;
    //             for (let i = 0; i < groupcount.length; i++) {
    //                 if (groupcount[i]['pony_status'] == 1) {
    //                     this.groupsCount++;
    //                 }
    //             }
    //             let countgroups = String(this.groupsCount);
    //             localStorage.setItem('countgroups', countgroups);
    //
    //         },
    //         error => console.log(error)
    //     );
    // }
    // console.log(this.groupsCount);
  }

  logout() {
    this._router.navigate([this._authService.logOut()]);
  }

}
