import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {UserService} from 'src/app/_services';
import {Router} from '@angular/router';

@Component({
    selector: 'app-repack',
    templateUrl: './repack.component.html',
    styleUrls: ['./repack.component.css']
})
export class RepackComponent implements OnInit {
    @ViewChild('trackingcode') inputText;
    @ViewChild('user_code') userCodeInput: any;
    @ViewChild('save') saveBtn: any;
    @ViewChild('comment') comment: any;
    @ViewChild('dItem') dItem: any;
    @ViewChild('openModal') openModal: ElementRef;
    @ViewChild('f') form: ElementRef;
    public audioStatus;
    public message;
    public bottom = 'bottom';
    public top = 'top';
    public left = 'left';
    public center = 'center';
    public mTop = 20;
    public mBottom = 1;
    public sizetwo = 50;
    public fsize = 12;
    public sizeBarcode = 1.5;
    public scannedStatus: boolean;
    public loading: boolean;
    public generated = false;
    public disableBtn = true;
    public defaultService: string;
    public defaultServiceModal = false;
    public checkUnknown: string;
    public lastSixDigit;
    order: any = {};
    autoScan = false;
    data;
    trackingcode: any = {};
    chack_ok = 0;
    showtracking;
    chack_data;
    chack_messege;
    ordereddata = [];
    orderglobaldata = [];
    fullname;
    showLoading = false;
    p_width;
    p_length;
    p_height;
    arm_clients = [];
    ru_clients = [];
    ge_amanati = [];
    ua_clients = [];
    ge_space_cargo_clients = [];
    fullNameForLabel;
    disabled = false;
    isGermany: boolean;
    commentDisabled: boolean;
    // public ARM = 'ARM';
    // public RU = 'RU';
    // public GE = 'GE';
    // public UA = 'UA';
    // public SC = 'SC';
    // public UAM = 'UAM';
    // public UGE = 'UGE';

    public audioFile = null;

    ok = 0;
    messege;

    constructor(private _userService: UserService, private _router: Router) {
        this.order.dispatch = 0;
        this.order.user_code = 'ARM';
        this.order.code = 'ARM';
        this.isGermany = localStorage.getItem('detectUser') === '6';
    }

    enter(trackingcode) {
        // if (!this.isGermany) {
        this.ok = 0;
        this.trackingcode.tracking_code = trackingcode;
        let defaultData = {'tracking_code': trackingcode};
        let withAutoScan = {'tracking_code': trackingcode, 'auto_scan': this.autoScan};
        let dataToReq = this.autoScan ? withAutoScan : defaultData;
        this._userService.createRepack(dataToReq).subscribe(
            data => {
                console.log(data);
                this.data = data;
                this.lastSixDigit = trackingcode.slice(-6);
                this.showtracking = trackingcode.substring(0, trackingcode.length - 6);
                this.showtracking += this.lastSixDigit;
                if (this.data.status == 200) {
                    this.messege = this.data.message;
                    // if (this.data.dispatch !== '0') {
                    //     this.checkAudioStatus(3, this.data.message);
                    // } else {
                    this.checkAudioStatus(1, this.data.message);
                    // }
                } else if (this.data.status == 400) {
                    this.checkAudioStatus(4, this.data.message);
                }
                if (this.data.status == 403) {
                    this.checkAudioStatus(5, this.data.message);
                }

            },
            error => {
                console.log(error);
            }
        );
        this.inputText.nativeElement.value = '';
        // }
    }

    @HostListener('keydown.tab', ['$event'])
    onTab(event: KeyboardEvent) {
        if (event.target == this.userCodeInput.nativeElement) {
            event.preventDefault();
            return false;
        }
    }

    @HostListener('keyup.enter', ['$event'])
    onEnter(event: KeyboardEvent) {
        if (event.target == this.inputText.nativeElement && this.userCodeInput) {
            this.userCodeInput.nativeElement.focus();
            event.preventDefault();
            return false;
        }
        event.preventDefault();
    }

    @HostListener('keyup.arrowDown', ['$event'])
    onArrowDown(event: KeyboardEvent) {
        if (event.target == this.userCodeInput.nativeElement) {
            if (
                this.arm_clients.length > 0 ||
                this.ru_clients.length > 0 ||
                this.ge_amanati.length > 0 ||
                this.ua_clients.length > 0 ||
                this.ge_space_cargo_clients.length > 0
            ) {
                this.dItem.nativeElement
                    .getElementsByClassName('dropdown-item')[0]
                    .focus();
                event.preventDefault();
                return false;
            }
        }
    }

    @HostListener('keyup.arrowUp', ['$event'])
    onArrowUp(event: KeyboardEvent) {
        if (
            this.arm_clients.length > 0 ||
            this.ru_clients.length > 0 ||
            this.ge_amanati.length > 0 ||
            this.ua_clients.length > 0 ||
            this.ge_space_cargo_clients.length > 0
        ) {
            if (
                event.target ==
                this.dItem.nativeElement.getElementsByClassName('dropdown-item')[0]
            ) {
                this.userCodeInput.nativeElement.focus();
                event.preventDefault();
                return false;
            }
        }
    }

    ngOnInit() {
        let mode = localStorage.getItem('autoScan');
        this.autoScan = mode == 'on';
    }

    // setOrderCode(value) {
    //     let str = value;
    //
    //     this.order.user_code = value;
    //     if (this.order.user_code.substr(this.order.user_code.length - 6) === 'delete') {
    //         this.order.user_code = '';
    //     }
    //
    //     switch (true) {
    //         case str.startsWith('A') || str.startsWith('M'):
    //             this.order.code = 'ARM';
    //             if (str.startsWith('M')) {
    //                 this.order.dispatch = '2';
    //             } else {
    //                 this.order.dispatch = '0';
    //             }
    //             break;
    //         case str.startsWith('RU'):
    //             this.order.code = 'RU';
    //             this.order.dispatch = '0';
    //             break;
    //         case str.startsWith('GE'):
    //             if (str.includes('10013')) {
    //                 console.log(123131313132);
    //                 this.order.code = 'UGE';
    //                 this.order.dispatch = '0';
    //             } else {
    //                 this.order.code = 'GE';
    //                 this.order.dispatch = '0';
    //             }
    //
    //             break;
    //         case str.startsWith('SC'):
    //             this.order.code = 'SC';
    //             this.order.dispatch = '0';
    //             break;
    //         case str.startsWith('UA'):
    //             if (str.includes('M')) {
    //                 this.order.code = 'UAM';
    //                 this.order.dispatch = '0';
    //             } else {
    //                 this.order.code = 'UA';
    //                 this.order.dispatch = '0';
    //             }
    //             break;
    //         default:
    //             this.order.dispatch = '0';
    //             this.order.code = 'ARM';
    //     }
    //     if (value == 'ARM267071') {
    //         this.order.code = 'UARM';
    //     }
    //     if (value == 'RU999999') {
    //         this.order.code = 'URU';
    //     }
    //     if (value == 'NL000000') {
    //         this.order.code = 'NL';
    //     }
    //     if (value == 'UA100000') {
    //         this.order.code = 'UUA';
    //     }
    //     if (value == 'GE000000') {
    //         this.order.code = 'GE';
    //     }
    //     if (value == 'USC0') {
    //         this.order.code = 'USC';
    //     }
    // }
    //
    // checkUserCode(us_code, code, $event) {
    //     let reg = /[a-z]{2,3}-[a-z]{4}/gm;
    //     // @ts-ignore
    //     if (reg.test($event.target.value)) {
    //         // @ts-ignore
    //         let userCode = event.target.value.match(reg)[0].toUpperCase();
    //         console.log(userCode);
    //         // @ts-ignore
    //         if (userCode.length < 8) {
    //             // @ts-ignore
    //             event.target.value = userCode.substr(0, 2)
    //                 .toUpperCase() == 'AM' ? 'ARM' : userCode.substr(0, 2).toUpperCase();
    //         } else {
    //             if (userCode.substr(0, 3).toUpperCase() == 'UAM') {
    //                 // @ts-ignore
    //                 event.target.value = userCode.substr(0, 3)
    //                     .toUpperCase() == 'UAM' ? 'ARM267071' : userCode.substr(0, 3).toUpperCase();
    //             }
    //             if (userCode.substr(0, 3).toUpperCase() == 'UGE') {
    //                 console.log();
    //                 // @ts-ignore
    //                 event.target.value = userCode.substr(0, 3)
    //                     .toUpperCase() == 'UGE' ? 'GE10013' : userCode.substr(0, 3).toUpperCase();
    //             }
    //
    //         }
    //         // @ts-ignore
    //         this.setOrderCode(event.target.value);
    //     }
    //
    //
    //     // if (!this.isGermany) {
    //     // console.log(code);
    //     this.arm_clients = [];
    //     this.ru_clients = [];
    //     let ln = code == 'ARM' ? 7 : 6;
    //     if (us_code.startsWith('MT', 0)) {
    //         us_code = us_code.replace('MT', 'ARM').toUpperCase();
    //     }
    //     // console.log(us_code, code, code === 'SC', this.scannedStatus);
    //     // return;
    //     if (us_code.length >= ln || code === 'SC') {
    //         this.showLoading = true;
    //         this._userService
    //             .checkUserCode({user_code: us_code, code: code})
    //             .subscribe(
    //                 (d) => {
    //                     if (d.status == 200) {
    //                         if (d.arm_data != undefined && d.arm_data.length > 0) {
    //                             if (this.order.dispatch == 2) {
    //                                 d.arm_data = d.arm_data.map((item) => {
    //                                     const splittedName = item.fullname.split(' ');
    //                                     splittedName[splittedName.length - 1] = splittedName[
    //                                     splittedName.length - 1
    //                                         ]
    //                                         .replace('ARM', 'MT')
    //                                         .toUpperCase();
    //                                     item.fullname = splittedName.join(' ');
    //                                     return item;
    //                                 });
    //                             }
    //                             this.arm_clients = d.arm_data;
    //                         }
    //                         if (d.ru_data != undefined && d.ru_data.length > 0) {
    //                             this.ru_clients = d.ru_data;
    //                         }
    //                         if (d.ge_amanati != undefined && d.ge_amanati.length > 0) {
    //                             this.ge_amanati = d.ge_amanati;
    //                         }
    //                         if (d.ua_data != undefined && d.ua_data.success) {
    //                             this.ua_clients = d.ua_data.recipients;
    //                         }
    //                         if (d.space_cargo != undefined) {
    //                             this.ge_space_cargo_clients = d.space_cargo;
    //                         }
    //                     }
    //                     this.showLoading = false;
    //                 },
    //                 (e) => {
    //                     console.log(e);
    //                 }
    //             );
    //     } else {
    //         this.arm_clients = [];
    //         this.ru_clients = [];
    //         this.ua_clients = [];
    //         this.ge_amanati = [];
    //         this.ge_space_cargo_clients = [];
    //     }
    // }
    //
    // public selectUserCode() {
    //     this.userCodeInput.nativeElement.focus();
    //     this.checkUnknown = null;
    //     this.commentDisabled = false;
    //     this.disableBtn = true;
    // }
    //
    // selectedClient(id, recipient, fullName, is_person, us_code, dest, defaultService) {
    //     this.checkUnknown = fullName;
    //     this.message = null;
    //     this.audioStatus = null;
    //     this.disableBtn = false;
    //     // this.ok = null;
    //     if (is_person !== '1' && is_person !== '') {
    //         this.checkAudioStatus(6, 'Company');
    //     }
    //     this.fullNameForLabel = fullName;
    //     let armPos;
    //     let user_code;
    //     if (
    //         id !== '12244' &&
    //         id !== '4903' &&
    //         id !== '1004' &&
    //         id !== '1004' &&
    //         id !== '0' &&
    //         id !== '1'
    //     ) {
    //         if (this.order.dispatch == 2) {
    //             armPos = fullName.lastIndexOf('MT');
    //             fullName = this.breakingFullName(fullName, 'MT', 'ARM');
    //             user_code = fullName.slice(armPos);
    //         } else {
    //             armPos = fullName.lastIndexOf(us_code);
    //             user_code = fullName.slice(armPos);
    //         }
    //     } else {
    //         armPos = fullName.lastIndexOf(us_code);
    //         user_code = fullName.slice(armPos);
    //     }
    //
    //     this.defaultService = defaultService;
    //
    //     if (this.trackingcode.tracking_code != undefined) {
    //         this.order.code = us_code;
    //         let dataToSend = {
    //             dest: dest,
    //             dispatch: `${this.order.dispatch}`,
    //             user_id: id,
    //             user_code: user_code,
    //             is_person: is_person,
    //             recipient: fullName,
    //             defaultService: defaultService,
    //             idrecipient: recipient,
    //             trackingcode: this.trackingcode.tracking_code,
    //
    //         };
    //         if (dest != 'NL') {
    //             this._userService.setClientInfo(dataToSend).subscribe(
    //                 (r) => {
    //                     this.arm_clients = [];
    //                     this.ru_clients = [];
    //                     if (this.order.dispatch == 2) {
    //                         fullName = this.breakingFullName(fullName, 'ARM', 'MT');
    //                         user_code = fullName.slice(armPos);
    //                     }
    //                     this.order.user_code = user_code;
    //                 },
    //                 (e) => {
    //                     console.log(e);
    //                 }
    //             );
    //         }
    //     } else {
    //         if (dest != 'NL') {
    //             this.checkAudioStatus(8, 'first check the tracking code!');
    //         }
    //     }
    //     // let user_code = armPos.slice(armPos, fullName.length);
    // }


    // private breakingFullName(name, defaultStr, replaceWith): string {
    //     const splittedName = name.split(' ');
    //     splittedName[splittedName.length - 1] = splittedName[
    //     splittedName.length - 1
    //         ]
    //         .replace(defaultStr, replaceWith)
    //         .toUpperCase();
    //     return splittedName.join(' ');
    // }

    /*
     * auto scan on|off toggle function
     * */
    changeMode() {
        let auto = this.autoScan ? 'on' : 'off';
        localStorage.setItem('autoScan', auto);
    }

    // printInfo() {
    //     this.order.dispatch = 0;
    //     this.order.user_code = 'ARM';
    //     this.order.code = 'ARM';
    //     this.inputText.nativeElement.value = '';
    // }

    private checkAudioStatus(audioStatus, message): void {
        switch (audioStatus) {
            case 1:
                this.audioStatus = '../assets/audio/ok.mp3';
                this.message = message;
                break;
            case 2:
                this.audioStatus = '../assets/audio/error.mp3';
                this.message = message;
                break;
            case 3:
                this.audioStatus = '../assets/audio/service_is_not_done.mp3';
                this.message = message;
                break;
            case 4:
                this.audioStatus = '../assets/audio/unknown_command.mp3';
                this.message = message;
                break;
            case 5:
                this.audioStatus = '../assets/audio/service.mp3';
                this.message = message;
                break;
            case 6:
                this.audioStatus = '../assets/audio/company.mp3';
                this.message = message;
                break;
            case 7:
                this.audioStatus = '../assets/audio/expected.mp3';
                this.message = message;
                break;
            case 8:
                this.audioStatus = null;
                this.message = message;
                break;
            case 9:
                this.audioStatus = '../assets/audio/marine.mp3';
                this.message = message;
                break;
            case 10:
                this.audioStatus = '../assets/audio/generate_label.mp3';
                this.message = message;
                break;
            case 11:
                this.audioStatus = '../assets/audio/dont_repack.mp3';
                this.message = message;
                break;
            case 12:
                this.audioStatus = '../assets/audio/change_recipient.mp3';
                this.message = message;
                break;
            case 13:
                this.audioStatus = '../assets/audio/additional_packaging.mp3';
                this.message = message;
                break;
        }
        setTimeout(() => {
            // this.ok = null;
            this.audioStatus = null;
            this.message = null;
        }, 3500);
    }


}
