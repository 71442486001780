import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchTracking'
})
export class SearchTrackingPipe implements PipeTransform {

  transform(value, tracking?: any): any {
    if (tracking) {
      return value.filter(item => item.tracking_code === tracking || item.npg_tracking === tracking );
    }
    return value;
  }

}
