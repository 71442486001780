import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_services';
import {Router} from '@angular/router';


@Component({
    selector: 'app-sent',
    templateUrl: './sent.component.html',
    styleUrls: ['./sent.component.css']
})
export class SentComponent implements OnInit {
    orders = [];
    showLoading = true;
    orderfromOrdersgroup = [];
    ogtracking;
    checked = [];
    p;


    constructor(private _userService: UserService, private _router: Router) {
        this.showLoading = false;


    }

    ngOnInit(){
        this._userService.getOrderGroup().subscribe(
            data => {
                for (var i = 0; i < data.orders.orderGroups.length; i++){
                    if(data.orders.orderGroups[i].pony_status == 3){

                        this.orders.push(data.orders.orderGroups[i]);
                    }
                }
            },
            error => console.log(error)
        )
    }

    get_orders(ordergroup,ogtracking){
        this._userService.getorders(ordergroup).subscribe(
            data => {
                this.ogtracking = ogtracking ;
                this.orderfromOrdersgroup = data.orders.orders;
            },
            error => console.log(error)
        )
    }



}