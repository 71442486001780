import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../_services';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.scss']
})
export class ReturnComponent implements OnInit {
  orders = [];
  tracking: string;
  showDetails: any;
  closeResult: string;
  activeFilter = 'return';
  @ViewChild('content') modal;
  private uploadData = {
    id: '',
    docFile: null
  };
  constructor(private userService: UserService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.userService.getOrdersForReturn().subscribe(
      d => {
        if (d.status === 200) {
          this.orders = d.orders;
        }
        console.log(d, this.orders);
      },
      e => {
        console.log(e);
      }
    );
  }
  searchTracking(content) {
    if (this.tracking != undefined && this.tracking != '') {
      this.userService.getOrderByTracking({tracking_code: this.tracking}).subscribe(
        d => {
          this.showDetails = d.order;
          this.open(content);
          console.log(this.showDetails);
        }, e => {
          console.log(e);
        }
      );
    }
  }

  /* modal actions*/
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  /* file upload actions*/
  handleFileInput(event, id) {
    this.uploadData.docFile = (event.target as HTMLInputElement).files[0];
    this.uploadData.id = id;
  }
  uploadDocFile() {
    if (this.uploadData.docFile != null) {
      this.userService.uploadFileToReturn(this.uploadData).subscribe(
        d => {
          if (d.status == 200) {
            this.orders.push(d.order);
            this.modalService.dismissAll();
          }
          console.log(d, this.orders);
        }, e => {
          console.log(e);
        }
      );
    }
  }
  setReturned(order, event) {
    console.log(order, event, event.checked);
    if (event.checked) {
      this.userService.setOrderReturned({id: order.id}).subscribe(
        d => {
          console.log(d);
          order.return = d.order.return;
        }, e => {
          console.log(e);
        }
      );
    }
  }
}
