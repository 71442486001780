import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortingPipe'
})
export class SortShelfBoxesPipe implements PipeTransform {

  transform(value: any, args?: string, type?: boolean): any {
    if (!Array.isArray(value)) {
      return;
    }
    if (args == 'name') {
      if (type) {
        value.sort((a, b) => (a.shelf_box > b.shelf_box) ? 1 : -1);
      } else {
        value.sort((a, b) => (b.shelf_box > a.shelf_box) ? 1 : -1);
      }
    } else {
      if (type) {
        value.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1);
      } else {
        value.sort((a, b) => (a.quantity < b.quantity) ? 1 : -1);
      }
    }
    return value;
  }

}
