import {AuthenticationService} from '../_services';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private auth: AuthenticationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the storage.
        const authToken = localStorage.getItem('currentUser');

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.

        const authReq = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + authToken
            }
        });
        // console.log('then this: ', req);
        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
}
