import {Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TrackingReportService} from '../../_services/tracking-report.service';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnChanges {
    public editWeightform: FormGroup;
    @Input() searchResult = [];
    @Input() selectedDay;
    showDetails: any = {};
    totalCount;
    totalWeight = 0;
    public userCode: string;

    constructor(private reportService: TrackingReportService, private fb: FormBuilder) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchResult) {
            this.totalWeight = 0;
            const changedValue: SimpleChange = changes.searchResult;
            this.searchResult = changedValue.currentValue;
            this.totalCount = this.searchResult.length;
            let filtered = this.searchResult.filter(key => key.waight != null);
            filtered.map(key => {
                this.totalWeight += +key.waight;
            });
        } else {
            const changedValue: SimpleChange = changes.selectedDay;
            this.selectedDay = changedValue.currentValue;
        }
    }

    ngOnInit() {
        this.formBuilder();
    }

    private formBuilder(): void {
        this.editWeightform = this.fb.group({
            editWeightValue: [null],
            width: [null],
            height: [null],
            length_v: [null],
            dispatch: [1],
            v_weight: [null],
            weight: [null]
        });
    }

    show(result) {
        console.log(result);
        this.userCode = result.user_code;
        this.reportService.showDetails({id: result.id}).subscribe(
            d => {
                console.log(d);
                this.showDetails = d.orderDetails[0];
                this.editWeightform.patchValue({
                    width: d.orderDetails[0].width,
                    height: d.orderDetails[0].height,
                    length_v: d.orderDetails[0].length,
                    dispatch: d.orderDetails[0].dispatch,
                    v_weight: d.orderDetails[0].v_waight,
                    weight: d.orderDetails[0].waight
                });
                if (this.selectedDay) {
                    this.showDetails.created_at = new Date(this.showDetails.created_at);
                    let day = this.selectedDay.day != this.showDetails.created_at.getDate() ? this.selectedDay.day : this.showDetails.created_at.getDate();
                    this.showDetails.created_at.setDate(day);
                }
            }, e => {
                console.log(e);
            }
        );
    }

    export(data) {
        // console.log(data);
        // window.print();
        let myWindow = window.open('', 'PRINT');
        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>table, th, td { border: 1px solid black; border-collapse: collapse;}</style>');
        myWindow.document.write('</head><body >');
        myWindow.document.write('<table style="width: 100%; text-align: center">');
        myWindow.document.write('<thead>\n' +
            '                        <tr>\n' +
            '                            <th>Scan Date</th>\n' +
            '                            <th>Tracking</th>\n' +
            '                            <th>Weight</th>\n' +
            '                            <th>Volume Weight</th>\n' +
            '                            <th>Comment</th>\n' +
            '                            <th>Parcel</th>\n' +
            '                        </tr>\n' +
            '                        </thead>');
        myWindow.document.write('<tbody>');
        for (let el of data) {
            el.waight = el.waight == null ? '' : el.waight;
            el.v_waight = el.v_waight == null ? '' : el.v_waight;
            el.parcel_name = el.parcel_name != undefined ? el.parcel_name : '';
            myWindow.document.write('<tr><td>' + el.created_at + '</td>' +
                '<td>' + el.tracking_code + '</td><td>' + el.waight + '</td><td>' + el.v_waight + '</td>' +
                '<td>' + el.comment + '</td><td>' + el.parcel_name + '</td></tr>');
        }
        myWindow.document.write('</tbody>');
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        // myWindow.print();
        // myWindow.close();

        return true;
    }

    public editWeight(tracking_code, order_whight): void {
        const formData = {
            height: this.editWeightform.get('height').value,
            weight: this.editWeightform.get('weight').value,
            v_weight: this.editWeightform.get('v_weight').value,
            width: this.editWeightform.get('width').value,
            length: this.editWeightform.get('length_v').value,
            dispatch: this.editWeightform.get('dispatch').value,
            tracking_code: tracking_code
        };
        this.reportService.editWeight(formData).subscribe((data: any) => {
            console.log(`[Edit Weight] ${data}`);
        });
    }

    public calc(w, h, l): void {
        let vWeight;
        if (this.userCode && this.userCode.includes('ARM')) {
            vWeight = 5000;
        } else {
            vWeight = 6000;
        }
        let totalV = (w * h * l) / vWeight;
        this.editWeightform.patchValue({
            v_weight: totalV
        });
    }
}


